import { makeAutoObservable } from "mobx";
import MarketingApi from "../api/MarketingApi";
import { RootStore } from "./RootStore";
import React from "react";

export interface CompanyStoreInterface {
  getCompanyFormDetailsIsErroneous: boolean;
  postCompanyFormIsErroneous: boolean;
  companyEmployees: CompanyDetails[];
  companyRevenue: CompanyDetails[];
  companyIndustry: CompanyDetails[];
  companyName: string;
  companyWebsite: string;
  companyTwitter: string;
  selectedIndustry: number;
  selectedEmployees: number;
  selectedRevenue: number;
  selectedIndustryValue: string;
  selectedEmployeesValue: string;
  selectedRevenueValue: string;
}

class CompanyStore implements CompanyStoreInterface {
  public getCompanyFormDetailsIsErroneous: boolean = false;
  public postCompanyFormIsErroneous: boolean = false;
  public companyEmployees: CompanyDetails[] = [];
  public companyRevenue: CompanyDetails[] = [];
  public companyIndustry: CompanyDetails[] = [];
  public companyName: string = "";
  public companyWebsite: string = "";
  public companyTwitter: string = "";
  public selectedIndustry: number = 0;
  public selectedEmployees: number = 0;
  public selectedRevenue: number = 0;
  public selectedIndustryValue: string = "";
  public selectedEmployeesValue: string = "";
  public selectedRevenueValue: string = "";
  public rootStore: RootStore | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async getCompanyFormDetails() {
    this.getCompanyFormDetailsIsErroneous = false;
    this.companyEmployees = [];
    this.companyRevenue = [];
    this.companyIndustry = [];

    try {
      const response = await MarketingApi.getCompanyDetails();
      this.companyEmployees = response.data.details.size;
      this.companyRevenue = response.data.details.revenue;
      this.companyIndustry = response.data.details.industry;
    } catch {
      this.getCompanyFormDetailsIsErroneous = true;
    }
  }

  public async postCompanyForm() {
    this.postCompanyFormIsErroneous = false;

    const companyData: CompanyData = {
      website: this.companyWebsite,
      company_name: this.companyName,
      twitter: this.companyTwitter,
      company_size_id: this.selectedEmployees,
      company_revenue_id: this.selectedRevenue,
      industry_id: this.selectedIndustry,
    };

    const companyDataRequest: CompanyDataRequest = {
      companyData: companyData,
    };

    try {
      await MarketingApi.postCompanyForm(companyDataRequest);
    } catch {
      this.postCompanyFormIsErroneous = true;
    }
  }

  public setCompanyName(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.companyName = event.target.value;
  }

  public setCompanyWebsite(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.companyWebsite = event.target.value;
  }

  public setCompanyTwitter(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.companyTwitter = event.target.value;
  }

  public setCompanyIndustry(event: React.ChangeEvent<HTMLSelectElement>) {
    this.selectedIndustry = Number(event.target.value);
    this.selectedIndustryValue = event.target.value;
  }

  public setCompanyEmployees(event: React.ChangeEvent<HTMLSelectElement>) {
    this.selectedEmployees = Number(event.target.value);
    this.selectedEmployeesValue = event.target.value;
  }

  public setCompanyRevenue(event: React.ChangeEvent<HTMLSelectElement>) {
    this.selectedRevenue = Number(event.target.value);
    this.selectedRevenueValue = event.target.value;
  }

  public checkRequiredFields() {
    return (
      this.companyName.length > 0 &&
      this.companyWebsite.length > 0 &&
      this.companyTwitter.length > 0 &&
      this.selectedIndustry > 0 &&
      this.selectedEmployees > 0 &&
      this.selectedRevenue > 0
    );
  }
}

export default CompanyStore;
