import { convertMagnitude } from "../../util/TwitterMagnitudeConverter";
import avatarIamge from "../../images/avatar.png";

const truncate = (str: string) => {
  if (str !== undefined) {
    const maxChars = 130;
    return str.length > maxChars ? str.substring(0, maxChars - 3) + "..." : str;
  }
  return "";
};

const getCompetitorLink = (competitorId: number) => {
  if (!competitorId) {
    return;
  }
  return "/dashboard/competitor-details/" + competitorId.toString();
};

const ResultsCard = (props: any) => {
  return (
    <div className={props.class} key={props.index}>
      <div className="Card Card__Social">
        <div className="Card__Number">0{props.index + 1}</div>
        <div
          className={`Card__ImageContainer Card__ImageContainer--h140 ${
            props.twitterItem.possibly_sensitive ? "overflow-hidden" : ""
          }`}
        >
          {props.type === "account" && (
            <img className="Card__ImageContainer__Image" src={props.twitterItem.profile_banner_url} alt={""} />
          )}

          {props.type === "profile" && (
            <img className="Card__ImageContainer__Image" src={props.twitterItem.profile_banner_url} alt={""} />
          )}

          {props.type === "tweet" && (
            <img
              className={`Card__ImageContainer__Image ${
                props.twitterItem.possibly_sensitive ? "Card__ImageContainer__Image--sensitive" : ""
              }`}
              src={props.twitterItem.attachment_url || avatarIamge}
              alt={""}
            />
          )}

          {props.showThumb && (
            <img className="Card__ImageContainer__Thumb" src={props.twitterItem.profile_image_url} alt={""} />
          )}

          {props.twitterItem.possibly_sensitive && props.type === "tweet" && (
            <span className="Card__ImageContainer__Text">Sensitive Image</span>
          )}
        </div>

        <div className="Card__Content">
          <div className="Card__Title">
            <h3>
              {props.twitterItem.twitter_account_name}
              {props.twitterItem.twitter_account_verified && <i className="VerifiedIcon mx-2"></i>}
            </h3>
            <h4>@{props.twitterItem.twitter_account}</h4>
          </div>

          <p className="Card__Body Card__Body--3">
            {truncate(props.twitterItem.description ? props.twitterItem.description : props.twitterItem.text)}
          </p>

          <div className="Card__Engagement">
            {props.type === "account" && (
              <>
                <span>
                  <strong>{convertMagnitude(props.twitterItem.following_count)}</strong> Following
                </span>

                <span>
                  <strong>{convertMagnitude(props.twitterItem.followers_count)}</strong> Followers
                </span>
              </>
            )}

            {props.type === "tweet" && (
              <>
                <span>
                  <strong>{props.twitterItem.retweet_count.toLocaleString("en")}</strong> Retweets
                </span>

                <span>
                  <strong>{props.twitterItem.quote_count.toLocaleString("en")}</strong> Quote Tweets
                </span>

                <span>
                  <strong>{props.twitterItem.like_count.toLocaleString("en")}</strong> Likes
                </span>
              </>
            )}
          </div>

          <div className="cursor-pointer">
            {props.type === "account" && (
              <a className="Card__Link" href={getCompetitorLink(props.twitterItem.competitor_id)}>
                View Details
              </a>
            )}

            {props.type === "profile" && (
              <a className="Card__Link" href={props.twitterItem.twitter_link} target="_blank" rel="noreferrer">
                View Profile
              </a>
            )}

            {props.type === "tweet" && (
              <a className="Card__Link" href={props.twitterItem.twitter_link} target="_blank" rel="noreferrer">
                See Tweet
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsCard;
