import Title from "../../components/headlines/Title";
import { Col, Row } from "react-bootstrap";
import EngagementGraph from "./components/EngagementGraph";

const Engagement = () => {
  return (
    <>
      <Title title="Twitter Engagement" />
      <div className="Container">
        <div className="row">
          <div className="col col-12">
            <div className="card p-0">
              <div className="Graph__Container__Header">
                <div className="row p-0">
                  <div className="col col-12">
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <strong>Across competitors</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form>
          <Row>
            <Col>
              <EngagementGraph title="engagement" />
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
};

export default Engagement;
