import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { Login, OnBoarding, ResultsContainer, Maintain, Landing } from "./pages";
import "./styles/App.scss";
import { JWT_TOKEN } from "./store/LoginStore";

const rootElement = document.getElementById("root");

function PrivateOutlet() {
  const isAuthenticated = authenticateUser();
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}

function PublicOutlet() {
  const isAuthenticated = authenticateUser();
  const targetPage = "/dashboard";
  return isAuthenticated ? <Navigate to={targetPage} /> : <Outlet />;
}

function authenticateUser() {
  const token = sessionStorage.getItem(JWT_TOKEN);
  if (token === null) return false;
  return token.length > 1;
}

const App = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PublicOutlet />}>
            <Route path="/landing" element={<Landing />} />
            <Route path="" element={<Maintain />} />
          </Route>
          <Route path="/login" element={<PublicOutlet />}>
            <Route path="" element={<Login />} />
          </Route>
          <Route path="/onboard" element={<PrivateOutlet />}>
            <Route path="" element={<OnBoarding />} />
          </Route>

          <Route path="/dashboard" element={<PrivateOutlet />}>
            <Route path="/dashboard" element={<ResultsContainer page="dashboard" />} />
            <Route path="/dashboard/engagement" element={<ResultsContainer page="engagement" />} />{" "}
            <Route
              path="/dashboard/competitor-details/:competitorId"
              element={<ResultsContainer page="competitor-details" />}
            />
            <Route path="/dashboard/campaigns" element={<ResultsContainer page="campaigns" />} />
            <Route path="/dashboard/profile" element={<ResultsContainer page="profile" />} />
            <Route path="/dashboard/insights" element={<ResultsContainer page="insights" />} />
          </Route>
          <Route path="*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
};

render(<App />, rootElement);
