import Button from 'react-bootstrap/Button';
import OjamuLogo from "../../components/logo/OjamuLogo";
import imageMaintain from "../../images/maintain.png"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStores } from '../../store/RootStore';

export default function Maintain() {
  const navigate = useNavigate();
  const { loginStore } = useStores();

  const [login, setLogin] = useState(false)
  const [error, setError] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    loginStore.handleShowPlatform(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogin = () => {
    if(username === 'ojamu' && password === 'OjamuLinhAI'){
      loginStore.handleShowPlatform(true)
      localStorage.setItem('isLogin', 'true')
    }else {
      loginStore.handleShowPlatform(false)
      setError(true)
    }
    if(loginStore.showFlatForm){
      navigate("/login");
    } 
  }
  return (
    <div className="maintain-container">
        <div onClick={() => setLogin(false)} className="cursor-pointer">
          <OjamuLogo small />
        </div>
        <div>
          <Button className="login-btn" variant="primary" onClick={() => setLogin(true)}>Login to access site</Button>
        </div>
        {
          login ? 
                (<div className="d-flex flex-column mt-5">
                    <div className="form-group pb-4">
                      <label className="pb-2" htmlFor="email">Email address</label>
                      <div className="Input__Icon">
                        <input type="text" onChange={(e) => {
                          setUsername(e.target.value)
                          setError(false)
                          }} placeholder="Username" />
                      </div>
                    </div>

                    <div className="form-group pb-4">
                      <label className="pb-2" htmlFor="pass">Password</label>
                      <div className="Input__Icon">
                        <input type="password" onChange={(e) => {
                          setPassword(e.target.value)
                          setError(false)
                        }} placeholder="Password" />
                      </div>
                    </div>

                    <Button className="login-btn btn-submit" onClick={handleLogin}>
                        Sign In
                    </Button>
                    {error && <p className='text-erorr'>Wrong username or password</p> }
                </div>) 
          :
                (
                  <>
                    <div className="maintain-text">
                      <h1>We'll Be Back Soon</h1>
                      <p>Ojamu is down for scheduled maintenance and expect to back online in a few minutes</p>
                    </div>
                    <div className='maintain-image'>
                      <img src={imageMaintain} alt="" />
                    </div>
                  </>
                )
      }     
    </div>
  );
}
