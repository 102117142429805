import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import MetaMask from "../../../metamask.svg";
import EmailSVG from "../../../images/mail-icon.svg";
import PassSVG from "../../../images/pass-icon.svg";
import { useStores } from "src/store/RootStore";

const LoginForm = ({ onSubmit, onMetaMaskSubmit, isLoading }) => {
  const usernameRef = React.useRef();
  const passwordRef = React.useRef();
  const { loginStore } = useStores();
  const [loginFormDisplayed, setLoginFormDisplayed] = useState(loginStore.showLoginForm);
  const [passwordShown, setPasswordShown] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      username: usernameRef.current.value,
      password: passwordRef.current.value,
    };
    onSubmit(data);
  };

  const handleMetaMaskLogin = () => {
    loginStore.handleLoginFormDisplay(false)
    setLoginFormDisplayed(false);
    onMetaMaskSubmit();
  };
  
  const displayLoginForm = () => {
    loginStore.handleLoginFormDisplay(true)
    setLoginFormDisplayed(true);
  }

  const togglePasswordShown = () => {
    setPasswordShown(!passwordShown);
  }

  return (
    <>
      <form className="d-flex flex-column" onSubmit={handleSubmit}>
        <h1 className="Login--Headline">Login</h1>

        <div className={`Login__Container ${loginFormDisplayed ? 'Login__Container--active' : '' }`}>
          <Button className="Button__Outlined Button__Outlined--MetaMask" onClick={(e) => handleMetaMaskLogin()}>
            <img className="Button__Outlined--logo" src={MetaMask} alt="MetaMask Log In" />
            <span>Log in with MetaMask</span>
          </Button>

          <div className="Divider text-center">
            <span className="Divider__Span">or</span>
          </div>

          <div className="d-flex flex-column">
            {loginFormDisplayed && (
                <div className="d-flex flex-column">
                  <div className="form-group pb-4">
                    <label className="pb-2" htmlFor="email">Email address</label>
                    <div className="Input__Icon">
                      <img className="Input__Icon__Icon" src={EmailSVG} alt={""}/>
                      <input type="text" id="email" className="form-control" ref={usernameRef} placeholder="" />
                    </div>
                  </div>

                  <div className="form-group pb-4">
                    <label className="pb-2" htmlFor="pass">Password</label>
                    <div className="Input__Icon">
                      <img className="Input__Icon__Icon Input__Icon__Icon--pass" src={PassSVG} alt={""} onClick={() => togglePasswordShown()} />
                      <input type={passwordShown ? "text" : "password"} id="pass" className="form-control" ref={passwordRef} placeholder="" />
                    </div>
                  </div>

                  <Button className="Button__Outlined" type="submit">
                    <span>{isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Sign In"}</span>
                  </Button>
                </div>
              )}
              {!loginFormDisplayed && (
                <Button className="Button__Outlined" onClick={(e) => displayLoginForm()}>
                  <span>{isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Log In With Email"}</span>
                </Button>
              )}
          </div>

        </div>

      </form>
    </>
  );
};

export default LoginForm;
