import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useStores } from "../../../store/RootStore";
import Web3Icon from "../../../components/icons/Web3Icon";

const Web3SpaceForm = (props: any) => {
  const [web3Spaces, setWeb3Spaces] = useState<Web3Space[]>([]);
  const { web3Store } = useStores();

  const whitelistedSpaces: number[] = [1]; // Temporary hack for whitelisting only specific spaces. Right now only id=1 (NFT Collections) space should be able to be chosen.


  useEffect(() => {
    const fetchWeb3Spaces = async () => {
      const web3Spaces = await web3Store.getWeb3Spaces();
      if (web3Spaces !== undefined) {
        setWeb3Spaces(web3Spaces);
      }
    };

    fetchWeb3Spaces().catch(console.error);
  }, [web3Store]);

  const populateItems = (web3Spaces: Web3Space[]) => {
    const loadingWeb3SpacesIsErroneous = !web3Store.loadingWeb3SpacesIsErroneous;
    return (
      loadingWeb3SpacesIsErroneous &&
      web3Spaces.map((web3Space, index) => (
        <li key={index}>
          <div className={`Web3SpaceForm__List__Item ${web3Space.selected ? 'Web3SpaceForm__List__Item--selected' : ''} ${!isItemWhitelisted(web3Space.id) ? 'Web3SpaceForm__List__Item--disabled' : ''}`} onClick={() => toggleWeb3SpaceSelection(web3Space.id)}>
            <span>
              <Web3Icon id={web3Space.id} className={`${web3Space.selected ? 'Web3Icon--selected' : ''} ${!isItemWhitelisted(web3Space.id) ? 'Web3Icon--disabled' : ''}`} />
              {web3Space.name}
            </span>
            <i className={`Web3SpaceForm__List__Indicator ${web3Space.selected ? 'Web3SpaceForm__List__Indicator--selected' : ''} ${!isItemWhitelisted(web3Space.id) ? 'Web3SpaceForm__List__Indicator--disabled' : ''}`}></i>
          </div>
        </li>
      ))
    );
  };

  const toggleWeb3SpaceSelection = (id: number) => {
    if (isItemWhitelisted(id)) {
      setWeb3Spaces(web3Store.toggleWeb3SpaceSelection(id));
    }
  };

  const isItemWhitelisted = (id: number)  => {
    return whitelistedSpaces.includes(id);
  };

  const postForm = () => {
    const selectedWeb3Space = web3Spaces.find((web3Space) => web3Space.selected === true);
    if (!selectedWeb3Space) {
      return;
    }

    const selectedWeb3SpaceRequest: SelectedWeb3SpaceRequest = {
      space_id: selectedWeb3Space.id
    };
    web3Store.postWeb3Space(selectedWeb3SpaceRequest).then(props.handleNextClick({ step: 3 }));
  };


  return (
    <>
      <div className="row">
        <div className="col-8 d-flex align-items-center">
          <div className="pe-5">
            <strong className="color--purple">Step 02</strong>
            <h1 className="pt-1 pb-3">Your Brand In Web3</h1>
            <span>
              Next enter your brand’s area of interest in Web3.
              <br/>
              <br/>
              Are you looking to get into the Metaverse, drop an NFT fashion line or launch your own game? Let us know by selecting one of the dropdown fields.
            </span>
          </div>
        </div>
        <div className="col-4">
          <h3 className="pb-0">Select Web3 Area Of Interest</h3>

          <form>
            <div className="form-group pb-2">
              {web3Spaces.length > 0 && (
                <div className="Web3SpaceForm">
                  <ul className={`Web3SpaceForm__List`}>
                    {populateItems(web3Spaces)}
                  </ul>
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-6">
                <Button className="Button Button--primary Button--primary--outlined w-100" onClick={() => props.handleBackClick({ goal: "brand", step: 1 })}>
                  <span>Back</span>
                </Button>
              </div>
              <div className="col-6">
                <Button
                  className="Button Button--primary w-100"
                  onClick={() => postForm()}
                  disabled={web3Spaces.filter( web3Space => web3Space.selected).length < 1}
                >
                  <span>Next</span>

                  <svg className="Button__Icon" xmlns="http://www.w3.org/2000/svg" width="5.258" height="8.514" viewBox="0 0 5.258 8.514">
                    <path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M10,11.76l3.257,3.25,3.257-3.25,1,1-4.257,4.257L9,12.76Z" transform="translate(-11.76 17.514) rotate(-90)" fill="#fff"/>
                  </svg>

                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Web3SpaceForm;
