const SubTitle = (props: any) => {
  return (
    <div
      className={`SubTitle SubTitle__Container SubTitle--glowing
        ${props.h3 ? "h3" : ""}
        ${props.light ? "SubTitle--light" : ""}
        ${props.rotate ? "SubTitle--rotate" : ""}`}
    >
      <div className="SubTitle__Titles">
        <span className="SubTitle__Titles__Title">{props.title}&nbsp;</span>
        <span className="SubTitle__Titles__SubTitle">{props.subTitle}</span>
      </div>
      {props.children}
    </div>
  );
};

export default SubTitle;
