import React from "react";
import "chartjs-adapter-moment";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, TimeScale, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, TimeScale);

const DayOfTheWeekGraph = (props) => {
  const graphData = props.data;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        display: true,
      },
      yAxis: {
        ticks: {
          display: false,
        },
      },
    },
  };

  function argMax(array) {
    return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
  }

  let color = graphData.map((x) => "#fff");
  color[argMax(graphData)] = "#e10c7c";

  const data = {
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    datasets: [
      {
        data: graphData,
        backgroundColor: color,
        borderRadius: 3,
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

export default DayOfTheWeekGraph;
