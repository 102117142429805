import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

import { topNFT } from "../../assets/fakedata";
import { changeContractWallet } from "../../util/TransformWallet";
import Title from "../headlines/Title";
import ContentFormatGraph from "../../pages/insights/components/ContentFormatGraph";
import Loading from "../progress/Loading";
import { useStores } from "../../store/RootStore";
import NftTransaction from "./NftTransaction";

const NFTModal = (props: any) => {
  const { web3Store } = useStores();
  const [loading, setLoading] = useState(false);
  const [nftDetail, setNftDetail] = useState({} as NftDetailAnalysis);
  const [avgHold, setAvgHold] = useState([] as avgHoldTime[]);
  const formatter = Intl.NumberFormat("en", { notation: "compact" });

  const getPercentageContentType = (label: string, arr: any[]) => {
    const index = arr.findIndex((a) => a === label);
    if (index !== -1) {
      return ((1 / arr.length) * 100).toFixed();
    } else {
      return 0;
    }
  };

  const getPercentageCategory = (label: string, arrInitial: string[], arrCategory: string[]) => {
    let newArr: string[] = [];
    arrInitial?.forEach((cate) => {
      if (arrCategory.includes(cate)) newArr.push(cate);
    });
    const index = newArr.findIndex((a) => a === label);
    if (index !== -1) {
      return ((1 / newArr.length) * 100).toFixed();
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const getNFTDetailAnalysis = async () => {
      const data = await web3Store.getNFTDetailAnalysis(props.NFTData.id);
      if (!data.blockchain) {
        let NFTCollection: any = topNFT?.find((item) => item.id === props.NFTData.id);
        setNftDetail(NFTCollection);
      } else {
        setNftDetail(data);
      }
    };

    const getAvgHoldTime = async () => {
      const data = await web3Store.getAvgHoldTime(props.NFTData.id);
      if (data.length === 0) {
        let NFTCollection: any = topNFT?.find((item) => item.id === props.NFTData.id);
        setAvgHold(NFTCollection.AvgHold);
      } else {
        setAvgHold(data);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await getNFTDetailAnalysis();
      await getAvgHoldTime();
      setLoading(false);
    };

    fetchData().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3Store]);

  let NFTCollection: any = topNFT?.find((item) => item.id === props.NFTData.id);
  if (NFTCollection === undefined) {
    NFTCollection = topNFT[0];
  }

  let contentType = [];
  let categories = ["Art", "Collectible", "Fashion", "Virtual Land", "In Game Purchase"];

  if (Object.keys(nftDetail).length !== 0) {
    for (const item of Object.keys(nftDetail?.contentType)) {
      contentType.push(item);
    }
  }

  let walletReceiving: string[] = [];
  let walletSend: string[] = [];
  let walletTransaction: string[] = [];
  let walletNotTransaction: string[] = [];
  let NFTTransaction: any[] = [];
  let NFTNotTransaction: any[] = [];
  let walletDetailTransactionSumary: any[] = [];
  let AvgHoldList: any[] = [];

  avgHold?.forEach((item: any) => {
    walletReceiving.push(item.to);
    walletSend.push(item.sender);
  });

  walletReceiving = Array.from(new Set(walletReceiving));
  walletSend = Array.from(new Set(walletSend));

  walletReceiving.forEach((walletTo: string) => {
    walletSend.forEach((walletSend: string) => {
      if (walletTo === walletSend) {
        walletTransaction.push(walletTo);
      }
    });
  });

  walletReceiving.forEach((wallet1: string) => {
    const index = walletSend.includes(wallet1);
    if (!index) {
      walletNotTransaction.push(wallet1);
    }
  });

  avgHold?.forEach((item: any) => {
    walletTransaction.forEach((wallet: string) => {
      if (item.sender === wallet || item.to === wallet) {
        NFTTransaction.push(item);
      }
    });
  });

  avgHold?.forEach((item: any) => {
    walletNotTransaction.forEach((wallet: string) => {
      if (item.to === wallet) {
        NFTNotTransaction.push(item);
      }
    });
  });

  walletTransaction.forEach((wallet: string) => {
    const walletDetailTransaction: any[] = [];
    avgHold?.forEach((item: any) => {
      if (item.sender === wallet || item.to === wallet) {
        walletDetailTransaction.push(item);
      }
    });
    walletDetailTransactionSumary.push(walletDetailTransaction);
  });

  walletDetailTransactionSumary.forEach((item: any) => {
    for (let i = item.length - 1; i > 0; i = i - 2) {
      const date1 = new Date(item[i].timestamp * 1000);
      const date2 = new Date(item[i - 1].timestamp * 1000);
      const x = date1.getMonth() + 1 + "/" + date1.getDate() + "/" + date1.getFullYear();
      const y = date2.getMonth() + 1 + "/" + date2.getDate() + "/" + date2.getFullYear();
      const a1: any = new Date(x);
      const a2: any = new Date(y);
      const diffTime = Math.abs(a2 - a1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      AvgHoldList.push(diffDays);
    }
  });

  NFTNotTransaction.forEach((item: any) => {
    const date1 = new Date(item.timestamp * 1000);
    const x = date1.getMonth() + 1 + "/" + date1.getDate() + "/" + date1.getFullYear();
    const a1: any = new Date(x);
    const a2: any = new Date();
    const diffTime = Math.abs(a2 - a1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    AvgHoldList.push(diffDays);
  });

  const AvgHold =
    AvgHoldList.reduce((previousValue, currentValue) => previousValue + currentValue, 0) / AvgHoldList.length;

  const handleClose = () => {
    props.setShowNFTModal(false);
  };

  return (
    <div className="modal__result__wallet">
      <div className="px-5">
        <div className="modal__result__title d-flex justify-content-between align-items-center mb-3">
          <p onClick={handleClose} className="mb-0 cursor-pointer">
            <FontAwesomeIcon className="MockDetailsPopup-close__Icon" icon={faAngleLeft} />
            <span>
              Back To <strong>Results</strong>
            </span>
          </p>
          <p className="mb-0">
            Results <span className="px-2">|</span>
            <span>
              <strong>NFTAnalysis</strong>
            </span>
          </p>
        </div>
        <Title title="NFT Analysis" />
        <div className="Content__Container">
          <Title title={props.NFTData.name} />

          <div className="Container p-4">
            {!loading ? (
              <div className="Content__Container__analysis d-flex row">
                <div className="col-6">
                  <div className="d-flex Wallet__content">
                    <div className="Wallet__image">
                      <img src={props.NFTData.image_url} alt="" />
                    </div>
                    <div className="Wallet__Info px-3">
                      <div className="Card__Nft__Title p-0">
                        <h3>{props.NFTData.name}</h3>
                        <span>
                          Created by {props.NFTData.creator}
                          <i className="VerifiedIcon mx-2"></i>
                        </span>
                      </div>
                      <div className="Card__Engagement Card__Engagement__Nft">
                        <div>
                          <span className="p-0">Volume:</span>
                          <strong>{formatter.format(props.NFTData.total_volume)} ETH</strong>
                        </div>

                        <div>
                          <span className="p-0">Floor Price:</span>
                          <strong>
                            {props.NFTData.floor_price_eth
                              ? Number(props.NFTData.floor_price_eth?.toFixed(2))?.toLocaleString() + " ETH"
                              : "---"}
                          </strong>
                        </div>

                        <div>
                          <span className="p-0">Max Price:</span>
                          <strong>{NFTCollection?.max_price} ETH</strong>
                        </div>

                        <div>
                          <span className="p-0">Owners:</span>
                          <strong>{formatter.format(props.NFTData.owner_count)}</strong>
                        </div>

                        <div>
                          <span className="p-0">Items:</span>
                          <strong>{formatter.format(props.NFTData.item_count)}</strong>
                        </div>
                        <div>
                          <span className="p-0">Avg. Hold Time:</span>
                          <strong>{Math.ceil(AvgHold) || NFTCollection.AvgHold2} Days</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="Wallet__description">
                    <div className="Wallet__description__test">
                      <p>{nftDetail?.description}</p>
                    </div>
                    <div className="Card__Engagement Card__Engagement__Nft">
                      <div>
                        <span>Digital Collectible:</span>
                        <strong>{nftDetail?.digitalCollectible || "---"}</strong>
                      </div>

                      <div>
                        <span>Brand Category:</span>
                        <strong>{nftDetail.brandCategory || "---"}</strong>
                      </div>

                      <div>
                        <span>Primary Utility:</span>
                        <strong>{nftDetail.primaryUtility || "---"}</strong>
                      </div>
                    </div>
                    <div className="Card__Engagement Card__Engagement__Nft">
                      <div>
                        <span>Contract Address:</span>
                        <strong className="text-main-color">
                          {nftDetail?.contractAddress ? changeContractWallet(nftDetail?.contractAddress) : "---"}
                        </strong>
                      </div>

                      <div>
                        <span>Token ID:</span>
                        <strong className="text-main-color">
                          {nftDetail?.tokenId
                            ? nftDetail?.tokenId?.length > 10
                              ? changeContractWallet(nftDetail?.tokenId)
                              : nftDetail?.tokenId
                            : "---"}
                        </strong>
                      </div>

                      <div>
                        <span>Token Standard:</span>
                        <strong>{nftDetail?.tokenStandard || "---"}</strong>
                      </div>
                      <div>
                        <span>Blockchain:</span>
                        <strong>{nftDetail?.blockchain || "---"}</strong>
                      </div>
                      <div>
                        <span>Creator Fees:</span>
                        <strong>{nftDetail?.creatorFees || "---"}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Loading />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <NftTransaction transactionCount={NFTCollection.transactionCount} nftId={props.NFTData.id} />
          </div>

          <div className="col-6">
            <div className="Content__Container">
              <Title title="NFT Details"></Title>
              {!loading ? (
                <div className="Container NFT-detail p-4 pb-0">
                  <div className="Wallet__Content__type pb-4">
                    <p className="mb-4">
                      <strong>Content Type</strong>
                    </p>
                    <div className="d-flex Wallet-chart">
                      <div>
                        <ContentFormatGraph percentage={getPercentageContentType("video", contentType)} label="Video" />
                      </div>
                      <div>
                        <ContentFormatGraph
                          percentage={getPercentageContentType("_2DImage", contentType)}
                          label="2D Image"
                        />
                      </div>
                      <div>
                        <ContentFormatGraph
                          percentage={getPercentageContentType("_3DImage", contentType)}
                          label="3D Image"
                        />
                      </div>
                      <div>
                        <ContentFormatGraph percentage={getPercentageContentType("gif", contentType)} label="GIF" />
                      </div>
                      <div>
                        <ContentFormatGraph percentage={getPercentageContentType("music", contentType)} label="Music" />
                      </div>
                    </div>
                  </div>
                  <div className="Wallet__category mt-4 pb-3">
                    <p className="mb-4">
                      <strong>Category</strong>
                    </p>
                    <div className="d-flex Wallet-chart">
                      <div>
                        <ContentFormatGraph
                          percentage={getPercentageCategory("Art", nftDetail?.category, categories)}
                          label="Art"
                        />
                      </div>
                      <div>
                        <ContentFormatGraph
                          percentage={getPercentageCategory("Collectible", nftDetail?.category, categories)}
                          label="Collectible"
                        />
                      </div>
                      <div>
                        <ContentFormatGraph
                          percentage={getPercentageCategory("Fashion", nftDetail?.category, categories)}
                          label="Fashion"
                        />
                      </div>
                      <div>
                        <ContentFormatGraph
                          percentage={getPercentageCategory("Virtual Land", nftDetail?.category, categories)}
                          label="Virtual Land"
                        />
                      </div>
                      <div>
                        <ContentFormatGraph
                          percentage={getPercentageCategory("In Game Purchase", nftDetail?.category, categories)}
                          label="In-Game Purchase"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTModal;
