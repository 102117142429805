import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import tiwterIcon from "../../images/tiwter-white.svg";
import closeIcon from "../../images/closeIcon.svg";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RelevantTweets } from "../../assets/fakedata";
import moment from "moment";

const TweeterModal = (props: any) => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    props.setShowPopup(false);
    setShow(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <div className="modal__tweet">
        <div className="modal__title">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="d-flex">
              <img className="icon" src={tiwterIcon} alt="" />
              <h3 className="SubTitle__Titles__Title mb-0">Relevant Tweets</h3>
            </div>
            <div className="btn__close" onClick={handleClose}>
              <img src={closeIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="modal__body">
          {RelevantTweets.map((tweet: any, index) => (
            <div className="modal__body__item" key={index}>
              <div className="modal__body__item--header">
                <img className="avatar" src={tweet.includes.users[0].profile_image_url} alt="" />
              </div>
              <div className="modal__body__item--content">
                <div className="header">
                  <p className="mb-0">{tweet.includes.users[0].name}</p>
                  <p>
                    @{tweet.includes.users[0].username} - {moment(tweet.data.created_at).fromNow()}
                  </p>
                </div>
                <div className="description">{tweet.data.description ? tweet.data.description : tweet.data.text}</div>
                <div className="image-post">
                  <img src={tweet.includes.media[0]?.url ?? tweet.includes.users[0].profile_image_url} alt="" />
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">
                    <strong>{tweet.data.public_metrics.like_count}</strong> likes
                  </p>
                  <p className="mb-0">
                    <strong>{tweet.data.public_metrics.retweet_count}</strong> retweets
                  </p>
                  <p className="mb-0">
                    <strong>{tweet.data.public_metrics.quote_count}</strong> quote tweets
                  </p>
                </div>
                <div className="">
                  <a
                    className="d-flex align-items-center text-decoration-none"
                    href={tweet.data.entities.urls[tweet.data.entities.urls.length - 1].url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="see-tweet pe-1">See tweet</span>
                    <FontAwesomeIcon className="MockDetailsPopup-close__Icon" icon={faAngleRight} />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default TweeterModal;
