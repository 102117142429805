import nike from "./nike-icon.svg";
import adidas from "./adidas-icon.svg";
import asics from "./asics-icon.svg";
import converse from "./converse-icon.svg";
import lululemon from "./lululemon-icon.svg";
import newbalance from "./newbalance-icon.svg";
import puma from "./puma-icon.svg";
import reebok from "./reebok-icon.svg";
import underarmour from "./underarmour-icon.svg";

const BrandIcon = (props: any) => {
  const iconMap = {
    19: nike,
    21: puma,
    22: reebok,
    23: lululemon,
    24: newbalance,
    25: underarmour,
    26: converse,
    28: adidas,
    30: asics
  };
  return <img className="BrandIcon" src={iconMap[props.id]} alt={props.id} width={props.width} height={props.height} />;
};

export default BrandIcon;
