import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Title,
  CategoryScale,
} from "chart.js";
import { useStores } from "../../../store/RootStore";
import Loading from "../../../components/progress/Loading";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, TimeScale);

const EngagementGraph = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [normalizedGraphData, setNormalizedGraphData] = useState({});
  const { graphStore, competitorStore } = useStores();

  const options = {
    maintainAspectRatio: false,
    color: "#fff",
    plugins: {
      legend: {
        position: "top",
      },
    },

    interaction: {
      intersect: false,
      mode: "index",
    },

    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          color: "#fff",
          callback: function (value, index, values) {
            return value;
          },
        },
        font: {
          color: "#fff",
        },
        grid: {
          color: "#414141",
        },
      },

      xAxis: {
        type: "time",
        display: true,
        time: {
          isoWeekday: true,
          unit: "day",
          displayFormats: {
            day: "MMM DD",
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  useEffect(() => {
    const getCompetitors = (competitors) => {
      let competitorRawData;
      competitors.map(async (competitor) => {
        await graphStore.getEngagementForPreviousWeek(competitor);
        competitorRawData = graphStore.twitterEngagement;
        composeDisplayData(competitorRawData, competitors);
      });
    };

    const fetchData = async () => {
      const competitors = await competitorStore.getUserCompetitors();
      getCompetitors(competitors);
    };

    fetchData().then();
  }, [graphStore, competitorStore]);

  const composeDisplayData = (competitorsRawData, competitors) => {
    let labels = getTimelineLabels(competitorsRawData);
    const datasets = getDatasets(competitorsRawData);
    const normalizedGraphData = { labels, datasets: datasets };
    setNormalizedGraphData(normalizedGraphData);
    const newCompetitors = Array.from(new Set(competitors));
    if(normalizedGraphData.datasets.length === newCompetitors.length)
    setIsLoading(false);
  };

  function getTimelineLabelsFromGraphRawData(graphDataInstance, labelsList) {
    let rawDate;
    let normalizedDate;
    graphDataInstance.data.forEach((dataInstance) => {
      rawDate = dataInstance.date;
      normalizedDate = rawDate.slice(0, 10);
      if (!labelsList.includes(normalizedDate)) {
        labelsList.push(normalizedDate);
      }
    });
  }

  const getTimelineLabels = (competitorsRawData) => {
    const labelsList = [];
    competitorsRawData.map(graphDataInstance => getTimelineLabelsFromGraphRawData(graphDataInstance, labelsList));
    return labelsList;
  };

  const getDatasets = (competitorsRawData) => {
    const datasets = [];
    competitorsRawData.forEach((graphDataInstance, index) => {
      const datasetInstance = {
        label: graphDataInstance.label,
        data: getDataPoints(graphDataInstance),
        borderColor: getColor(index),

        pointRadius: 0,
        pointHoverRadius: 6,
        pointBackgroundColor: getColor(index),
        bezierCurve: true,
        borderWidth: 5,

        tension: 0.125,
      };

      datasets.push(datasetInstance);
    });
    return datasets;
  };

  const getDataPoints = (graphDataInstance) => {
    let dataPointsList = [];
    graphDataInstance.data.map((graphData) => dataPointsList.push(normalizeDataPoints(graphData.twitter_engagement)));
    return dataPointsList;
  };

  const normalizeDataPoints = (engagement) => {
    const castedEngagement = engagement.toString();
    const normalizedNumber = castedEngagement.slice(0, 4);
    return Number(normalizedNumber);
  };

  const getColor = (index) => {
    const colors = ["rgb(53, 162, 235)", "#7950b6", "#b30868", "#3e0439", "rgba(25, 32, 68, 0.5)"];
    return colors[index];
  };

  return (
    <>
      {!isLoading ? (
        <div className="Container-16-9">
          <div className="Container-16-9-content ">
            <div className="Graph">
              <span className="Graph__Label">
                <i>Engagement change %</i>
              </span>
              <Line data={normalizedGraphData} options={options} redraw={true} />
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default EngagementGraph;
