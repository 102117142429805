import TopNavigation from "../../components/navigation/TopNavigation";
import CompetitorDetails from "../competitor-details/CompetitorDetails";
import ResultsContent from "./ResultsContent";
import Engagement from "../engagement/Engagement";
import Profile from "../profile/Profile";
import Insights from "../insights/Insights";
import Campaigns from "../campaigns/Campaigns";

const dashboardPages = {
  dashboard: <ResultsContent />,
  engagement: <Engagement />,
  profile: <Profile />,
  insights: <Insights />,
  campaigns: <Campaigns />,
  "competitor-details": <CompetitorDetails />,
};

const SelectedPage = (props: any) => {
  return (
    <>
      <div className="Container__Dashboard">
        <TopNavigation />
        <div className="Container px-5">{dashboardPages[props.page]}</div>
      </div>
    </>
  );
};

export default SelectedPage;
