const Title = (props: any) => {
  return (
    <div className="Title Title__Container">
      <div className="Title__Titles d-flex align-items-center">
        <span className="Title__Titles__Title">{props.title}&nbsp;</span>
        {props.img && <img src={props.img} className="favourite-img ms-2" onClick={() => props.setFavourite(!props.favourite)} alt="" />}
        <span className="Title__Titles__SubTitle">{props.subTitle}</span>
      </div>
      {props.children}
    </div>
  );
};

export default Title;
