import React, { useState, useEffect, useCallback, createRef } from "react";
import { useScreenshot, createFileName } from "use-react-screenshot";
import Loading from "../../../components/progress/Loading";
import { formattedDate } from "../../../util/DateFormatter";
import CompetitorDetailGraph from "./CompetitorDetailGraph";
import circleIcon from "../../../styles/img/circleIcon.svg";
import SocialmediaIcon from "../../../components/icons/SocialmediaIcon";
import closeIcon from "../../../styles/img/close-icon.svg";
import { useStores } from "../../../store/RootStore";
import BrandIcon from "src/components/icons/BrandIcon";

const CompetitorDetailGraphContainer = (props) => {
  const [data, setData] = useState([]);
  const [bubbleContent, setBubbleContent] = useState([]);
  const [showGraphLightbox, setShowGraphLightbox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let ref2 = createRef(null);
  const { graphStore } = useStores();

  const getNormalizedGraphData = (competitorId, graphData, range) => {
    const circleImage = new Image();
    circleImage.src = circleIcon;
    if (graphData.length === 0) {
      graphData = [{ name: "", date: "", twitter_followers_count: 0, twitter_engagement: 0 }];
    }

    return {
      id: competitorId,
      name: graphData[0].name,
      labels: graphData.map((graphData) => graphData.date),
      range,
      datasets: [
        {
          yAxisID: "y",
          label: "FOLLOWERS",
          data: graphData.map((graphData) => graphData.twitter_followers_count),
          borderColor: "#7950b6",
          bezierCurve: true,

          tension: 0.125,
          borderWidth: 5,

          pointStyle: circleImage,
          pointRadius: 0,
        },
        {
          yAxisID: "y1",
          label: "ENGAGEMENT",
          pointStyle: circleImage,
          pointRadius: 0,

          data: graphData.map((graphData) => graphData.twitter_engagement),
          borderColor: "#e10c7c",
          tension: 0.125,
          borderWidth: 5,
        },
      ],
    };
  };

  const fetchAdditionalData = useCallback(
    async (range) => {
      setIsLoading(true);

      graphStore
        .getCompetitorDetailsByRange(range, props.competitorId)
        .then((graphData) => {
          setData(getNormalizedGraphData(props.competitorId, graphData, range));
        })
        .catch((e) => {
          console.log("error: ", e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [props.competitorId, graphStore]
  );

  useEffect(() => {
    const competitorId = props.competitorId;
    const setGraphData = (range, graphData) => {
      const normalizedGraphData = getNormalizedGraphData(competitorId, graphData, range);
      setData(normalizedGraphData);
      setIsLoading(false);
    };

    const fetchData = async (range) => {
      setIsLoading(true);
      const rawResponse = await graphStore.getCompetitorDetailsByRange(range, competitorId);
      setGraphData(range, rawResponse);
    };

    if (data.length === 0 || data.id !== competitorId) {
      fetchData(30).then();
    }
  }, [data, props.competitorId, graphStore, setData]);

  const [, takeScreenshot] = useScreenshot();
  const getImage = () => {
    takeScreenshot(ref2.current).then((file) => download(file));
  };

  const download = (image, { name = data.name + "-Price_x_Content_Frequency", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  function definedDaysRange(selected) {
    fetchAdditionalData(parseInt(selected.target.value)).then();
  }

  const handleSelect = (e) => {
    const d1 = parseInt(data.datasets[0].data[e]);
    const d2 = parseInt(data.datasets[1].data[e]);
    const date = formattedDate(data.labels[e]);
    setBubbleContent({
      date,
      followers: d1,
      engagement: d2,
    });
    setShowGraphLightbox(true);
  };

  const hideLightboxes = () => {
    setShowGraphLightbox(false);
  }

  return (
    <>
      {!isLoading ? (
        <>
          <div className="row">
            <div className="col col-12">
              <div className="Content__Container">
                <div className="Graph__Container__Header">
                  <div className="row p-0">
                    <div className="col col-12">
                      <div className="p-3 pb-0">
                        <div className="d-flex border-bottom justify-content-between align-items-center pb-3">
                          <div className="d-flex">
                            <strong>
                              Competitor Details:
                            </strong>
                            <div className="Graph__Container__Header__Brand">
                              <BrandIcon id={data.id} name={data.name} height={16} />
                              <strong>
                                {data.name}
                              </strong>
                              <span>
                                Followers x Engagement
                              </span>
                            </div>
                          </div>
                          <button className="Button Button--snapshot d-none d-sm-inline-block" onClick={getImage}>
                            Take Snapshot:
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="29.249"
                              height="24.203"
                              viewBox="0 0 29.249 24.203"
                            >
                              <g
                                id="Icon_feather-camera"
                                data-name="Icon feather-camera"
                                transform="translate(-0.75 -3.75)"
                              >
                                <path
                                  id="Path_1413"
                                  data-name="Path 1413"
                                  d="M29.249,24.681A2.523,2.523,0,0,1,26.726,27.2H4.023A2.523,2.523,0,0,1,1.5,24.681V10.806A2.523,2.523,0,0,1,4.023,8.284H9.068L11.59,4.5h7.568l2.523,3.784h5.045a2.523,2.523,0,0,1,2.523,2.523Z"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                />
                                <path
                                  id="Path_1414"
                                  data-name="Path 1414"
                                  d="M22.09,18.545A5.045,5.045,0,1,1,17.045,13.5a5.045,5.045,0,0,1,5.045,5.045Z"
                                  transform="translate(-1.671 -1.432)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                />
                              </g>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col col-12">
                      <div className="d-flex align-items-center justify-content-between px-3">
                        <div className="ResolutionPicker pt-0 pb-0 align-items-center">
                          <label className="GraphContainer__Label  d-none d-sm-block">Resolution Period:</label>

                          <div role="group" aria-label="Basic radio toggle button group">
                            <input
                              type="radio"
                              className="btn-check"
                              name="resolution"
                              id="resolution1"
                              value="30"
                              onChange={definedDaysRange.bind(this)}
                              checked={data.range === 30}
                            />
                            <label className="btn btn-radio" htmlFor="resolution1">
                              30D
                            </label>

                            <input
                              type="radio"
                              className="btn-check"
                              name="resolution"
                              id="resolution2"
                              value="14"
                              onChange={definedDaysRange.bind(this)}
                              checked={data.range === 14}
                            />
                            <label className="btn btn-radio" htmlFor="resolution2">
                              14D
                            </label>

                            <input
                              type="radio"
                              className="btn-check"
                              name="resolution"
                              id="resolution3"
                              value="7"
                              onChange={definedDaysRange.bind(this)}
                              checked={data.range === 7}
                            />
                            <label className="btn btn-radio" htmlFor="resolution3">
                              7D
                            </label>
                          </div>
                        </div>

                        <div role="group" aria-label="Basic radio toggle button group">
                          <label className="GraphContainer__Label  d-none d-sm-inline-block mx-2">Sort By Platform:</label>
                          <input
                            type="radio"
                            className="btn-check"
                            name="socialmediachoice_competitors"
                            id="sm_twitter_competitors"
                            value="twitter"
                            onChange={() => true}
                            defaultChecked
                          />
                          <label className="btn btn-radio mx-2" htmlFor="sm_twitter_competitors">
                            <SocialmediaIcon id={'twitter'} name={'twitter'} /><span className="pl-2 d-none d-sm-inline">Twitter</span>
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="socialmediachoice_competitors"
                            id="sm_youtube_competitors"
                            value="youtube"
                            disabled
                          />
                          <label className="btn btn-radio mx-2" htmlFor="sm_youtube_competitors">
                            <SocialmediaIcon id={'youtube'} name={'youtube'} />
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="socialmediachoice_competitors"
                            id="sm_tiktok_competitors"
                            value="tiktok"
                            disabled
                          />
                          <label className="btn btn-radio mx-0" htmlFor="sm_tiktok_competitors">
                            <SocialmediaIcon id={'tiktok'} name={'tiktok'} />
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="socialmediachoice_competitors"
                            id="sm_instagram_competitors"
                            value="instagram"
                            disabled
                          />
                          <label className="btn btn-radio mx-0" htmlFor="sm_instagram_competitors">
                            <SocialmediaIcon id={'instagram'} name={'instagram'} />
                          </label>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="Container-16-9">
                  <div className="Container-16-9-content ">
                    <div className="Graph" ref={ref2}>
                      <CompetitorDetailGraph data={data} onSelectBubble={handleSelect} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showGraphLightbox &&
            <div className="Lightbox animate--slideUp" onClick={hideLightboxes}>
              {(bubbleContent && bubbleContent.followers) &&
                <div className="Lightbox__Content pb-5">

                  <div className="pb-4 d-flex justify-content-between bg--black Lightbox__Header">
                    <div className="d-flex align-items-center w-100">
                      <i className='Circle'></i>
                      <span className="d-flex px-2">
                        Highlighted Detail
                      </span>
                      <span className="px-1">|</span>
                      <strong className='color--purple px-2'>{bubbleContent.date}</strong>
                    </div>

                    <button className="Lightbox__Close" onClick={hideLightboxes}>
                      <img className="mx-2" src={closeIcon} />
                    </button>
                  </div>

                  <section className="Lightbox__Body">
                    <p>
                      {data.name} followers amount was <strong>{bubbleContent.followers}</strong> with a total Twitter engagement of <strong>{bubbleContent.engagement}</strong> on {bubbleContent.date}.
                    </p>
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil tenetur cupiditate doloremque iusto vel similique asperiores maxime quae consequuntur et, cum, corrupti id! Culpa, facilis officia. Quam repellat eum ipsam.
                    </p>
                  </section>

                </div>
              }
            </div>
          }
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default CompetitorDetailGraphContainer;
