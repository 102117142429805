import { makeAutoObservable } from "mobx";
import MarketingApi from "../api/MarketingApi";
import { RootStore } from "./RootStore";

export const JWT_TOKEN = "token";

export interface LoginStoreInterface {
  loginIsErroneous: boolean;
  loginIsSuccessful: boolean;
  isLoading: boolean;
  metaMaskLoginIsErroneous: boolean;
  metaMaskIsLoading: boolean;
  onBoardingStatus: boolean;
  showLoginForm: boolean;
  showFlatForm: boolean;
}

class LoginStore implements LoginStoreInterface {
  public loginIsErroneous: boolean = false;
  public loginIsSuccessful: boolean = false;
  public isLoading: boolean = false;
  public metaMaskLoginIsErroneous: boolean = false;
  public metaMaskIsLoading: boolean = false;
  public onBoardingStatus: boolean = false;
  public rootStore: RootStore | undefined;
  public showLoginForm: boolean = false;
  public showFlatForm: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async loginWithCredentials(credentials: LoginCredentials) {
    this.isLoading = true;
    this.loginIsErroneous = false;
    this.loginIsSuccessful = false;

    try {
      const response = await MarketingApi.logIn(credentials);
      if (response.data.success === "true") {
        sessionStorage.setItem(JWT_TOKEN, response.data.token);
        this.onBoardingStatus = response.data.onBoardingStatus;
        this.loginIsSuccessful = true;
      }
    } catch {
      this.loginIsErroneous = true;
    } finally {
      this.isLoading = false;
    }
  }

  public async loginWithMetaMask(credentials: MetaMaskCredentials) {
    this.metaMaskIsLoading = true;
    this.metaMaskLoginIsErroneous = false;
    this.loginIsSuccessful = false;

    try {
      const response = await MarketingApi.logInWithMetaMask(credentials);
      if (response.data.success === "true") {
        sessionStorage.setItem(JWT_TOKEN, response.data.token);
        this.onBoardingStatus = response.data.onBoardingStatus;
        this.loginIsSuccessful = true;
      }
    } catch {
      this.metaMaskLoginIsErroneous = true;
    } finally {
      this.metaMaskIsLoading = false;
    }
  }

  public handleLoginFormDisplay(visible: boolean) {
    console.log('hmm', visible);
    
    return this.showLoginForm = visible;
  }

  public handleShowPlatform = (value: boolean) => {
    return this.showFlatForm = value
  }
}

export default LoginStore;
