import React from "react";

const ContentFormatGraph = (props) => {
  const stroke = props.percentage + ", 100";
  const color = props.percentage > 50 ? "purple" : "";
  const noBackground = props.simple ? "CircularGraphWrapper--noBg" : "";

  return (
    <div className={`CircularGraphWrapper ${noBackground}`}>
      <svg viewBox="0 0 36 36" className="circular-chart">
        <path
          className="circle-bg"
          d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className={`circle ${color}`}
          strokeDasharray={stroke}
          d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" className="percentage">
          {props.percentage}%
        </text>
      </svg>
      <span>{props.label}</span>
    </div>
  );
};

export default ContentFormatGraph;
