import Title from "../headlines/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import ContentFormatGraph from "src/pages/insights/components/ContentFormatGraph";
import { changeContractWallet, transformToImage, formatUSD } from "../../util/TransformWallet";
import { useEffect } from "react";
import transForm from "../../assets/TransformImage";
import moment from "moment";

const WalletModalResult = (props: any) => {
  const handleClose = () => {
    props.setShowWalletModal(false);
  };

  useEffect(() => {
    transForm();
    const image2Arr = Array.from(document.querySelectorAll(`.image${props.walletData.address}`));
    if (image2Arr) {
      image2Arr.forEach((selector) => {
        transformToImage(selector, props.walletData.address);
      });
    }
  }, []);

  return (
    <div className="modal__result__wallet WalletResult_modal mb-4">
      <div className="px-5">
        <div className="modal__result__title d-flex justify-content-between align-items-center mb-4">
          <p onClick={handleClose} className="mb-0 cursor-pointer">
            <FontAwesomeIcon className="MockDetailsPopup-close__Icon" icon={faAngleLeft} />
            <span>
              Back To <strong>Results</strong>
            </span>
          </p>
        </div>
        <Title title={`Web3 Wallet Analysis | ${changeContractWallet(props.walletData.address)}`} />
        <div className="WalletResult_modal--wrapper">
          <div className="Wallet_Analysis">
            <div className="Wrapper">
              <div className="innner_Left">
                <div className="photo">
                  <img className={`image${props.walletData.address}`} alt={""} />
                </div>
                <div className="info Card__Engagement Card__Engagement__Nft">
                  <div>
                    <p className="mb-3">{props.walletData.address}</p>
                    <span>Name Tag: Not Available</span>
                  </div>
                </div>
              </div>
              <div className="innner_Right">
                <div className="Card__Engagement Card__Engagement__Nft">
                  <div>
                    <span className="mb-3">Asset Held:</span>
                    <strong>{props.walletData.asset_held || props.walletData.assetsHeld}</strong>
                  </div>

                  <div>
                    <span className="mb-3">Wallet Value:</span>
                    <strong>
                      {formatUSD(
                        ((props.walletData.wallet_value || props.walletData.walletValues) / 10e18) *
                          props.walletData.usd
                      )}
                    </strong>
                  </div>

                  <div>
                    <span className="mb-3">ETH Balance:</span>
                    <strong>{Number(props.walletData.topAssets[0]?.ethBalance)?.toFixed(3)} ETH</strong>
                  </div>
                  <div>
                    <span className="mb-3">Latest Transaction:</span>
                    <strong>{moment(props.walletData.topAssets[0]?.latestTransaction).fromNow()}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Digital_Asset_Collection">
            <div className="wrapper">
              <div className="inner">
                <div className="Card__Nft__Title p-0 heading">
                  <h3>Digital Asset Collection - Top 6 Assets (USD value)</h3>
                </div>
                <div className="Collections">
                  <div className="Collection-lists">
                    {props.walletData.topAssets[0]?.data?.map((item: topWalletDetail) => (
                      <div className="Collection-item">
                        <div className="photo">
                          <img src={item.image} alt="" />
                        </div>
                        <div className="d-flex flex-column px-3">
                          <div className="Card__Nft__Title p-0">
                            <h3>{item.name}</h3>
                            <span className="my-2">
                              Created by {item.creator === "null" ? "Unknown creator" : item.creator}
                              <i className="VerifiedIcon mx-2"></i>
                            </span>
                          </div>
                          <span>
                            <strong>
                              {item.price_eth?.toFixed(1)} ETH [$
                              {Number(item.price_usd?.toFixed(2))?.toLocaleString()} USD]
                            </strong>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Wallet_Holdings">
            <div className="wrapper">
              <div className="inner">
                <div className="content-left">
                  <div className="heading">
                    <p>
                      <strong>
                        Industry Breakdown <span className="regular">[Macro] | Wallet Holdings</span>
                      </strong>
                    </p>
                  </div>
                  <div className="Holdings">
                    <div className="lists">
                      <div className="item">
                        <ContentFormatGraph percentage={100} label="Sports" />
                      </div>
                      <div className="item">
                        <ContentFormatGraph percentage={75} label="Gaming" />
                      </div>
                      <div className="item">
                        <ContentFormatGraph percentage={50} label="Fashion" />
                      </div>
                      <div className="item">
                        <ContentFormatGraph percentage={25} label="Art" />
                      </div>
                      <div className="item">
                        <ContentFormatGraph percentage={20} label="FMCG" />
                      </div>
                      <div className="item">
                        <ContentFormatGraph percentage={15} label="Entertainment" />
                      </div>
                      <div className="item">
                        <ContentFormatGraph percentage={0} label="Crypto" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-right">
                  <div className="heading">
                    <p>
                      <strong>
                        Brand Breakdown <span className="regular">[Micro] | Wallet Holdings</span>
                      </strong>
                    </p>
                  </div>
                  <div className="Holdings">
                    <div className="lists">
                      <div className="item">
                        <ContentFormatGraph percentage={100} label="Nike" />
                      </div>
                      <div className="item">
                        <ContentFormatGraph percentage={75} label="Adidas" />
                      </div>
                      <div className="item">
                        <ContentFormatGraph percentage={50} label="RTFKT" />
                      </div>
                      <div className="item">
                        <ContentFormatGraph percentage={25} label="Invisible Friends" />
                      </div>
                      <div className="item">
                        <ContentFormatGraph percentage={20} label="Etherium" />
                      </div>
                      <div className="item">
                        <ContentFormatGraph percentage={15} label="Otherside" />
                      </div>
                      <div className="item">
                        <ContentFormatGraph percentage={0} label="Meme Land" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletModalResult;
