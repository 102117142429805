import { useEffect, useState, memo } from "react";

import Title from "../headlines/Title";
import DayOfTheMonthGraph from "../../pages/insights/components/DayOfTheMonthGraph";
import { useStores } from "../../store/RootStore";
import Loading from "../progress/Loading";

function NftTransaction(props: any) {
  const { web3Store } = useStores();
  const [loading, setLoading] = useState(false);
  const [timeTransaction, setTimeTransaction] = useState(30);
  const [transactionCount, setTransactionCount] = useState({} as nftActivity);

  const arrayMax = (arr: number[]) => {
    return arr.reduce(function (p, v) {
      return p > v ? p : v;
    });
  };

  const DataGraph = transactionCount?.getTransactionCount?.slice(-12)?.map((item: nftTransaction) => item.numberSales);
  const labels = transactionCount?.getTransactionCount?.slice(-12)?.map((item: nftTransaction) => {
    const date = item?.dateTime?.split("T")[0]?.split("-");
    if (date?.length > 0) return date[1] + "/" + date[2];
    else {
      return "";
    }
  });

  const maxTransaction = transactionCount?.getTransactionCount
    ?.slice(-12)
    ?.find((item: any) => item.numberSales === arrayMax(DataGraph as []));
  const maxTransactionDate = maxTransaction?.dateTime?.split("T")[0];
  const formatDate = new Date(maxTransactionDate as string).toDateString().split(" ");

  useEffect(() => {
    const getNftTransaction = async () => {
      const payload = { id: props.nftId, search: timeTransaction };
      const data = await web3Store.getNFTTransactionCount(payload);
      if (data.getTransactionCount.length === 0) {
        setTransactionCount(props.transactionCount);
      } else {
        setTransactionCount(data);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await getNftTransaction();
      setLoading(false);
    };

    fetchData().then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeTransaction]);

  return (
    <div className="Content__Container">
      <Title title="Activity" />

      {!loading ? (
        <div className="Container p-4 Wallet__Activity">
          <div className="d-flex justify-content-between">
            <p>
              <strong>Transaction Count</strong>
            </p>
            <div
              role="group"
              onChange={(e: any) => setTimeTransaction(+e.target.value)}
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check px-0"
                defaultChecked={timeTransaction.toString() === "1"}
                name="nft_timing"
                id="nft_timing_1d"
                value={1}
              />
              <label className="btn btn-radio mx-2" htmlFor="nft_timing_1d">
                <span className="d-sm-inline mx-0">1D</span>
              </label>

              <input
                type="radio"
                defaultChecked={timeTransaction.toString() === "7"}
                className="btn-check"
                name="nft_timing"
                id="nft_timing_7d"
                value={7}
              />
              <label className="btn btn-radio mx-2" htmlFor="nft_timing_7d">
                <span className="d-sm-inline mx-0">7D</span>
              </label>

              <input
                type="radio"
                className="btn-check"
                name="nft_timing"
                id="nft_timing_30d"
                defaultChecked={timeTransaction.toString() === "30"}
                value={30}
              />
              <label className="btn btn-radio mx-2" htmlFor="nft_timing_30d">
                <span className="d-sm-inline mx-0">30D</span>
              </label>
            </div>
          </div>
          <span className="text-description">
            Graph displays the average price, volume, and number of sales of the item per day over a period of time.
          </span>
          <div className="InsightsGraphWrapper my-3">
            <DayOfTheMonthGraph data={DataGraph} labels={labels} />
          </div>
          <div className="d-flex mb-4 justify-content-between">
            <p>
              30 Day Avg. Price:{" "}
              <strong>
                {transactionCount?.avgPriceCount === 0 ? 0 : transactionCount?.avgPriceCount?.toFixed(4) || "---"} ETH
              </strong>
            </p>
            <p>
              30 Day Volume:{" "}
              <strong>
                {transactionCount?.volumesCount === 0 ? 0 : transactionCount?.volumesCount?.toFixed(4) || "---"} ETH
              </strong>
            </p>
          </div>
          <p className="text-main-color">
            {formatDate[2] !== undefined ? formatDate[1] + " " + formatDate[2] + ", " + formatDate[3] : ""} Details:
          </p>

          <div className="d-flex mb-2 wallet__price">
            <p>
              Avg. Price:{" "}
              <strong>{maxTransaction?.avgPrice === 0 ? 0 : maxTransaction?.avgPrice.toFixed(4) || "---"} ETH</strong>
            </p>
            <p>
              Volume: <strong>{maxTransaction?.volume === 0 ? 0 : maxTransaction?.volume.toFixed(4) || "---"}</strong>
            </p>
            <p>
              Number of Sales: <strong>{maxTransaction?.numberSales || "---"}</strong>
            </p>
            <p>
              Holders: <strong>{transactionCount?.holders === 0 ? 0 : transactionCount.holders || "---"}</strong>
            </p>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default memo(NftTransaction);
