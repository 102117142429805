import CompetitorDetailGraphContainer from "./components/CompetitorDetailGraphContainer";
import CompetitorAdditionalMetrics from "./components/CompetitorAdditionalMetrics";
import { useParams } from "react-router-dom";
import BreadcrumbNav from "src/components/navigation/BreadcrumbNav";

const CompetitorDetails = () => {
  let { competitorId } = useParams();

  return (
    <>
      <BreadcrumbNav backLabel="Results" />
      <div className="row">
        <div className="col-12">
          <CompetitorDetailGraphContainer competitorId={competitorId} />
        </div>
        <div className="col-12 pt-5">
          <CompetitorAdditionalMetrics competitorId={competitorId} />
        </div>
      </div>
    </>
  );
};

export default CompetitorDetails;
