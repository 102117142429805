import Title from "../../components/headlines/Title";
import SubTitle from "../../components/headlines/SubTitle";
import CampaignItem from "./components/CampaignItem";
import { campaigns } from "../../assets/fakedata";
import Pagination from "react-bootstrap/Pagination";
import heartIcon from "../../images/heart.svg";
import { useStores } from "../../store/RootStore";
import heartWhiteIcon from "../../images/heart-white.svg";
import { useState, useEffect } from "react";
import Loading from "../../components/progress/Loading";
import WalletResultModal from "../../components/Modal/WalletResultModal";

const Campaigns = () => {
  const [favourite, setFavourite] = useState(false);
  const [campaignsList, setCampaignsList] = useState([] as Campaign[]);
  const [isLoading, setIsLoading] = useState(true);
  const { recommendCampaignStore } = useStores();
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [walletData, setWalletData] = useState({});

  useEffect(() => {
    const getSaveCampaign = async () => {
      await recommendCampaignStore.getSavedCampaign();
      setIsLoading(false);
    };

    const fetchData = async () => {
      await getSaveCampaign();
    };

    fetchData().then();
  }, []);

  const handleRemoveSavedCampaign = async (campaign_id: string) => {
    setIsLoading(true);
    await recommendCampaignStore.removeSavedCampaign(campaign_id);
    window.scrollTo(0, 0);
    setIsLoading(false);
    setCampaignsList(recommendCampaignStore.savedCampaign);
  };

  const handleSaveCampaign = async (campaign: Campaign) => {
    setIsLoading(true);
    await recommendCampaignStore.saveCampaign(campaign);
    setIsLoading(false);
  };

  useEffect(() => {
    const container = document.querySelector(".Container__Dashboard");
    const walletModalHeight = document.querySelector(".WalletResult_modal")?.clientHeight;

    if (showWalletModal) {
      (container as HTMLElement).style.height = (walletModalHeight as number) + 100 + "px";
    } else {
      (container as HTMLElement).style.height = "auto";
    }
  }, [showWalletModal]);

  useEffect(() => {
    const normalCampaign: any = campaigns;
    favourite ? setCampaignsList(recommendCampaignStore.savedCampaign) : setCampaignsList(normalCampaign);
  }, [favourite, recommendCampaignStore.savedCampaign]);

  return (
    <>
      <Title
        title="Campaigns"
        setFavourite={setFavourite}
        favourite={favourite}
        img={favourite ? heartIcon : heartWhiteIcon}
      />
      <div className="Container p-0">
        <div className="row">
          <div className="col col-12">
            <div className="Content__Container mb-4 overflow-visible">
              <SubTitle title={favourite ? "Saved campaigns" : "Top recommended campaigns"} />
              {!isLoading ? (
                <div className="Container p-0">
                  <div className="row m-0">
                    {campaignsList.map((campaign, index) => (
                      <CampaignItem
                        key={index}
                        index={index}
                        setWalletData={setWalletData}
                        setShowWalletModal={setShowWalletModal}
                        handleSaveCampaign={handleSaveCampaign}
                        handleRemoveSavedCampaign={handleRemoveSavedCampaign}
                        favourite={favourite}
                        campaign={campaign}
                      />
                    ))}
                  </div>
                  <Pagination>
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Next />
                  </Pagination>
                </div>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </div>
      {showWalletModal && (
        <div className="popupResult">
          <WalletResultModal walletData={walletData} setShowWalletModal={setShowWalletModal} />
        </div>
      )}
    </>
  );
};

export default Campaigns;
