import React from "react";

const ProgressDots = (props) => {
  return (
    <div className="ProgressDots">
      <div className={`ProgressDots__Indicator ${props.step >= 1 ? "ProgressDots__Indicator--filled" : ""}`}></div>
      <div className={`ProgressDots__Indicator ${props.step >= 2 ? "ProgressDots__Indicator--filled" : ""}`}></div>
      <div className={`ProgressDots__Indicator ${props.step >= 3 ? "ProgressDots__Indicator--filled" : ""}`}></div>
    </div>
  );
};

export default ProgressDots;
