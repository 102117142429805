import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import walletIcon from "../../images/wallet.svg";
import closeIcon from "../../images/closeIcon.svg";
import WalletCard from "../../pages/results/WalletCard";
import { useStores } from "../../store/RootStore";
import Loading from "../progress/Loading";
import { toJS } from "mobx";

const WalletModal = (props: any) => {
  const { recommendCampaignStore, web3Store } = useStores();
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [walletCampaign, setWalletCampaign] = useState([] as relevantWallet[]);

  useEffect(() => {
    const getRelevantWallets = async (address: string) => {
      const response: any = await recommendCampaignStore.getRelevantWallets(address);
      if (response) setWalletCampaign(toJS(response));
    };

    const getUSD = async () => {
      if (web3Store.uSD === 0) {
        await web3Store.getUSD();
      }
    };

    const fetchData = async () => {
      setIsLoading(true);
      await props.wallet.forEach((item: any) => getRelevantWallets(item.Address));
      await getUSD();
      setIsLoading(false);
    };

    fetchData().then();
  }, []);

  const handleClose = () => {
    props.setShowWalletPopup(false);
    setShow(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <div className="modal__wallet modal__wallet-responsive">
        <div className="modal__title">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="d-flex">
              <img className="icon" src={walletIcon} alt="" />
              <h3 className="SubTitle__Titles__Title mb-0">Relevant Wallets</h3>
            </div>
            <div className="btn__close" onClick={handleClose}>
              <img src={closeIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="modal__body pb-0">
          {!isLoading ? (
            <div className="Wallet__card__item p-0">
              {walletCampaign.map((wallet, index) => {
                return (
                  <WalletCard
                    setShowWalletModal={props.setShowWalletModal}
                    setWalletData={props.setWalletData}
                    setShowWalletPopup={props.setShowWalletPopup}
                    index={index}
                    address={props.wallet[index].Address}
                    usd={web3Store.uSD}
                    key={index}
                    wallet={wallet}
                    class="col-12 col-md-12 mb-4"
                  />
                );
              })}
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default WalletModal;
