import { Form, Button } from "react-bootstrap";
import { useStores } from "../../../store/RootStore";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Observer } from "mobx-react";

const CompanyForm = (props: any) => {
  const { companyStore } = useStores();
  const [nextDisabled, setNextDisabled] = useState(true);
  const detailsNotErroneous = !companyStore.getCompanyFormDetailsIsErroneous;
  let navigate = useNavigate();

  useEffect(() => {
    enableNextIfPossible();
  }, []);

  const populateCompanyEmployeesDropdown = () => {
    const companyEmployees = companyStore.companyEmployees;
    return (
      detailsNotErroneous &&
      companyEmployees.map((sizeItem: CompanyDetails) => (
        <option key={sizeItem.id} value={sizeItem.id.toString()}>
          {sizeItem.value}
        </option>
      ))
    );
  };

  const populateIndustryDropdown = () => {
    const companyIndustry = companyStore.companyIndustry;
    return (
      detailsNotErroneous &&
      companyIndustry.map((industryItem: CompanyDetails) => (
        <option key={industryItem.id} value={industryItem.id.toString()}>
          {industryItem.value}
        </option>
      ))
    );
  };

  const populateRevenueDropdown = () => {
    const companyRevenue = companyStore.companyRevenue;
    return (
      detailsNotErroneous &&
      companyRevenue.map((revenueItem: CompanyDetails) => (
        <option key={revenueItem.id} value={revenueItem.id.toString()}>
          {revenueItem.value}
        </option>
      ))
    );
  };

  const postCompanyForm = () => {
    companyStore.postCompanyForm().then(props.handleNextClick({ step: 2 }));
  };

  const setCompanyName = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    companyStore.setCompanyName(event);
    enableNextIfPossible();
  };

  const setCompanyWebsite = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    companyStore.setCompanyWebsite(event);
    enableNextIfPossible();
  };

  const setCompanyTwitter = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    companyStore.setCompanyTwitter(event);
    enableNextIfPossible();
  };

  const setCompanyIndustry = (event: React.ChangeEvent<HTMLSelectElement>) => {
    companyStore.setCompanyIndustry(event);
    enableNextIfPossible();
  };

  const setCompanyEmployees = (event: React.ChangeEvent<HTMLSelectElement>) => {
    companyStore.setCompanyEmployees(event);
    enableNextIfPossible();
  };

  const setCompanyRevenue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    companyStore.setCompanyRevenue(event);
    enableNextIfPossible();
  };

  const enableNextIfPossible = () => {
    if (companyStore.checkRequiredFields()) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-8 d-flex align-items-center">
          <div className="pe-5">
            <strong className="color--purple">Step 01</strong>
            <h1 className="pt-1 pb-3">Let's Get To Know Your Brand</h1>
            <span>
              To start, enter your brand’s details in the fields to the right of your screen.
              <br/>
              <br/>
              FYI: The Ojamu onboarding process will take 3 minutes or less.
            </span>
          </div>
        </div>
        <div className="col-4">
          <h3 className="pb-3">Brand Details</h3>
          <form>
            <Form.Group className="mb-3">
              <Observer>
                {() => (
                  <Form.Control
                    className="Input"
                    type="text"
                    onChange={setCompanyName}
                    placeholder="Brand Name"
                    value={companyStore.companyName}
                  />
                )}
              </Observer>
            </Form.Group>

            <Form.Group className="mb-3">
              <Observer>
                {() => (
                  <Form.Control
                    className="Input"
                    type="text"
                    onChange={setCompanyWebsite}
                    placeholder="Website"
                    value={companyStore.companyWebsite}
                  />
                )}
              </Observer>
            </Form.Group>

            <Form.Group className="mb-3">
              <Observer>
                {() => (
                  <Form.Control
                    className="Input"
                    type="text"
                    onChange={setCompanyTwitter}
                    placeholder="Twitter URL"
                    value={companyStore.companyTwitter}
                  />
                )}
              </Observer>
            </Form.Group>

            <Form.Group className="mb-3">
              <Observer>
                {() => (
                  <Form.Select
                    className="Input"
                    onChange={setCompanyIndustry}
                    value={companyStore.selectedIndustryValue}
                  >
                    <option> Industry </option>
                    {populateIndustryDropdown()}
                  </Form.Select>
                )}
              </Observer>
            </Form.Group>

            <Form.Group className="mb-3">
              <Observer>
                {() => (
                  <Form.Select
                    className="Input"
                    onChange={setCompanyEmployees}
                    value={companyStore.selectedEmployeesValue}
                  >
                    <option> Size </option>
                    {populateCompanyEmployeesDropdown()}
                  </Form.Select>
                )}
              </Observer>
            </Form.Group>

            <Form.Group className="mb-3">
              <Observer>
                {() => (
                  <Form.Select className="Input" onChange={setCompanyRevenue} value={companyStore.selectedRevenueValue}>
                    <option>Revenue</option>
                    {populateRevenueDropdown()}
                  </Form.Select>
                )}
              </Observer>
            </Form.Group>

            <div className="row pt-3">
              <div className="col-6">
                <Button className="Button Button--primary Button--primary--outlined w-100" onClick={() => navigate(-1)}>
                  <span>Back</span>
                </Button>
              </div>
              <div className="col-6">
                <Button
                  className="Button Button--primary w-100"
                  onClick={() => postCompanyForm()}
                  disabled={nextDisabled}
                >
                  <span>Next</span>

                  <svg className="Button__Icon" xmlns="http://www.w3.org/2000/svg" width="5.258" height="8.514" viewBox="0 0 5.258 8.514">
                    <path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M10,11.76l3.257,3.25,3.257-3.25,1,1-4.257,4.257L9,12.76Z" transform="translate(-11.76 17.514) rotate(-90)" fill="#fff"/>
                  </svg>

                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CompanyForm;
