import { makeAutoObservable } from "mobx";
import MarketingApi from "../api/MarketingApi";
import { RootStore } from "./RootStore";
import { formattedDate } from "../util/DateFormatter";

export interface GraphStoreInterface {
  getTwitterEngagementIsErroneous: boolean;
  getCompetitorDetailsByRangeIsErroneous: boolean;
  twitterEngagementGraphResponse: CompetitorTwitterMetricsNormalizedResponse[];
  competitorDetailsResponse: CompetitorTwitterMetricsNormalizedResponse[];
  twitterEngagementGraphData: TwitterEngagementGraphData[];
  twitterEngagement: TwitterEngagement[];
  competitorInDepthGraphData: CompetitorInDepthGraph[];
}

class GraphStore implements GraphStoreInterface {
  public getTwitterEngagementIsErroneous: boolean = false;
  public getCompetitorDetailsByRangeIsErroneous: boolean = false;
  public twitterEngagementGraphResponse: CompetitorTwitterMetricsNormalizedResponse[] = [];
  public competitorDetailsResponse: CompetitorTwitterMetricsNormalizedResponse[] = [];
  public twitterEngagementGraphData: TwitterEngagementGraphData[] = [];
  public twitterEngagement: TwitterEngagement[] = [];
  public competitorInDepthGraphData: CompetitorInDepthGraph[] = [];
  public rootStore: RootStore | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async getCompetitorDetailsByRange(range: number, competitorId: number) {
    this.getCompetitorDetailsByRangeIsErroneous = false;
    this.cleanCompetitorDetailsState();

    const timeFrame = this.getDateRange(range);

    const request: CompetitorTwitterMetricsRequest = {
      account: competitorId,
      startDate: timeFrame.startDate,
      endDate: timeFrame.endDate,
    };

    try {
      const response = await MarketingApi.getCompetitorTwitterMetrics(request);
      if (response.data.success === "true") {
        this.competitorDetailsResponse = response.data.graph.data;
        const label = response.data.graph.label;
        this.createCompetitorInDepthGraphData(label);
        return this.competitorInDepthGraphData;
      }
      return;
    } catch {
      this.getCompetitorDetailsByRangeIsErroneous = true;
      return;
    }
  }

  public async getEngagementForPreviousWeek(competitorId: number) {
    this.getTwitterEngagementIsErroneous = false;
    this.cleanEngagementState();

    const timeFrame = this.getDateRange(8);

    const request: CompetitorTwitterMetricsRequest = {
      account: competitorId,
      startDate: timeFrame.startDate,
      endDate: timeFrame.endDate,
    };

    try {
      const response = await MarketingApi.getCompetitorTwitterMetrics(request);
      if (response.data.success === "true") {
        this.twitterEngagementGraphResponse = response.data.graph.data;
        const label = response.data.graph.label;
        this.getTwitterEngagementGraphData();
        this.getTwitterEngagementByCompetitor(competitorId, label);
      }
    } catch {
      this.getTwitterEngagementIsErroneous = true;
    }
  }

  public createCompetitorInDepthGraphData(label: string) {
    this.competitorDetailsResponse.forEach((responseInstance) => {
      const competitorInDepthGraphDataPoint = {
        name: label,
        date: this.normalizeDate(responseInstance.date),
        twitter_engagement: responseInstance.twitter_engagement,
        twitter_followers_count: responseInstance.twitter_followers_count,
      };
      this.competitorInDepthGraphData.push(competitorInDepthGraphDataPoint);
    });
  }

  private normalizeDate(rawDate: string) {
    return rawDate.slice(0, 10);
  }

  private cleanCompetitorDetailsState() {
    this.twitterEngagementGraphData = [];
    this.competitorInDepthGraphData = [];
  }

  private cleanEngagementState() {
    this.twitterEngagementGraphResponse = [];
    this.twitterEngagementGraphData = [];
  }

  private getDateRange(dateRange: number) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const past = new Date();
    const pastRange = dateRange + 1;
    past.setDate(past.getDate() - pastRange);

    return {
      startDate: formattedDate(past, "ymd"),
      endDate: formattedDate(yesterday, "ymd"),
    };
  }

  private getTwitterEngagementGraphData() {
    this.twitterEngagementGraphData = [];
    this.twitterEngagementGraphResponse.map((graphRateResponse) => {
      const graphData: TwitterEngagementGraphData = {
        date: graphRateResponse.date,
        twitter_engagement: graphRateResponse.twitter_engagement,
      };
      return this.twitterEngagementGraphData.push(graphData);
    });
  }

  private getTwitterEngagementByCompetitor(competitorId: number, label: string) {
    const singleGrowthRateByCompetitor: TwitterEngagement = {
      competitorId: competitorId,
      label: label,
      data: this.twitterEngagementGraphData,
    };
    const index = this.twitterEngagement.findIndex(
      (item) => item.competitorId === singleGrowthRateByCompetitor.competitorId
    );
    if (index === -1) this.twitterEngagement.push(singleGrowthRateByCompetitor);
  }
}

export default GraphStore;
