import { useNavigate } from "react-router-dom";

const BreadcrumbNav = (props: any) => {
  const navigate = useNavigate();

  return (
    <div className="BreadCrumb">
      <a className="BreadCrumb__Link" href="#" onClick={() => navigate(-1)}>
        <i></i>
        <span className="px-1">
          Back to
        </span>
        <strong>{props.backLabel}</strong>
      </a>
      <div>
        <span>
          Results
        </span>
        <span>
          Competitor Details
        </span>
      </div>
    </div>
  )
}

export default BreadcrumbNav