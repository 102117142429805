const NftCard = (props: any) => {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });

  const handleShowDetailNFT = () => {
    props.setShowNFTModal(true);
    props.setNFTData(props.nft);
  };

  return (
    <div className={props.class} key={props.index}>
      <div className="Card Card__Nft">
        <div className="Card__Number">0{props.index + 1}</div>
        <div className="d-flex Card__Top">
          <div className="Card__ImageContainer Card__ImageContainer__Nft">
            <img className="Card__ImageContainer__Image" src={props.nft.image_url} alt={""} />
          </div>

          <div className="Card__Nft__Title">
            <h3>{props.nft.name}</h3>
            <span>
              Created by {props.nft.creator}
              {props.nft.verified && <i className="VerifiedIcon mx-2"></i>}
            </span>
          </div>
        </div>

        <div className="Card__Content">
          <div className="Card__Engagement Card__Engagement__Nft">
            <div>
              <span>Volume:</span>
              <strong>{formatter.format(props.nft.total_volume)} ETH</strong>
            </div>

            <div>
              <span>Floor Price:</span>

              <span style={{ color: "white" }}>
                <span style={{ padding: 0, color: "white", fontSize: 15, textAlign: "center" }}>
                  <strong>
                    {props.nft?.floor_price_eth
                      ? Number(props.nft?.floor_price_eth?.toFixed(2))?.toLocaleString() + " ETH"
                      : "---"}
                  </strong>
                </span>
                {props.nft?.floor_price_eth ? ` [$${Number(props.nft.floor_price_usd)?.toFixed(2)} USD]` : ""}
              </span>
            </div>

            <div>
              <span>Owners:</span>
              <strong>{formatter.format(props.nft.owner_count)}</strong>
            </div>

            <div>
              <span>Items:</span>
              <strong>{formatter.format(props.nft.item_count)}</strong>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center pt-2">
            <div onClick={handleShowDetailNFT} className="cursor-pointer">
              <a className="Card__Link" href="/dashboard" style={{ pointerEvents: "none" }}>
                View Details
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftCard;
