import Logo from "../logo/ojamu_small.png";

const Loading = () => {
  return (
    <div className="Loading">
      <img src={Logo} alt="OJAMU Loading" width="56" height="64" />
    </div>
  );
};

export default Loading;
