export const convertMagnitude = (value: number) => {
  const valueLength = value.toString().length;
  if (checkThousands(valueLength)) {
    const roundedThousands = (value / 1000).toFixed(1);
    return roundedThousands.toString() + "K";
  }

  if (checkMillions(valueLength)) {
    const roundedMillions = (value / 1000000).toFixed(1);
    return roundedMillions.toString() + "M";
  }

  return value;
};

const checkThousands = (valueToEvaluate: number) => {
  return valueToEvaluate > 3 && valueToEvaluate < 7 ? true : false;
};

const checkMillions = (valueToEvaluate: number) => {
  return valueToEvaluate > 6 && valueToEvaluate < 10 ? true : false;
};
