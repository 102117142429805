import nftCollections from "./web3space/svg/nc-icon.svg";
import virtualLand from "./web3space/svg/vl-icon.svg";
import blockchainGaming from "./web3space/svg/bg-icon.svg";
import virtualStore from "./web3space/svg/vs-icon.svg";

const Web3Icon = (props: any) => {
  const iconMap = {
    1: nftCollections,
    2: virtualLand,
    3: blockchainGaming,
    4: virtualStore
  };

  const icon = iconMap[props.id];

  if (icon) {
    return <img className={`Web3Icon ${props.className}`} src={iconMap[props.id]} alt={props.id} />;
  } else {
    return <></>
  }
};

export default Web3Icon;
