export const RelevantTweets = [
  {
    data: {
      source: "Sprout Social",
      lang: "en",
      entities: {
        urls: [
          {
            start: 212,
            end: 235,
            url: "https://t.co/rqrMie8Y7H",
            expanded_url: "https://sandboxgame.gitbook.io/the-sandbox/funds-programs-creators/metaverse-agencies",
            display_url: "sandboxgame.gitbook.io/the-sandbox/fu…",
            images: [
              {
                url: "https://pbs.twimg.com/news_img/1546466585690324993/f7mgf2yz?format=jpg&name=orig",
                width: 1280,
                height: 381,
              },
              {
                url: "https://pbs.twimg.com/news_img/1546466585690324993/f7mgf2yz?format=jpg&name=150x150",
                width: 150,
                height: 150,
              },
            ],
            status: 200,
            title: "Metaverse Agencies",
            description:
              "Learn about some of the agencies helping to bring brands to the metaverse and how to reach out to them.",
            unwound_url: "https://sandboxgame.gitbook.io/the-sandbox/funds-programs-creators/metaverse-agencies",
          },
          {
            start: 236,
            end: 259,
            url: "https://t.co/OUlRfJfflh",
            expanded_url: "https://twitter.com/TheSandboxGame/status/1500048505779503106/photo/1",
            display_url: "pic.twitter.com/OUlRfJfflh",
            media_key: "3_1500048501480251398",
          },
        ],
        hashtags: [
          {
            start: 58,
            end: 63,
            tag: "Web3",
          },
          {
            start: 111,
            end: 121,
            tag: "Metaverse",
          },
        ],
      },
      context_annotations: [
        {
          domain: {
            id: "30",
            name: "Entities [Entity Service]",
            description:
              "Entity Service top level domain, every item that is in Entity Service should be in this domain",
          },
          entity: {
            id: "848920371311001600",
            name: "Technology",
            description: "Technology and computing",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "848920371311001600",
            name: "Technology",
            description: "Technology and computing",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "913142676819648512",
            name: "Cryptocurrencies",
            description: "Cryptocurrency",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "1427745203700469767",
            name: "Metaverse",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "1486271512655003652",
            name: "Web3",
          },
        },
        {
          domain: {
            id: "165",
            name: "Technology",
            description: "for individual and types of technology, e.g., food technology, 3D printing",
          },
          entity: {
            id: "848920371311001600",
            name: "Technology",
            description: "Technology and computing",
          },
        },
      ],
      created_at: "2022-03-05T10:00:07.000Z",
      id: "1500048505779503106",
      reply_settings: "everyone",
      public_metrics: {
        retweet_count: 195,
        reply_count: 56,
        like_count: 853,
        quote_count: 2,
      },
      author_id: "347831597",
      conversation_id: "1500048505779503106",
      text: "We’re all about bringing new brands and partners into the #Web3 space and helping them build a presence in the #Metaverse! 🌐\n\nTake a look at our latest directory of the agencies that are helping us do just that! https://t.co/rqrMie8Y7H https://t.co/OUlRfJfflh",
      attachments: {
        media_keys: ["3_1500048501480251398"],
      },
      possibly_sensitive: false,
    },
    includes: {
      media: [
        {
          width: 1920,
          url: "https://pbs.twimg.com/media/FNE-KhyWYAY_jzT.jpg",
          type: "photo",
          media_key: "3_1500048501480251398",
          height: 1080,
        },
      ],
      users: [
        {
          verified: true,
          profile_image_url: "https://pbs.twimg.com/profile_images/1543874421991133184/FdMBQ7uo_normal.jpg",
          created_at: "2011-08-03T12:46:21.000Z",
          entities: {
            url: {
              urls: [
                {
                  start: 0,
                  end: 23,
                  url: "https://t.co/0WFJqWWW4d",
                  expanded_url: "https://linktr.ee/sandbox",
                  display_url: "linktr.ee/sandbox",
                },
              ],
            },
            description: {
              urls: [
                {
                  start: 109,
                  end: 132,
                  url: "https://t.co/mnYOKa0WAA",
                  expanded_url: "http://register.sandbox.game",
                  display_url: "register.sandbox.game",
                },
              ],
            },
          },
          id: "347831597",
          description:
            "The Sandbox is a virtual gaming world where players can build, own, and monetize their gaming experiences. \n\nhttps://t.co/mnYOKa0WAA",
          public_metrics: {
            followers_count: 1060063,
            following_count: 1190,
            tweet_count: 12659,
            listed_count: 4193,
          },
          name: "The Sandbox",
          username: "TheSandboxGame",
          pinned_tweet_id: "1562472497181691904",
          protected: false,
          url: "https://t.co/0WFJqWWW4d",
        },
      ],
    },
  },
  {
    data: {
      lang: "en",
      entities: {
        urls: [
          {
            start: 199,
            end: 222,
            url: "https://t.co/SxN30hCtK9",
            expanded_url: "http://2974.Currybrand.com",
            display_url: "2974.Currybrand.com",
            images: [
              {
                url: "https://pbs.twimg.com/news_img/1542629706612236291/SSEJ6gwK?format=png&name=orig",
                width: 256,
                height: 256,
              },
              {
                url: "https://pbs.twimg.com/news_img/1542629706612236291/SSEJ6gwK?format=png&name=150x150",
                width: 150,
                height: 150,
              },
            ],
            status: 200,
            title: "Genesis Curry Flow",
            description: "Change the game for GOOD. Wear your Genesis Curry Flow into the Metaverse.",
            unwound_url: "https://2974.currybrand.com/",
          },
          {
            start: 223,
            end: 246,
            url: "https://t.co/NMdwRXOdkl",
            expanded_url: "https://twitter.com/UnderArmour/status/1473375443030052866/video/1",
            display_url: "pic.twitter.com/NMdwRXOdkl",
            media_key: "7_1473375376923574281",
          },
        ],
        mentions: [
          {
            start: 95,
            end: 110,
            username: "StephenCurry30",
            id: "42562446",
          },
        ],
      },
      text: "In celebration of the greatest 3-point shooter in the UNIVERSE, we are dropping 2,974 pairs of @stephencurry30’s recording breaking 👟 in the metaverse. The “Genesis Curry Flow” NFT drops at 8pm EST: https://t.co/SxN30hCtK9 https://t.co/NMdwRXOdkl",
      possibly_sensitive: false,
      author_id: "23114836",
      conversation_id: "1473375443030052866",
      created_at: "2021-12-21T19:30:54.000Z",
      reply_settings: "everyone",
      id: "1473375443030052866",
      source: "Twitter for iPhone",
      attachments: {
        media_keys: ["7_1473375376923574281"],
      },
      public_metrics: {
        retweet_count: 156,
        reply_count: 78,
        like_count: 692,
        quote_count: 38,
      },
      context_annotations: [
        {
          domain: {
            id: "3",
            name: "TV Shows",
            description: "Television shows from around the world",
          },
          entity: {
            id: "10000607734",
            name: "NBA Basketball",
            description: "All the latest basketball action from the NBA.",
          },
        },
        {
          domain: {
            id: "11",
            name: "Sport",
            description: "Types of sports, like soccer and basketball",
          },
          entity: {
            id: "706083902411055104",
            name: "Basketball",
          },
        },
        {
          domain: {
            id: "26",
            name: "Sports League",
            description: "",
          },
          entity: {
            id: "706083889454813185",
            name: "NBA",
          },
        },
        {
          domain: {
            id: "45",
            name: "Brand Vertical",
            description: "Top level entities that describe a Brands industry",
          },
          entity: {
            id: "781974596148793345",
            name: "Business & finance",
          },
        },
        {
          domain: {
            id: "30",
            name: "Entities [Entity Service]",
            description:
              "Entity Service top level domain, every item that is in Entity Service should be in this domain",
          },
          entity: {
            id: "1298254626077122560",
            name: "Sporting goods",
          },
        },
        {
          domain: {
            id: "65",
            name: "Interests and Hobbies Vertical",
            description: "Top level interests and hobbies groupings, like Food or Travel",
          },
          entity: {
            id: "781974596148793345",
            name: "Business & finance",
          },
        },
        {
          domain: {
            id: "30",
            name: "Entities [Entity Service]",
            description:
              "Entity Service top level domain, every item that is in Entity Service should be in this domain",
          },
          entity: {
            id: "848920371311001600",
            name: "Technology",
            description: "Technology and computing",
          },
        },
        {
          domain: {
            id: "66",
            name: "Interests and Hobbies Category",
            description: "A grouping of interests and hobbies entities, like Novelty Food or Destinations",
          },
          entity: {
            id: "913142676819648512",
            name: "Cryptocurrencies",
            description: "Cryptocurrency",
          },
        },
        {
          domain: {
            id: "67",
            name: "Interests and Hobbies",
            description:
              "Interests, opinions, and behaviors of individuals, groups, or cultures; like Speciality Cooking or Theme Parks",
          },
          entity: {
            id: "847894353708068864",
            name: "Investing",
            description: "Investing",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "706083889454813185",
            name: "NBA",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "706083902411055104",
            name: "Basketball",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "847894353708068864",
            name: "Investing",
            description: "Investing",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "847900493514891265",
            name: "Sports",
            description: "Sports",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "913142676819648512",
            name: "Cryptocurrencies",
            description: "Cryptocurrency",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "1265692717071265792",
            name: "NBA players",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "1298254626077122560",
            name: "Sporting goods",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "1369311988040355840",
            name: "NFTs",
            description: "Non-fungible tokens",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "1427745203700469767",
            name: "Metaverse",
          },
        },
        {
          domain: {
            id: "165",
            name: "Technology",
            description: "for individual and types of technology, e.g., food technology, 3D printing",
          },
          entity: {
            id: "848920371311001600",
            name: "Technology",
            description: "Technology and computing",
          },
        },
        {
          domain: {
            id: "10",
            name: "Person",
            description: "Named people in the world like Nelson Mandela",
          },
          entity: {
            id: "809849103983071232",
            name: "Stephen Curry",
          },
        },
        {
          domain: {
            id: "30",
            name: "Entities [Entity Service]",
            description:
              "Entity Service top level domain, every item that is in Entity Service should be in this domain",
          },
          entity: {
            id: "781974597176467456",
            name: "Sports/Outdoor - Fashion",
          },
        },
        {
          domain: {
            id: "47",
            name: "Brand",
            description: "Brands and Companies",
          },
          entity: {
            id: "10024011486",
            name: "Under Armour",
          },
        },
        {
          domain: {
            id: "60",
            name: "Athlete",
            description: "An athlete in the world, like Serena Williams or Lionel Messi",
          },
          entity: {
            id: "809849103983071232",
            name: "Stephen Curry",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "10024011486",
            name: "Under Armour",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "706083889454813185",
            name: "NBA",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "706083902411055104",
            name: "Basketball",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "809849103983071232",
            name: "Stephen Curry",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "847900493514891265",
            name: "Sports",
            description: "Sports",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "1248316002317643776",
            name: "Athletic apparel",
          },
        },
      ],
    },
    includes: {
      media: [
        {
          preview_image_url: "https://pbs.twimg.com/ext_tw_video_thumb/1473375376923574281/pu/img/Ha9-g5LahEOEkl6Z.jpg",
          height: 1280,
          duration_ms: 39539,
          media_key: "7_1473375376923574281",
          public_metrics: {
            view_count: 28014,
          },
          variants: [
            {
              bit_rate: 950000,
              content_type: "video/mp4",
              url: "https://video.twimg.com/ext_tw_video/1473375376923574281/pu/vid/480x600/3PDGQoEMgmLX2d62.mp4?tag=12",
            },
            {
              bit_rate: 2176000,
              content_type: "video/mp4",
              url: "https://video.twimg.com/ext_tw_video/1473375376923574281/pu/vid/720x900/cQaM4fPeHim34Xk4.mp4?tag=12",
            },
            {
              content_type: "application/x-mpegURL",
              url: "https://video.twimg.com/ext_tw_video/1473375376923574281/pu/pl/hUFIY05rCPwuq6Vf.m3u8?tag=12&container=fmp4",
            },
            {
              bit_rate: 632000,
              content_type: "video/mp4",
              url: "https://video.twimg.com/ext_tw_video/1473375376923574281/pu/vid/320x400/YOVv11eS6IV94Op6.mp4?tag=12",
            },
          ],
          width: 1024,
          type: "video",
        },
      ],
      users: [
        {
          public_metrics: {
            followers_count: 970638,
            following_count: 894,
            tweet_count: 26666,
            listed_count: 3285,
          },
          description: "Under Armour makes you better. #TheOnlyWayIsThrough",
          name: "Under Armour",
          protected: false,
          url: "https://t.co/wdMGfGq94p",
          id: "23114836",
          pinned_tweet_id: "1485982775907950601",
          username: "UnderArmour",
          entities: {
            url: {
              urls: [
                {
                  start: 0,
                  end: 23,
                  url: "https://t.co/wdMGfGq94p",
                  expanded_url: "http://underarmour.com",
                  display_url: "underarmour.com",
                },
              ],
            },
            description: {
              hashtags: [
                {
                  start: 31,
                  end: 51,
                  tag: "TheOnlyWayIsThrough",
                },
              ],
            },
          },
          profile_image_url: "https://pbs.twimg.com/profile_images/1217113524532654081/kPJNPGc4_normal.jpg",
          location: "Baltimore, MD",
          verified: true,
          created_at: "2009-03-06T20:35:33.000Z",
        },
        {
          public_metrics: {
            followers_count: 16998046,
            following_count: 854,
            tweet_count: 7884,
            listed_count: 16197,
          },
          description:
            "Believer. Husband 2 @ayeshacurry, father to Riley, Ryan and Canon, son, brother. Warriors guard. Davidson Wildcat. BAYC. Philippians 4:13 #ChangeTheGameForGood",
          name: "Stephen Curry",
          protected: false,
          url: "https://t.co/GoKze52y5r",
          id: "42562446",
          username: "StephenCurry30",
          entities: {
            url: {
              urls: [
                {
                  start: 0,
                  end: 23,
                  url: "https://t.co/GoKze52y5r",
                  expanded_url: "http://CurryBrand.com",
                  display_url: "CurryBrand.com",
                },
              ],
            },
            description: {
              hashtags: [
                {
                  start: 138,
                  end: 159,
                  tag: "ChangeTheGameForGood",
                },
              ],
              mentions: [
                {
                  start: 20,
                  end: 32,
                  username: "ayeshacurry",
                },
              ],
            },
          },
          profile_image_url: "https://pbs.twimg.com/profile_images/1542667356949819392/IyRCxMgo_normal.jpg",
          location: "Worldwide",
          verified: true,
          created_at: "2009-05-26T04:15:37.000Z",
        },
      ],
    },
  },
  {
    data: {
      author_id: "20348431",
      context_annotations: [
        {
          domain: {
            id: "45",
            name: "Brand Vertical",
            description: "Top level entities that describe a Brands industry",
          },
          entity: {
            id: "1298254626077122560",
            name: "Sporting goods",
          },
        },
        {
          domain: {
            id: "65",
            name: "Interests and Hobbies Vertical",
            description: "Top level interests and hobbies groupings, like Food or Travel",
          },
          entity: {
            id: "1256236649253449729",
            name: "Fashion & beauty",
          },
        },
        {
          domain: {
            id: "66",
            name: "Interests and Hobbies Category",
            description: "A grouping of interests and hobbies entities, like Novelty Food or Destinations",
          },
          entity: {
            id: "1235998170233991168",
            name: "Sneakers",
          },
        },
        {
          domain: {
            id: "67",
            name: "Interests and Hobbies",
            description:
              "Interests, opinions, and behaviors of individuals, groups, or cultures; like Speciality Cooking or Theme Parks",
          },
          entity: {
            id: "839543390668673024",
            name: "Fashion",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "846810837301932032",
            name: "Shoes",
            description: "Fashion",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "1235998170233991168",
            name: "Sneakers",
          },
        },
        {
          domain: {
            id: "131",
            name: "Unified Twitter Taxonomy",
            description: "A taxonomy view into the Semantic Core knowledge graph",
          },
          entity: {
            id: "1298254626077122560",
            name: "Sporting goods",
          },
        },
        {
          domain: {
            id: "173",
            name: "Google Product Taxonomy",
            description: "A product taxonomy created by Google",
          },
          entity: {
            id: "1298254626077122560",
            name: "Sporting goods",
          },
        },
        {
          domain: {
            id: "45",
            name: "Brand Vertical",
            description: "Top level entities that describe a Brands industry",
          },
          entity: {
            id: "781974596706635776",
            name: "Retail",
          },
        },
        {
          domain: {
            id: "30",
            name: "Entities [Entity Service]",
            description:
              "Entity Service top level domain, every item that is in Entity Service should be in this domain",
          },
          entity: {
            id: "781974597428125696",
            name: "Sports/Outdoor - Retail",
          },
        },
        {
          domain: {
            id: "47",
            name: "Brand",
            description: "Brands and Companies",
          },
          entity: {
            id: "10026773952",
            name: "Adidas",
          },
        },
        {
          domain: {
            id: "48",
            name: "Product",
            description: "Products created by Brands.  Examples: Ford Explorer, Apple iPhone.",
          },
          entity: {
            id: "10040354442",
            name: "Adidas Originals",
          },
        },
      ],
      attachments: {
        media_keys: [
          "3_1560264265793425416",
          "3_1560264286081273856",
          "3_1560264310030729216",
          "3_1560264328930271232",
        ],
      },
      possibly_sensitive: false,
      text: "Hold the pace of the daily grind with the NMD_V3, inspired by archived designs and featuring BOOST cushioning. ​\n​\nExplore now at https://t.co/19s77dIcdK or through select retailers. ​\n​\n#NMD https://t.co/ePI095ed0M",
      entities: {
        annotations: [
          {
            start: 42,
            end: 47,
            probability: 0.78,
            type: "Product",
            normalized_text: "NMD_V3",
          },
        ],
        urls: [
          {
            start: 130,
            end: 153,
            url: "https://t.co/19s77dIcdK",
            expanded_url: "http://adidas.com/nmd",
            display_url: "adidas.com/nmd",
            status: 200,
            title: "adidas NMD",
            description:
              "Two street photographers. Two cities. Explore their stories, discover the new NMD shoes and see how past empowers future.",
            unwound_url: "https://www.adidas.com/us/nmd",
          },
          {
            start: 192,
            end: 215,
            url: "https://t.co/ePI095ed0M",
            expanded_url: "https://twitter.com/adidasoriginals/status/1560280353776226311/photo/1",
            display_url: "pic.twitter.com/ePI095ed0M",
            media_key: "3_1560264265793425416",
          },
          {
            start: 192,
            end: 215,
            url: "https://t.co/ePI095ed0M",
            expanded_url: "https://twitter.com/adidasoriginals/status/1560280353776226311/photo/1",
            display_url: "pic.twitter.com/ePI095ed0M",
            media_key: "3_1560264286081273856",
          },
          {
            start: 192,
            end: 215,
            url: "https://t.co/ePI095ed0M",
            expanded_url: "https://twitter.com/adidasoriginals/status/1560280353776226311/photo/1",
            display_url: "pic.twitter.com/ePI095ed0M",
            media_key: "3_1560264310030729216",
          },
          {
            start: 192,
            end: 215,
            url: "https://t.co/ePI095ed0M",
            expanded_url: "https://twitter.com/adidasoriginals/status/1560280353776226311/photo/1",
            display_url: "pic.twitter.com/ePI095ed0M",
            media_key: "3_1560264328930271232",
          },
        ],
        hashtags: [
          {
            start: 187,
            end: 191,
            tag: "NMD",
          },
        ],
      },
      public_metrics: {
        retweet_count: 36,
        reply_count: 16,
        like_count: 196,
        quote_count: 3,
      },
      lang: "en",
      source: "Twitter Web App",
      reply_settings: "everyone",
      id: "1560280353776226311",
      created_at: "2022-08-18T14:59:59.000Z",
      conversation_id: "1560280353776226311",
    },
    includes: {
      media: [
        {
          height: 600,
          type: "photo",
          url: "https://pbs.twimg.com/media/FacsFUZaUAgncSO.jpg",
          media_key: "3_1560264265793425416",
          width: 1200,
        },
        {
          height: 600,
          type: "photo",
          url: "https://pbs.twimg.com/media/FacsGf-aUAAoitf.jpg",
          media_key: "3_1560264286081273856",
          width: 1200,
        },
        {
          height: 600,
          type: "photo",
          url: "https://pbs.twimg.com/media/FacsH5MaAAAfShe.jpg",
          media_key: "3_1560264310030729216",
          width: 1200,
        },
        {
          height: 600,
          type: "photo",
          url: "https://pbs.twimg.com/media/FacsI_maIAAxHqz.jpg",
          media_key: "3_1560264328930271232",
          width: 1200,
        },
      ],
      users: [
        {
          id: "20348431",
          username: "adidasoriginals",
          public_metrics: {
            followers_count: 4251158,
            following_count: 348,
            tweet_count: 18241,
            listed_count: 6388,
          },
          entities: {
            url: {
              urls: [
                {
                  start: 0,
                  end: 23,
                  url: "https://t.co/umPF59lhNs",
                  expanded_url: "https://confirmed.onelink.me/mzYA/5b30338e",
                  display_url: "confirmed.onelink.me/mzYA/5b30338e",
                },
              ],
            },
          },
          protected: false,
          name: "adidas Originals",
          pinned_tweet_id: "1559925524071342086",
          profile_image_url: "https://pbs.twimg.com/profile_images/1547819269991256065/U4IbCGls_normal.jpg",
          description: "Celebrating originality since 1972. Remixing the past. Creating the future.",
          created_at: "2009-02-08T03:04:25.000Z",
          verified: true,
          url: "https://t.co/umPF59lhNs",
          location: "Global",
        },
      ],
    },
  },
];

export const campaigns = [
  {
    "NFT Type": "NFT Collections",
    Description:
      "Due to the popularity of NFT Collections which accounts for over 90% of the campaign choices for brands, NFT collection is recommend NFT Collection as your campaign type. NFT Collections is recommend Sneakers as your area of focus as it is the most likely to be successful according to our AI's social network and key wallets analysis. NFT Collections also recommend ETH as your blockchain as it is one of the best blockchain for NFTs and the launch price to be $750 based on similar NFTs in the market and from your competitors. The estimated success rate of this campaign is 76.3%",
    "Content Tag": "Sneakers/OpenSea",
    Blockchain: "ETH",
    Amount: 500,
    Price: 750,
    Image:
      "https://i.seadn.io/gae/-j4h0B8cXDX2PqNlKf5DQQU9BJpJKv-FuW2q9zx2RNlG1cbgpBSsGck1UlZ2Z86Cm-ciw69OwstV_dH79HZ-idPunP9mkoSdxvABHU4?w=500&auto=format",
    id: "ad57ed59f00e68b94840b09b7d3b33f1160c84f80e0e4ce0e1b9500ea49c5780",
    "Success Rate": 0.7632505421849596,
    Insights: [
      "NFT Collections represent 92% of Web 3 activities among the top 100 Global Brands",
      "Many brands have started to venture into the metaverse and join the Sandbox, including Adidas, and Gucci",
      "More than 67% of the public opinions analyzed by our AI are positive towards NFT Collections",
      "ETH is one the most popular blockchains for NFTs and Web 3 campaigns",
    ],
    "Relevant Wallets": [
      {
        Address: "0x468436bc55a88d1721617cac6448b56dae1ddb92",
        Insights: [
          "This wallet has a total value of $284013",
          "This wallet has been active in the following brands: adidas, MeeBits, Time Magazine, Robotos, RTFKT, Nike",
          "This wallet has been active in the following industries: Fashion, Artworks, Entertainment, Art, Tech",
          "40% of the NFTs in this wallet was created by adidas",
          "42% of the NFTs in this wallet belongs to the Fashion category",
        ],
      },
      {
        Address: "0xa022f02d2f76159331bd5ddee7c30c256188096d",
        Insights: [
          "This wallet has a total value of $12251",
          "This wallet has been active in the following brands: adidas, Nike, RTFKT",
          "This wallet has been active in the following industries: Fashion, Tech",
          "57% of the NFTs in this wallet was created by adidas",
          "68% of the NFTs in this wallet belongs to the Fashion category",
        ],
      },
      {
        Address: "0xb36c7e6e58e38ad608748e6a00bda29dbeaec96a",
        Insights: [
          "This wallet has a total value of $63656",
          "This wallet has been active in the following brands: RTFKT, adidas, Nike",
          "This wallet has been active in the following industries: Tech, Fashion",
          "28% of the NFTs in this wallet was created by adidas",
          "43% of the NFTs in this wallet belongs to the Fashion category",
        ],
      },
    ],
    "Relevant NFTs": [
      {
        "Contract Address": "0x28472a58a490c5e09a238847f66a68a47cc76f0f",
        "Token ID": "1",
        Insights: [
          {
            content: "For the moment we advise you only buy unequipped vials from",
            link: "https://twitter.com/rtfkt/status/1517701627628724224",
          },
          {
            content: "Until we resolve an issue with equipped sneakers with opensea",
            link: "https://twitter.com/rtfkt/status/1529201508012765184",
          },
          {
            content: "For the best of culture, we chose the best of places.",
            link: "https://twitter.com/DapperDanHarlem/status/1586368103868665856",
          },
        ],
      },
      {
        "Contract Address": "0x28472a58a490c5e09a238847f66a68a47cc76f0f",
        "Token ID": "0",
        Insights: [
          {
            content: "For the moment we advise you only buy unequipped vials from",
            link: "https://twitter.com/rtfkt/status/1517701627628724224",
          },
          {
            content: "Until we resolve an issue with equipped sneakers with opensea",
            link: "https://twitter.com/rtfkt/status/1529201508012765184",
          },
          {
            content: "For the best of culture, we chose the best of places.",
            link: "https://twitter.com/DapperDanHarlem/status/1586368103868665856",
          },
        ],
      },
      {
        "Contract Address": "0x455c732fee7b5c3b09531439b598ead4817d5274",
        "Token ID": "23101",
        Insights: [
          {
            content: "For the moment we advise you only buy unequipped vials from",
            link: "https://twitter.com/rtfkt/status/1517701627628724224",
          },
          {
            content: "Until we resolve an issue with equipped sneakers with opensea",
            link: "https://twitter.com/rtfkt/status/1529201508012765184",
          },
          {
            content: "For the best of culture, we chose the best of places.",
            link: "https://twitter.com/DapperDanHarlem/status/1586368103868665856",
          },
        ],
      },
    ],
  },
  {
    "NFT Type": "Virtual Land",
    Description:
      "With the recent rise of interest in the Metaverse, Virtual Land is recommend Virtual Land as your campaign type. Virtual Land is one of the hottest asset in popular metaverse worlds such as The Sandbox with over 68% positivity score according to our AI's social network and key wallets analysis. Virtual Land is also recommend SOL as your blockchain as it is one of the best blockchain for Metaverse and the launch price to be $1500 based on similar campaigns in the market and from your competitors. The estimated success rate of this campaign is 74.4%",
    "Content Tag": "Metaverse/The Sandbox",
    Blockchain: "SOL",
    Amount: 10,
    Price: 1000,
    Image: "https://img.seadn.io/files/f6b0da27b743556f5807e5ae26512e29.jpg",
    id: "bb96e0a45810de04f771343b7cb1d5cd6243be2ae504973447e9aec4901b9214",
    "Success Rate": 0.7443731689453125,
    Insights: [
      "NFT Collections represent 92% of Web 3 activities among the top 100 Global Brands",
      "More than 74% of the public opinions analyzed by our AI are positive towards Partnership",
      "More than 67% of the public opinions analyzed by our AI are positive towards NFT Collections",
    ],
    "Relevant Wallets": [
      {
        Address: "0x468436bc55a88d1721617cac6448b56dae1ddb92",
        Insights: [
          "This wallet has a total value of $284013",
          "This wallet has been active in the following brands: adidas, MeeBits, Time Magazine, Robotos, RTFKT, Nike",
          "This wallet has been active in the following industries: Fashion, Artworks, Entertainment, Art, Tech",
          "40% of the NFTs in this wallet was created by adidas",
          "42% of the NFTs in this wallet belongs to the Fashion category",
        ],
      },
      {
        Address: "0x3aa485a8e745fc2bd68abbdb3cf05b58e338d7fe",
        Insights: [
          "This wallet has a total value of $581604",
          "This wallet has been active in the following brands: RTFKT, Nike, Yuga Labs, adidas, CryptoPunks",
          "This wallet has been active in the following industries: Tech, Fashion, Artworks",
          "25% of the NFTs in this wallet belongs to the Tech category",
        ],
      },
      {
        Address: "0xa022f02d2f76159331bd5ddee7c30c256188096d",
        Insights: [
          "This wallet has a total value of $12251",
          "This wallet has been active in the following brands: adidas, Nike, RTFKT",
          "This wallet has been active in the following industries: Fashion, Tech",
          "57% of the NFTs in this wallet was created by adidas",
          "68% of the NFTs in this wallet belongs to the Fashion category",
        ],
      },
    ],
    "Relevant NFTs": [
      {
        "Contract Address": "0x28472a58a490c5e09a238847f66a68a47cc76f0f",
        "Token ID": "1",
        Insights: [
          {
            content: "Navigate through a magical space where past, present and future co-exist!",
            link: "https://twitter.com/TheSandboxGame/status/1585621447539625984",
          },
          {
            content: "Are you a Virtual LAND owner yet?",
            link: "https://twitter.com/thesandboxgame/status/1555572130141638657",
          },
          {
            content: "What should we build, together in @TheSandboxGame?",
            link: "https://twitter.com/adidasoriginals/status/1462873002350202882",
          },
        ],
      },
      {
        "Contract Address": "0x28472a58a490c5e09a238847f66a68a47cc76f0f",
        "Token ID": "0",
        Insights: [
          {
            content: "Navigate through a magical space where past, present and future co-exist!",
            link: "https://twitter.com/TheSandboxGame/status/1585621447539625984",
          },
          {
            content: "Are you a Virtual LAND owner yet?",
            link: "https://twitter.com/thesandboxgame/status/1555572130141638657",
          },
          {
            content: "What should we build, together in @TheSandboxGame?",
            link: "https://twitter.com/adidasoriginals/status/1462873002350202882",
          },
        ],
      },
      {
        "Contract Address": "0x6d4bbc0387dd4759eee30f6a482ac6dc2df3facf",
        "Token ID": "1",
        Insights: [
          {
            content: "Navigate through a magical space where past, present and future co-exist!",
            link: "https://twitter.com/TheSandboxGame/status/1585621447539625984",
          },
          {
            content: "Are you a Virtual LAND owner yet?",
            link: "https://twitter.com/thesandboxgame/status/1555572130141638657",
          },
          {
            content: "What should we build, together in @TheSandboxGame?",
            link: "https://twitter.com/adidasoriginals/status/1462873002350202882",
          },
        ],
      },
    ],
  },
  {
    "NFT Type": "Collaboration",
    Description:
      "Due to the growing popularity of Decentraland, Collaboration is recommend Collaboration as your campaign type. Collaboration is recommend placing your products on influencers' avatars and streams to increase the reach and people's awareness. Collaboration is also recommend MATIC as your blockchain as it is one of the best blockchain for Metaverse and the launch price to be $1500 based on similar campaigns in the market and from your competitors. The estimated success rate of this campaign is 73.9%",
    "Content Tag": "Influencer/Decentraland",
    Blockchain: "MATIC",
    Amount: 20,
    Price: 500,
    Image: "https://img.freepik.com/premium-vector/illustration-two-hands-handshake-form-starry-sky-space_87494-75.jpg",
    id: "68ccda5407f5f140938cbb835ee73e79c24f4a5bc825a02a040e21b7c5ae5048",
    "Success Rate": 0.7386860847473145,
    Insights: [
      "NFT Collections represent 92% of Web 3 activities among the top 100 Global Brands",
      "More than 74% of the public opinions analyzed by our AI are positive towards Blockchain Gaming",
      "More than 67% of the public opinions analyzed by our AI are positive towards NFT Collections",
    ],
    "Relevant Wallets": [
      {
        Address: "0x468436bc55a88d1721617cac6448b56dae1ddb92",
        Insights: [
          "This wallet has a total value of $284013",
          "This wallet has been active in the following brands: adidas, MeeBits, Time Magazine, Robotos, RTFKT, Nike",
          "This wallet has been active in the following industries: Fashion, Artworks, Entertainment, Art, Tech",
          "40% of the NFTs in this wallet was created by adidas",
          "42% of the NFTs in this wallet belongs to the Fashion category",
        ],
      },
      {
        Address: "0x3aa485a8e745fc2bd68abbdb3cf05b58e338d7fe",
        Insights: [
          "This wallet has a total value of $581604",
          "This wallet has been active in the following brands: RTFKT, Nike, Yuga Labs, adidas, CryptoPunks",
          "This wallet has been active in the following industries: Tech, Fashion, Artworks",
          "25% of the NFTs in this wallet belongs to the Tech category",
        ],
      },
      {
        Address: "0xa022f02d2f76159331bd5ddee7c30c256188096d",
        Insights: [
          "This wallet has a total value of $12251",
          "This wallet has been active in the following brands: adidas, Nike, RTFKT",
          "This wallet has been active in the following industries: Fashion, Tech",
          "57% of the NFTs in this wallet was created by adidas",
          "68% of the NFTs in this wallet belongs to the Fashion category",
        ],
      },
    ],
    "Relevant NFTs": [
      {
        "Contract Address": "0x28472a58a490c5e09a238847f66a68a47cc76f0f",
        "Token ID": "1",
        Insights: [
          {
            content: "Big congratulations to @RTFKTstudios for joining the @Nike team",
            link: "https://twitter.com/decentraland/status/1470561429543698432",
          },
          {
            content:
              "Varvarian Sneakers lands in Decentraland. You can browse the collection. There is also a huge sneaker set up",
            link: "https://twitter.com/metawear_nft/status/1481445468777611264",
          },
          {
            content:
              "The @decentraland desktop beta client is now out @BossBeautiesNFT announces collab with @neimanmarcus",
            link: "https://twitter.com/CryptoGucci/status/1500580923892387847",
          },
        ],
      },
      {
        "Contract Address": "0x28472a58a490c5e09a238847f66a68a47cc76f0f",
        "Token ID": "0",
        Insights: [
          {
            content: "Big congratulations to @RTFKTstudios for joining the @Nike team",
            link: "https://twitter.com/decentraland/status/1470561429543698432",
          },
          {
            content:
              "Varvarian Sneakers lands in Decentraland. You can browse the collection. There is also a huge sneaker set up",
            link: "https://twitter.com/metawear_nft/status/1481445468777611264",
          },
          {
            content:
              "The @decentraland desktop beta client is now out @BossBeautiesNFT announces collab with @neimanmarcus",
            link: "https://twitter.com/CryptoGucci/status/1500580923892387847",
          },
        ],
      },
      {
        "Contract Address": "0x6d4bbc0387dd4759eee30f6a482ac6dc2df3facf",
        "Token ID": "1",
        Insights: [
          {
            content: "Big congratulations to @RTFKTstudios for joining the @Nike team",
            link: "https://twitter.com/decentraland/status/1470561429543698432",
          },
          {
            content:
              "Varvarian Sneakers lands in Decentraland. You can browse the collection. There is also a huge sneaker set up",
            link: "https://twitter.com/metawear_nft/status/1481445468777611264",
          },
          {
            content:
              "The @decentraland desktop beta client is now out @BossBeautiesNFT announces collab with @neimanmarcus",
            link: "https://twitter.com/CryptoGucci/status/1500580923892387847",
          },
        ],
      },
    ],
  },
];

export const walletsCampaign = [
  {
    contract: "0xf59742b240426fdc41d61954a49f55f2a377313d",
    assetsCount: 20,
    value: 28825.03,
    topAssets: [
      {
        name: "RTFKT SKINVIAL EVO X",
        value: 2.68,
      },
      {
        name: "RTFKT MNLTH 2",
        value: 3.4,
      },
      {
        name: "adidas Originals: Into the Metaverse",
        value: 1.37,
      },
    ],
  },
  {
    contract: "0x62d5e272a28f1d697bba571c12d0009a61eeb768",
    assetsCount: 32,
    value: 13573.35,
    topAssets: [
      {
        name: "CHEERS, by JASON NAYLOR",
        value: 0.60025,
      },
      {
        name: "RTFKT x Nike Dunk Genesis",
        value: 1.4,
      },
      {
        name: "adidas Originals: Into the Metaverse",
        value: 1.37,
      },
    ],
  },
];

export const walletAddress = [
  {
    class: "image1",
    address: "0xf59742b240426fdc41d61954a49f55f2a377313d",
  },
  {
    class: "image2",
    address: "0x62d5e272a28f1d697bba571c12d0009a61eeb768",
  },
];

export const topNFT = [
  {
    id: 1,
    contractAddress: "0x6d4bbc0387dd4759eee30f6a482ac6dc2df3facf",
    tokenId: "1",
    tokenStandard: "ERC1155",
    blockchain: "Ethereum",
    creatorFees: "10%",
    description: "MNLTH Quest will continue... 🗿⚔️ an experience powered by DART X.",
    digitalCollectible: "Miscellaneous",
    brandCategory: "Sportswear",
    category: ["Fashion", "Tech"],
    contentType: {
      video: "https://openseauserdata.com/files/8c462879c41eb8214c9fc6f6851bb66f.mp4",
      _2DImage:
        "https://lh3.googleusercontent.com/X5MygRptX2sjWTxBbyVeNtyZ6muq39aFFN1dRI8iSa4mnmt521ttZTyQfeIdDE8VGJETFDiGWPeyJhmjrHKBk6lRhJX0LLuHhGAk5A",
    },
    primaryUtility: "Collectible only",
    max_price: 5.012,
    AvgPrice: 1.5595,
    AvgVolume: 743.8704,
    AvgHold: [],
    AvgHold2: 3,
    transactionCount: {
      getTransactionCount: [
        {
          numberSales: 22,
          volume: 30.763,
          avgPrice: 1.3983181818181818,
          dateTime: "2022-10-04T00:00:00",
        },
        {
          numberSales: 28,
          volume: 39.7747,
          avgPrice: 1.420525,
          dateTime: "2022-10-05T00:00:00",
        },
        {
          numberSales: 12,
          volume: 18.066979999999997,
          avgPrice: 1.5055816666666664,
          dateTime: "2022-10-06T00:00:00",
        },
        {
          numberSales: 19,
          volume: 30.588995000000004,
          avgPrice: 1.6099471052631582,
          dateTime: "2022-10-07T00:00:00",
        },
        {
          numberSales: 10,
          volume: 17.747999999999998,
          avgPrice: 1.7747999999999997,
          dateTime: "2022-10-08T00:00:00",
        },
        {
          numberSales: 9,
          volume: 15.969999999999997,
          avgPrice: 1.774444444444444,
          dateTime: "2022-10-09T00:00:00",
        },
        {
          numberSales: 8,
          volume: 14.259,
          avgPrice: 1.782375,
          dateTime: "2022-10-10T00:00:00",
        },
        {
          numberSales: 5,
          volume: 8.575,
          avgPrice: 1.7149999999999999,
          dateTime: "2022-10-11T00:00:00",
        },
        {
          numberSales: 7,
          volume: 11.950000000000003,
          avgPrice: 1.7071428571428575,
          dateTime: "2022-10-12T00:00:00",
        },
        {
          numberSales: 6,
          volume: 7.9238,
          avgPrice: 1.3206333333333333,
          dateTime: "2022-10-13T00:00:00",
        },
        {
          numberSales: 4,
          volume: 6.348,
          avgPrice: 1.587,
          dateTime: "2022-10-14T00:00:00",
        },
        {
          numberSales: 4,
          volume: 6.530000000000001,
          avgPrice: 1.6325000000000003,
          dateTime: "2022-10-15T00:00:00",
        },
        {
          numberSales: 5,
          volume: 7.916,
          avgPrice: 1.5832000000000002,
          dateTime: "2022-10-16T00:00:00",
        },
        {
          numberSales: 10,
          volume: 15.78,
          avgPrice: 1.5779999999999998,
          dateTime: "2022-10-17T00:00:00",
        },
        {
          numberSales: 29,
          volume: 42.90999999999999,
          avgPrice: 1.4796551724137927,
          dateTime: "2022-10-18T00:00:00",
        },
        {
          numberSales: 5,
          volume: 7.3721,
          avgPrice: 1.4744199999999998,
          dateTime: "2022-10-19T00:00:00",
        },
        {
          numberSales: 6,
          volume: 9.065999999999999,
          avgPrice: 1.511,
          dateTime: "2022-10-20T00:00:00",
        },
        {
          numberSales: 12,
          volume: 18.402009999999997,
          avgPrice: 1.533500833333333,
          dateTime: "2022-10-21T00:00:00",
        },
        {
          numberSales: 8,
          volume: 11.74,
          avgPrice: 1.4675,
          dateTime: "2022-10-22T00:00:00",
        },
        {
          numberSales: 5,
          volume: 7.343,
          avgPrice: 1.4686,
          dateTime: "2022-10-23T00:00:00",
        },
        {
          numberSales: 5,
          volume: 7.34,
          avgPrice: 1.468,
          dateTime: "2022-10-24T00:00:00",
        },
        {
          numberSales: 4,
          volume: 5.766999999999999,
          avgPrice: 1.4417499999999996,
          dateTime: "2022-10-25T00:00:00",
        },
        {
          numberSales: 8,
          volume: 11.306,
          avgPrice: 1.41325,
          dateTime: "2022-10-26T00:00:00",
        },
        {
          numberSales: 20,
          volume: 25.051000000000005,
          avgPrice: 1.2525500000000003,
          dateTime: "2022-10-27T00:00:00",
        },
        {
          numberSales: 11,
          volume: 14.343799999999998,
          avgPrice: 1.303981818181818,
          dateTime: "2022-10-28T00:00:00",
        },
        {
          numberSales: 8,
          volume: 10.636,
          avgPrice: 1.3295,
          dateTime: "2022-10-29T00:00:00",
        },
        {
          numberSales: 4,
          volume: 5.266,
          avgPrice: 1.3165,
          dateTime: "2022-10-30T00:00:00",
        },
        {
          numberSales: 9,
          volume: 11.447299999999998,
          avgPrice: 1.271922222222222,
          dateTime: "2022-10-31T00:00:00",
        },
        {
          numberSales: 8,
          volume: 10.56,
          avgPrice: 1.32,
          dateTime: "2022-11-01T00:00:00",
        },
        {
          numberSales: 5,
          volume: 6.270999999999999,
          avgPrice: 1.2541999999999998,
          dateTime: "2022-11-02T00:00:00",
        },
        {
          numberSales: 1,
          volume: 1.21,
          avgPrice: 1.21,
          dateTime: "2022-11-03T00:00:00",
        },
      ],
      volumesCount: 438.22468499999997,
      avgPriceCount: 1.4808321817683805,
      holders: 10477,
    },
  },
  {
    id: 2,
    contractAddress: "0xf661d58cfe893993b11d53d11148c4650590c692",
    tokenId: "1",
    tokenStandard: "ERC721",
    blockchain: "Ethereum",
    creatorFees: "10%",
    description:
      "Introducing the first RTFKT x Nike Sneaker NFT, the RTFKT X Nike Dunk Genesis CRYPTOKICKS Sneaker 🧪.When equipped with a RTFKT Skin Vial NFT, the look of the RTFKT x NIKE DUNK GENESIS CRYPTOKICKS changes according to the traits of the vial",
    digitalCollectible: "Wearable",
    primaryUtility: "Linked to a physical twin",
    brandCategory: "Sportswear",
    max_price: 2.1,
    AvgPrice: 0.5445,
    AvgVolume: 234.1538,
    category: ["Fashion", "Tech"],
    contentType: {
      video: "https://openseauserdata.com/files/8c462879c41eb8214c9fc6f6851bb66f.mp4",
      _2DImage:
        "https://lh3.googleusercontent.com/X5MygRptX2sjWTxBbyVeNtyZ6muq39aFFN1dRI8iSa4mnmt521ttZTyQfeIdDE8VGJETFDiGWPeyJhmjrHKBk6lRhJX0LLuHhGAk5A",
    },
    AvgHold: [],
    AvgHold2: 103,
    transactionCount: {
      avgPriceCount: 0.932554575454,
      getTransactionCount: [
        { numberSales: 5, volume: 7.532, avgPrice: 1.5064, dateTime: "2022-10-04T00:00:00" },
        { numberSales: 10, volume: 14.6277, avgPrice: 1.4627700000000001, dateTime: "2022-10-05T00:00:00" },
        { numberSales: 11, volume: 15.8955, avgPrice: 1.4450454545454545, dateTime: "2022-10-06T00:00:00" },
        { numberSales: 11, volume: 16.46, avgPrice: 1.4963636363636363, dateTime: "2022-10-07T00:00:00" },
        { numberSales: 4, volume: 5.510000000000001, avgPrice: 1.3775000000000002, dateTime: "2022-10-08T00:00:00" },
        { numberSales: 5, volume: 7.285, avgPrice: 1.457, dateTime: "2022-10-09T00:00:00" },
        { numberSales: 9, volume: 12.588, avgPrice: 1.3986666666666665, dateTime: "2022-10-10T00:00:00" },
        { numberSales: 22, volume: 30.763, avgPrice: 1.3983181818181818, dateTime: "2022-10-11T00:00:00" },
        { numberSales: 28, volume: 39.7747, avgPrice: 1.420525, dateTime: "2022-10-12T00:00:00" },
        { numberSales: 12, volume: 18.066979999999997, avgPrice: 1.5055816666666664, dateTime: "2022-10-13T00:00:00" },
        { numberSales: 19, volume: 30.588995000000004, avgPrice: 1.6099471052631582, dateTime: "2022-10-14T00:00:00" },
        { numberSales: 10, volume: 17.747999999999998, avgPrice: 1.7747999999999997, dateTime: "2022-10-15T00:00:00" },
        { numberSales: 9, volume: 15.969999999999997, avgPrice: 1.774444444444444, dateTime: "2022-10-16T00:00:00" },
        { numberSales: 8, volume: 14.259, avgPrice: 1.782375, dateTime: "2022-10-17T00:00:00" },
        { numberSales: 5, volume: 8.575, avgPrice: 1.7149999999999999, dateTime: "2022-10-18T00:00:00" },
        { numberSales: 7, volume: 11.950000000000003, avgPrice: 1.7071428571428575, dateTime: "2022-10-19T00:00:00" },
        { numberSales: 6, volume: 7.9238, avgPrice: 1.3206333333333333, dateTime: "2022-10-20T00:00:00" },
        { numberSales: 4, volume: 6.348, avgPrice: 1.587, dateTime: "2022-10-21T00:00:00" },
        { numberSales: 4, volume: 6.530000000000001, avgPrice: 1.6325000000000003, dateTime: "2022-10-22T00:00:00" },
        { numberSales: 5, volume: 7.916, avgPrice: 1.5832000000000002, dateTime: "2022-10-23T00:00:00" },
        { numberSales: 10, volume: 15.78, avgPrice: 1.5779999999999998, dateTime: "2022-10-24T00:00:00" },
        { numberSales: 29, volume: 42.90999999999999, avgPrice: 1.4796551724137927, dateTime: "2022-10-25T00:00:00" },
        { numberSales: 5, volume: 7.3721, avgPrice: 1.4744199999999998, dateTime: "2022-10-26T00:00:00" },
        { numberSales: 6, volume: 9.065999999999999, avgPrice: 1.511, dateTime: "2022-10-27T00:00:00" },
        { numberSales: 12, volume: 18.402009999999997, avgPrice: 1.533500833333333, dateTime: "2022-10-28T00:00:00" },
        { numberSales: 8, volume: 11.74, avgPrice: 1.4675, dateTime: "2022-10-29T00:00:00" },
        { numberSales: 5, volume: 7.343, avgPrice: 1.4686, dateTime: "2022-10-30T00:00:00" },
        { numberSales: 5, volume: 7.34, avgPrice: 1.468, dateTime: "2022-10-31T00:00:00" },
        { numberSales: 4, volume: 5.766999999999999, avgPrice: 1.4417499999999996, dateTime: "2022-11-01T00:00:00" },
        { numberSales: 8, volume: 11.306, avgPrice: 1.41325, dateTime: "2022-11-02T00:00:00" },
        { numberSales: 8, volume: 10.991000000000001, avgPrice: 1.3738750000000002, dateTime: "2022-11-03T00:00:00" },
      ],
      holders: 12235,
      volumesCount: 0,
    },
  },
  {
    id: 3,
    contractAddress: "0x28472a58a490c5e09a238847f66a68a47cc76f0f",
    tokenId: "0",
    tokenStandard: "ERC1155",
    blockchain: "Ethereum",
    creatorFees: "10%",
    description:
      "Into the Metaverse is a collaborative, co-created NFT project between adidas Originals and NFT pioneers gmoney, Bored Ape Yacht Club and PUNKS Comic. Evolved to Phase 2.",
    digitalCollectible: "Card",
    primaryUtility: "Collectible only",
    brandCategory: "Sportswear",
    category: ["Fashion"],
    contentType: {
      video: "https://openseauserdata.com/files/3565db33a856b19f48396062e59e6d62.mp4",
      _2DImage:
        "https://lh3.googleusercontent.com/JnuVC76owh2sD__99-CRj9yNNJyVVNWgNkob5mra5yCGTx9pnL3RXA4jdYIJX2XuDsMqF5KET0kziwOs3oigBuUEKDnlPqHU9P3Edw",
    },
    max_price: 1.6,
    AvgPrice: 0,
    AvgVolume: 0,
    AvgHold: [],
    AvgHold2: 6,
    transactionCount: {
      getTransactionCount: [
        {
          numberSales: 3,
          volume: 1.7229999999999996,
          avgPrice: 0.5743333333333333,
          dateTime: "2022-10-03T00:00:00",
        },
        {
          numberSales: 3,
          volume: 1.529049,
          avgPrice: 0.509683,
          dateTime: "2022-10-04T00:00:00",
        },
        {
          numberSales: 2,
          volume: 1.02,
          avgPrice: 0.51,
          dateTime: "2022-10-05T00:00:00",
        },
        {
          numberSales: 3,
          volume: 1.609951,
          avgPrice: 0.5366503333333333,
          dateTime: "2022-10-06T00:00:00",
        },
        {
          numberSales: 4,
          volume: 1.971,
          avgPrice: 0.49275,
          dateTime: "2022-10-07T00:00:00",
        },
        {
          numberSales: 5,
          volume: 2.2320100000000003,
          avgPrice: 0.4464020000000001,
          dateTime: "2022-10-08T00:00:00",
        },
        {
          numberSales: 3,
          volume: 1.6449,
          avgPrice: 0.5483,
          dateTime: "2022-10-09T00:00:00",
        },
        {
          numberSales: 2,
          volume: 1.089,
          avgPrice: 0.5445,
          dateTime: "2022-10-10T00:00:00",
        },
        {
          numberSales: 4,
          volume: 1.99,
          avgPrice: 0.4975,
          dateTime: "2022-10-11T00:00:00",
        },
        {
          numberSales: 3,
          volume: 1.414,
          avgPrice: 0.4713333333333333,
          dateTime: "2022-10-12T00:00:00",
        },
        {
          numberSales: 5,
          volume: 2.221,
          avgPrice: 0.44420000000000004,
          dateTime: "2022-10-13T00:00:00",
        },
        {
          numberSales: 5,
          volume: 2.102,
          avgPrice: 0.4204,
          dateTime: "2022-10-14T00:00:00",
        },
        {
          numberSales: 5,
          volume: 2.2319999,
          avgPrice: 0.44639998,
          dateTime: "2022-10-15T00:00:00",
        },
        {
          numberSales: 2,
          volume: 0.913,
          avgPrice: 0.4565,
          dateTime: "2022-10-16T00:00:00",
        },
        {
          numberSales: 5,
          volume: 2.346,
          avgPrice: 0.4692,
          dateTime: "2022-10-17T00:00:00",
        },
        {
          numberSales: 5,
          volume: 2.453999999,
          avgPrice: 0.4907999998,
          dateTime: "2022-10-18T00:00:00",
        },
        {
          numberSales: 2,
          volume: 1.0188000000000001,
          avgPrice: 0.5094000000000001,
          dateTime: "2022-10-19T00:00:00",
        },
        {
          numberSales: 1,
          volume: 0.44,
          avgPrice: 0.44,
          dateTime: "2022-10-20T00:00:00",
        },
        {
          numberSales: 2,
          volume: 0.9101,
          avgPrice: 0.45505,
          dateTime: "2022-10-21T00:00:00",
        },
        {
          numberSales: 2,
          volume: 1.026,
          avgPrice: 0.513,
          dateTime: "2022-10-22T00:00:00",
        },
        {
          numberSales: 5,
          volume: 2.38,
          avgPrice: 0.476,
          dateTime: "2022-10-23T00:00:00",
        },
        {
          numberSales: 5,
          volume: 2.132049,
          avgPrice: 0.42640979999999995,
          dateTime: "2022-10-24T00:00:00",
        },
        {
          numberSales: 4,
          volume: 1.613,
          avgPrice: 0.40325,
          dateTime: "2022-10-25T00:00:00",
        },
        {
          numberSales: 3,
          volume: 1.205,
          avgPrice: 0.40166666666666667,
          dateTime: "2022-10-26T00:00:00",
        },
        {
          numberSales: 3,
          volume: 1.335951,
          avgPrice: 0.445317,
          dateTime: "2022-10-27T00:00:00",
        },
        {
          numberSales: 4,
          volume: 1.661064,
          avgPrice: 0.415266,
          dateTime: "2022-10-28T00:00:00",
        },
        {
          numberSales: 3,
          volume: 1.153,
          avgPrice: 0.38433333333333336,
          dateTime: "2022-10-29T00:00:00",
        },
        {
          numberSales: 4,
          volume: 1.496951,
          avgPrice: 0.37423775,
          dateTime: "2022-10-30T00:00:00",
        },
        {
          numberSales: 6,
          volume: 1.82282,
          avgPrice: 0.30380333333333337,
          dateTime: "2022-10-31T00:00:00",
        },
        {
          numberSales: 4,
          volume: 1.6899,
          avgPrice: 0.422475,
          dateTime: "2022-11-01T00:00:00",
        },
        {
          numberSales: 3,
          volume: 1.33,
          avgPrice: 0.44333333333333336,
          dateTime: "2022-11-02T00:00:00",
        },
      ],
      volumesCount: 49.705544899,
      avgPriceCount: 0.4604030385956989,
      holders: 48595,
    },
  },
  {
    id: 4,
    contractAddress: "0x3ca69d4062e59576e2704e8e260f359643351042",
    tokenId: "0",
    tokenStandard: "ERC721",
    blockchain: "Ethereum",
    creatorFees: "0%",
    description:
      "Reebok and NST2 have created 300 NFT collectibles of Reebok's and A$AP NAST's collaboration on the Zig Kinetica II Edge. The NFTs are available in 3 variants, representing the different elements in which the Zig Kinetica II Edge can be worn.",
    digitalCollectible: "Collectible",
    primaryUtility: "Collectible only",
    brandCategory: "Sportswear",
    category: ["Fashion", "Music"],
    contentType: {
      video: "https://openseauserdata.com/files/3565db33a856b19f48396062e59e6d62.mp4",
      _2DImage:
        "https://lh3.googleusercontent.com/JnuVC76owh2sD__99-CRj9yNNJyVVNWgNkob5mra5yCGTx9pnL3RXA4jdYIJX2XuDsMqF5KET0kziwOs3oigBuUEKDnlPqHU9P3Edw",
    },
    max_price: 0.5,
    AvgPrice: 0.2745,
    AvgVolume: 0.5,
    AvgHold: [],
    AvgHold2: 111,
    transactionCount: {
      getTransactionCount: [
        { numberSales: 2, volume: 1.06, avgPrice: 0.53, dateTime: "2022-10-05T00:00:00" },
        { numberSales: 1, volume: 0.568, avgPrice: 0.568, dateTime: "2022-10-06T00:00:00" },
        { numberSales: 6, volume: 3.19901, avgPrice: 0.5331683333333334, dateTime: "2022-10-07T00:00:00" },
        { numberSales: 1, volume: 0.55, avgPrice: 0.55, dateTime: "2022-10-08T00:00:00" },
        { numberSales: 1, volume: 0.545, avgPrice: 0.545, dateTime: "2022-10-09T00:00:00" },
        { numberSales: 4, volume: 2.203, avgPrice: 0.55075, dateTime: "2022-10-10T00:00:00" },
        { numberSales: 3, volume: 1.529049, avgPrice: 0.509683, dateTime: "2022-10-11T00:00:00" },
        { numberSales: 2, volume: 1.02, avgPrice: 0.51, dateTime: "2022-10-12T00:00:00" },
        { numberSales: 3, volume: 1.609951, avgPrice: 0.5366503333333333, dateTime: "2022-10-13T00:00:00" },
        { numberSales: 4, volume: 1.971, avgPrice: 0.49275, dateTime: "2022-10-14T00:00:00" },
        { numberSales: 5, volume: 2.2320100000000003, avgPrice: 0.4464020000000001, dateTime: "2022-10-15T00:00:00" },
        { numberSales: 3, volume: 1.6449, avgPrice: 0.5483, dateTime: "2022-10-16T00:00:00" },
        { numberSales: 2, volume: 1.089, avgPrice: 0.5445, dateTime: "2022-10-17T00:00:00" },
        { numberSales: 4, volume: 1.99, avgPrice: 0.4975, dateTime: "2022-10-18T00:00:00" },
        { numberSales: 3, volume: 1.414, avgPrice: 0.4713333333333333, dateTime: "2022-10-19T00:00:00" },
        { numberSales: 5, volume: 2.221, avgPrice: 0.44420000000000004, dateTime: "2022-10-20T00:00:00" },
        { numberSales: 5, volume: 2.102, avgPrice: 0.4204, dateTime: "2022-10-21T00:00:00" },
        { numberSales: 5, volume: 2.2319999, avgPrice: 0.44639998, dateTime: "2022-10-22T00:00:00" },
        { numberSales: 2, volume: 0.913, avgPrice: 0.4565, dateTime: "2022-10-23T00:00:00" },
        { numberSales: 5, volume: 2.346, avgPrice: 0.4692, dateTime: "2022-10-24T00:00:00" },
        { numberSales: 5, volume: 2.453999999, avgPrice: 0.4907999998, dateTime: "2022-10-25T00:00:00" },
        { numberSales: 2, volume: 1.0188000000000001, avgPrice: 0.5094000000000001, dateTime: "2022-10-26T00:00:00" },
        { numberSales: 1, volume: 0.44, avgPrice: 0.44, dateTime: "2022-10-27T00:00:00" },
        { numberSales: 2, volume: 0.9101, avgPrice: 0.45505, dateTime: "2022-10-28T00:00:00" },
        { numberSales: 2, volume: 1.026, avgPrice: 0.513, dateTime: "2022-10-29T00:00:00" },
        { numberSales: 5, volume: 2.38, avgPrice: 0.476, dateTime: "2022-10-30T00:00:00" },
        { numberSales: 5, volume: 2.132049, avgPrice: 0.42640979999999995, dateTime: "2022-10-31T00:00:00" },
        { numberSales: 4, volume: 1.613, avgPrice: 0.40325, dateTime: "2022-11-01T00:00:00" },
        { numberSales: 3, volume: 1.205, avgPrice: 0.40166666666666667, dateTime: "2022-11-02T00:00:00" },
        { numberSales: 2, volume: 0.933951, avgPrice: 0.4669755, dateTime: "2022-11-03T00:00:00" },
      ],
      volumesCount: 0,
      avgPriceCount: 0.564688615156,
      holders: 3,
    },
  },
  {
    id: 5,
    contractAddress: "0x2953399124f0cbb46d2cbacd8a89cf0599974963",
    tokenId: "85012068470949857615623703973878707195543258932276703717142883380199241549165",
    tokenStandard: "ERC1155",
    blockchain: "Polygon",
    creatorFees: "10%",
    description:
      "By UnderArmourSC. It's the ingredients inside; strength, character, focus that flows to the magic outside and changes the game for good.",
    digitalCollectible: "Collectible",
    primaryUtility: "Collectible only",
    brandCategory: "Sportswear",
    contentType: {
      video: null,
      _2DImage: null,
    },
    category: ["Fashion", "Art"],
    max_price: 0.001,
    AvgPrice: 0.001,
    AvgVolume: 0.001,
    AvgHold: [],
    AvgHold2: 244,
    transactionCount: {
      avgPriceCount: 1.5214440113545655,
      getTransactionCount: [
        { numberSales: 5, volume: 7.532, avgPrice: 1.5064, dateTime: "2022-10-04T00:00:00" },
        { numberSales: 10, volume: 14.6277, avgPrice: 1.4627700000000001, dateTime: "2022-10-05T00:00:00" },
        { numberSales: 11, volume: 15.8955, avgPrice: 1.4450454545454545, dateTime: "2022-10-06T00:00:00" },
        { numberSales: 11, volume: 16.46, avgPrice: 1.4963636363636363, dateTime: "2022-10-07T00:00:00" },
        { numberSales: 4, volume: 5.510000000000001, avgPrice: 1.3775000000000002, dateTime: "2022-10-08T00:00:00" },
        { numberSales: 5, volume: 7.285, avgPrice: 1.457, dateTime: "2022-10-09T00:00:00" },
        { numberSales: 9, volume: 12.588, avgPrice: 1.3986666666666665, dateTime: "2022-10-10T00:00:00" },
        { numberSales: 22, volume: 30.763, avgPrice: 1.3983181818181818, dateTime: "2022-10-11T00:00:00" },
        { numberSales: 28, volume: 39.7747, avgPrice: 1.420525, dateTime: "2022-10-12T00:00:00" },
        { numberSales: 12, volume: 18.066979999999997, avgPrice: 1.5055816666666664, dateTime: "2022-10-13T00:00:00" },
        { numberSales: 19, volume: 30.588995000000004, avgPrice: 1.6099471052631582, dateTime: "2022-10-14T00:00:00" },
        { numberSales: 10, volume: 17.747999999999998, avgPrice: 1.7747999999999997, dateTime: "2022-10-15T00:00:00" },
        { numberSales: 9, volume: 15.969999999999997, avgPrice: 1.774444444444444, dateTime: "2022-10-16T00:00:00" },
        { numberSales: 8, volume: 14.259, avgPrice: 1.782375, dateTime: "2022-10-17T00:00:00" },
        { numberSales: 5, volume: 8.575, avgPrice: 1.7149999999999999, dateTime: "2022-10-18T00:00:00" },
        { numberSales: 7, volume: 11.950000000000003, avgPrice: 1.7071428571428575, dateTime: "2022-10-19T00:00:00" },
        { numberSales: 6, volume: 7.9238, avgPrice: 1.3206333333333333, dateTime: "2022-10-20T00:00:00" },
        { numberSales: 4, volume: 6.348, avgPrice: 1.587, dateTime: "2022-10-21T00:00:00" },
        { numberSales: 4, volume: 6.530000000000001, avgPrice: 1.6325000000000003, dateTime: "2022-10-22T00:00:00" },
        { numberSales: 5, volume: 7.916, avgPrice: 1.5832000000000002, dateTime: "2022-10-23T00:00:00" },
        { numberSales: 10, volume: 15.78, avgPrice: 1.5779999999999998, dateTime: "2022-10-24T00:00:00" },
        { numberSales: 29, volume: 42.90999999999999, avgPrice: 1.4796551724137927, dateTime: "2022-10-25T00:00:00" },
        { numberSales: 5, volume: 7.3721, avgPrice: 1.4744199999999998, dateTime: "2022-10-26T00:00:00" },
        { numberSales: 6, volume: 9.065999999999999, avgPrice: 1.511, dateTime: "2022-10-27T00:00:00" },
        { numberSales: 12, volume: 18.402009999999997, avgPrice: 1.533500833333333, dateTime: "2022-10-28T00:00:00" },
        { numberSales: 8, volume: 11.74, avgPrice: 1.4675, dateTime: "2022-10-29T00:00:00" },
        { numberSales: 5, volume: 7.343, avgPrice: 1.4686, dateTime: "2022-10-30T00:00:00" },
        { numberSales: 5, volume: 7.34, avgPrice: 1.468, dateTime: "2022-10-31T00:00:00" },
        { numberSales: 4, volume: 5.766999999999999, avgPrice: 1.4417499999999996, dateTime: "2022-11-01T00:00:00" },
        { numberSales: 8, volume: 11.306, avgPrice: 1.41325, dateTime: "2022-11-02T00:00:00" },
        { numberSales: 8, volume: 10.991000000000001, avgPrice: 1.3738750000000002, dateTime: "2022-11-03T00:00:00" },
      ],
      holders: 10401,
      volumesCount: 444.32878499999987,
    },
  },
  {
    id: 6,
    contractAddress: "0x495f947276749ce646f68ac8c248420045cb7b5e",
    tokenId: "24669738458109347488789552043827723089814696673991841899921894440613433049089",
    tokenStandard: "ERC1155",
    blockchain: "Ethereum",
    creatorFees: "5%",
    description:
      "The METARACER™ shoe is made for runners who want the most out of their fast-paced training and racing. This NFT is a piece of Limited Edition memorabilia that is limited to just 20 editions sequentially numbered on the NFT. The highest bidder will also gain access to unlockable content attached to the NFT. Unlockable content for this NFT includes a link to download the 3D model and texture files for the METARACER™ Limited Edition digital shoe.",
    digitalCollectible: "Miscellaneous",
    primaryUtility: "Collectible only",
    brandCategory: "Sportswear",
    contentType: {
      _2DImage:
        "https://i.seadn.io/gae/TXS7DHkerl0xQd7COoiO_fEZ0QG6GRbk8gMjIkWAVhbiax1wwZtAUY9vdWj4gXCGriwMcmWbg3g43lhx2di6XpbjtlpmDyAOLXOmlA?w=500&auto=format",
      video: "https://openseauserdata.com/files/818f378896e18a60b54ff83a8c45a1fe.mp4#t=0.001",
    },
    category: ["Fashion", "Collectible"],
    max_price: 0.001,
    AvgPrice: 0.001,
    AvgVolume: 0.001,
    AvgHold: [],
    AvgHold2: 63,
    transactionCount: {
      avgPriceCount: 1.5214440113545655,
      getTransactionCount: [
        { numberSales: 5, volume: 7.532, avgPrice: 1.5064, dateTime: "2022-10-04T00:00:00" },
        { numberSales: 10, volume: 14.6277, avgPrice: 1.4627700000000001, dateTime: "2022-10-05T00:00:00" },
        { numberSales: 11, volume: 15.8955, avgPrice: 1.4450454545454545, dateTime: "2022-10-06T00:00:00" },
        { numberSales: 11, volume: 16.46, avgPrice: 1.4963636363636363, dateTime: "2022-10-07T00:00:00" },
        { numberSales: 4, volume: 5.510000000000001, avgPrice: 1.3775000000000002, dateTime: "2022-10-08T00:00:00" },
        { numberSales: 5, volume: 7.285, avgPrice: 1.457, dateTime: "2022-10-09T00:00:00" },
        { numberSales: 9, volume: 12.588, avgPrice: 1.3986666666666665, dateTime: "2022-10-10T00:00:00" },
        { numberSales: 22, volume: 30.763, avgPrice: 1.3983181818181818, dateTime: "2022-10-11T00:00:00" },
        { numberSales: 28, volume: 39.7747, avgPrice: 1.420525, dateTime: "2022-10-12T00:00:00" },
        { numberSales: 12, volume: 18.066979999999997, avgPrice: 1.5055816666666664, dateTime: "2022-10-13T00:00:00" },
        { numberSales: 19, volume: 30.588995000000004, avgPrice: 1.6099471052631582, dateTime: "2022-10-14T00:00:00" },
        { numberSales: 10, volume: 17.747999999999998, avgPrice: 1.7747999999999997, dateTime: "2022-10-15T00:00:00" },
        { numberSales: 9, volume: 15.969999999999997, avgPrice: 1.774444444444444, dateTime: "2022-10-16T00:00:00" },
        { numberSales: 8, volume: 14.259, avgPrice: 1.782375, dateTime: "2022-10-17T00:00:00" },
        { numberSales: 5, volume: 8.575, avgPrice: 1.7149999999999999, dateTime: "2022-10-18T00:00:00" },
        { numberSales: 7, volume: 11.950000000000003, avgPrice: 1.7071428571428575, dateTime: "2022-10-19T00:00:00" },
        { numberSales: 6, volume: 7.9238, avgPrice: 1.3206333333333333, dateTime: "2022-10-20T00:00:00" },
        { numberSales: 4, volume: 6.348, avgPrice: 1.587, dateTime: "2022-10-21T00:00:00" },
        { numberSales: 4, volume: 6.530000000000001, avgPrice: 1.6325000000000003, dateTime: "2022-10-22T00:00:00" },
        { numberSales: 5, volume: 7.916, avgPrice: 1.5832000000000002, dateTime: "2022-10-23T00:00:00" },
        { numberSales: 10, volume: 15.78, avgPrice: 1.5779999999999998, dateTime: "2022-10-24T00:00:00" },
        { numberSales: 29, volume: 42.90999999999999, avgPrice: 1.4796551724137927, dateTime: "2022-10-25T00:00:00" },
        { numberSales: 5, volume: 7.3721, avgPrice: 1.4744199999999998, dateTime: "2022-10-26T00:00:00" },
        { numberSales: 6, volume: 9.065999999999999, avgPrice: 1.511, dateTime: "2022-10-27T00:00:00" },
        { numberSales: 12, volume: 18.402009999999997, avgPrice: 1.533500833333333, dateTime: "2022-10-28T00:00:00" },
        { numberSales: 8, volume: 11.74, avgPrice: 1.4675, dateTime: "2022-10-29T00:00:00" },
        { numberSales: 5, volume: 7.343, avgPrice: 1.4686, dateTime: "2022-10-30T00:00:00" },
        { numberSales: 5, volume: 7.34, avgPrice: 1.468, dateTime: "2022-10-31T00:00:00" },
        { numberSales: 4, volume: 5.766999999999999, avgPrice: 1.4417499999999996, dateTime: "2022-11-01T00:00:00" },
        { numberSales: 8, volume: 11.306, avgPrice: 1.41325, dateTime: "2022-11-02T00:00:00" },
        { numberSales: 8, volume: 10.991000000000001, avgPrice: 1.3738750000000002, dateTime: "2022-11-03T00:00:00" },
      ],
      holders: 10401,
      volumesCount: 444.32878499999987,
    },
  },
];
