import SocialmediaIcon from "./icons/SocialmediaIcon";

const PlatformSelection = () => {
  return (
    <div role="group" aria-label="Basic radio toggle button group">
      <label className="GraphContainer__Label d-none d-sm-inline-block mx-2 align-bottom">Sort By Platform:</label>
      <input
        type="radio"
        className="btn-check"
        name="socialmediachoice_sharedPosts"
        id="sm_twitter_sharedPosts"
        value="twitter"
        onChange={() => true}
        defaultChecked
      />
      <label className="btn btn-radio mx-2" htmlFor="sm_twitter_sharedPosts">
        <SocialmediaIcon id={'twitter'} name={'twitter'} /><span className="pl-2 d-none d-sm-inline">Twitter</span>
      </label>

      <input
        type="radio"
        className="btn-check"
        name="socialmediachoice_sharedPosts"
        id="sm_youtube_sharedPosts"
        value="youtube"
        disabled
      />
      <label className="btn btn-radio mx-2" htmlFor="sm_youtube_sharedPosts">
        <SocialmediaIcon id={'youtube'} name={'youtube'} />
      </label>

      <input
        type="radio"
        className="btn-check"
        name="socialmediachoice_sharedPosts"
        id="sm_tiktok_sharedPosts"
        value="tiktok"
        disabled
      />
      <label className="btn btn-radio mx-0" htmlFor="sm_tiktok_sharedPosts">
        <SocialmediaIcon id={'tiktok'} name={'tiktok'} />
      </label>

      <input
        type="radio"
        className="btn-check"
        name="socialmediachoice_sharedPosts"
        id="sm_instagram_sharedPosts"
        value="instagram"
        disabled
      />
      <label className="btn btn-radio mx-0" htmlFor="sm_instagram_sharedPosts">
        <SocialmediaIcon id={'instagram'} name={'instagram'} />
      </label>
    </div>
  );
};

export default PlatformSelection;
