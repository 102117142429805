import plusIcon from "../../images/circleIcon.svg";
import searchIcon from "../../images/search.svg";
import homeIcon from "../../images/homeIcon.svg";
import hashtagIcon from "../../images/hashtag.svg";
import avatarIcon from "../../images/avatarchat.png";
import searchWhiteIcon from "../../images/search_white.svg";
import triangleRedIcon from "../../images/triangle_red.svg";
import triangleGreenIcon from "../../images/triangle_green.svg";
import ChatBox from "./ChatBox";
import Marquee from "react-fast-marquee";
import { useState } from "react";

export default function Landing() {
  const tickets = [
    {
      id: 1,
      text: "Azuki",
      positive: true,
      percent: "+19.83%",
      icon: triangleGreenIcon,
    },
    {
      id: 2,
      text: "Rhider",
      positive: true,
      percent: "+1.52%",
      icon: triangleGreenIcon,
    },
    {
      id: 3,
      text: "Open Space",
      positive: false,
      percent: "-9.87%",
      icon: triangleRedIcon,
    },
    {
      id: 4,
      text: "PCO",
      positive: true,
      percent: "+5.44%",
      icon: triangleGreenIcon,
    },
    {
      id: 5,
      text: "OGPD",
      positive: false,
      percent: "-50.83%",
      icon: triangleRedIcon,
    },
    {
      id: 6,
      text: "Open Space",
      positive: false,
      percent: "-9.87%",
      icon: triangleRedIcon,
    },
    {
      id: 7,
      text: "Rhider",
      positive: true,
      percent: "+1.52%",
      icon: triangleGreenIcon,
    },
    {
      id: 8,
      text: "OGPD",
      positive: false,
      percent: "-50.83%",
      icon: triangleRedIcon,
    },
    {
      id: 9,
      text: "PCO",
      positive: true,
      percent: "+5.44%",
      icon: triangleGreenIcon,
    },
  ];
  const [access, setAccess] = useState(false);
  const [connectWallet, setConnectWallet] = useState(false);
  return (
    <div className="Landing-container">
      <div className="Landing_header">
        <div className="Landing_header_left">
          <p onClick={() => setAccess(false)}>UNFUCK</p>
        </div>
        <ul className="Landing_header_menu">
          <li>Home</li>
          <li>README.md</li>
          <li>
            {connectWallet ? (
              <button className="button_connect">0xd39...29d9</button>
            ) : (
              <button className="button_connect" onClick={() => setAccess(true)}>
                Connect Wallet
              </button>
            )}
          </li>
        </ul>
      </div>
      {access ? (
        <ChatBox setConnectWallet={setConnectWallet} connectWallet={connectWallet} />
      ) : (
        <>
          <div className="Landing_content">
            <div className="Landing_text_heading">
              <div className="hero_title">
                <h1>ACCESS Unparalleled Knowledge.</h1>
              </div>
              <div className="Sub_title">
                <p>
                  Try out our automated projects sourcing to discover and connect with a large number of stealth and
                  about to launch protocols & applications before anyone else.
                </p>
              </div>
              <div className="Landing_start">
                <button className="btn_access">Access Beta</button>
                <p>Join Waitlist</p>
              </div>
            </div>
            <div className="Landing_main">
              <div className="Secondary_Tag">
                <div className="Secondary_Tag_title">
                  <h1>Meet Unfuck. An All New AI Powered Alpha Generator.</h1>
                </div>
                <div className="Secondary_Tag_text">
                  <p>
                    Use Unfuck to help you find and learn about the newest and most slept on collections out there. Chat
                    with her on telegram, discord, and more.
                  </p>
                </div>
              </div>
              <div className="Discordbot">
                <div className="Discordbot_navbar">
                  <div className="Discordbot_navbar_top">
                    <div className="mac-button red"></div>
                    <div className="mac-button yellow"></div>
                    <div className="mac-button green"></div>
                  </div>
                  <div className="Discordbot_navbar_wrapper">
                    <div className="navbar_item">
                      <img src={homeIcon} alt="" />
                    </div>
                    <div className="navbar_item">
                      <img src={plusIcon} alt="" />
                    </div>
                    <div className="navbar_item">
                      <img src={searchIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div className="Discordbot_chatbox">
                  <div className="chatbox_title">
                    <img src={hashtagIcon} alt="" />
                    <p>Unfuck Bot</p>
                  </div>
                  <div className="chatbox_content">
                    <div className="chatbox_list">
                      <div className="chatbox_list_item">
                        <img src={avatarIcon} alt="" />
                        <div className="chatbox_text">
                          <div className="chatbox_text_heading">
                            <p>Unfuck Bot</p>
                            <span>02/03/2023 </span>
                          </div>
                          <div className="chatbox_text_content">
                            <p>
                              Here are some recommended NFTs for this week, powered by Ojamu. "Wild Wonders" collection
                              "Galactic Gems" collection "Mythical Creatures" collection "Enchanted Landscapes"
                              collection. "Future Tech" collection Ask me some questions about these collections to
                              learn more!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="chatbox_send">
                      <div className="chatbox_send_btn">
                        <img src={searchWhiteIcon} alt="" />
                      </div>
                      <input type="text" placeholder="Ask me anything about NFTs!" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Landing_text_heading">
              <div className="hero_title">
                <h1>POWERED BY SOME DOPE TECHNOLOGY</h1>
              </div>
              <div className="Sub_title">
                <p>
                  We’ve partnered with the folks over at Ojamu to get you the best and fastest insights. Use all of
                  their tools and analytics to teach yourself how to find the best collections and even better gains.
                  With the power of GPT and Ojamu we are proud to present to you one of most elite analytics tool in the
                  game. Try us out, check out some of our previous calls and see what we can do.
                </p>
              </div>
            </div>
          </div>
          <div className="Landing_ticket">
            <Marquee gradient={false} speed={30}>
              {tickets.map((item, index) => (
                <div className="ticket_item" key={index}>
                  <p>{item.text}</p>
                  <img src={item.icon} alt="" />
                  <span className={item.positive ? "positive" : ""}>{item.percent}</span>
                </div>
              ))}
            </Marquee>
          </div>
        </>
      )}
      <div className="Landing_footer">
        <ul className="Landing_footer_list">
          <li className="Landing_footer_item">2023 Unfuck</li>
          <li className="Landing_footer_item">Terms and Conditions</li>
          <li className="Landing_footer_item">Contact</li>
          <li className="Landing_footer_item">Disclaimer</li>
        </ul>
      </div>
    </div>
  );
}
