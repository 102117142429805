import OjamuLogo from "../../components/logo/OjamuLogo";
import { Col, Container, Row } from "react-bootstrap";
import ProgressDots from "../../components/progress/ProgressDots";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CompanyForm from "./steps/CompanyForm";
import Web3SpaceForm from "./steps/Web3SpaceForm";
import CompetitorsForm from "./steps/CompetitorsForm";
import { useStores } from "../../store/RootStore";

export default function OnBoarding() {
  const { companyStore, competitorStore } = useStores();

  useEffect(() => {
    companyStore.getCompanyFormDetails().then();
  });

  const initialState = {
    goal: 'brand',
    step: 1,
    isLoading: false,
  };

  const navigate = useNavigate();

  const [onBoardingState, setOnBoardingState] = useState(initialState);

  const onHandleBackNextClick = async (direction: any) => {
    setOnBoardingState({ ...onBoardingState, ...direction });
  };

  const onHandleSubmitClick = () => {
    const selectedCompetitors = competitorStore.industryCompetitors.filter((ic) => ic.selected);
    if (selectedCompetitors.length > 2) {
      setOnBoardingState({ ...onBoardingState, isLoading: true }); // Just to display the loading indicator
      competitorStore.setIndustryCompetitors().then();
      setTimeout(() => {
        setOnBoardingState({ ...onBoardingState, isLoading: false });
        navigate("/dashboard");
      }, 500);
    }
  };

  const renderSelectedForm = (onBoardingState: any) => {
    let selectedForm;

    const forms = {
      brand: [
        <CompanyForm
          signup={onBoardingState}
          handleNextClick={onHandleBackNextClick}
        />,
        <Web3SpaceForm
          signup={onBoardingState}
          handleBackClick={onHandleBackNextClick}
          handleNextClick={onHandleBackNextClick}
          isLoading={onBoardingState.isLoading}
        />,
        <CompetitorsForm
          signup={onBoardingState}
          handleBackClick={onHandleBackNextClick}
          handleSubmitClick={onHandleSubmitClick}
          isLoading={onBoardingState.isLoading}
        />
      ],
    };

    if (onBoardingState.step > 0) {
      selectedForm = forms[onBoardingState.goal] ? forms[onBoardingState.goal][onBoardingState.step - 1] : null;
    }

    return selectedForm;
  };

  return (
    <>
      <div className="Onboard">
        <section className="Header text-center">
            <OjamuLogo small />
        </section>

        <Container className="pb-5">
          <Row className="justify-content-center">
            <Col md={12} className="d-flex flex-column">
              {renderSelectedForm(onBoardingState)}
            </Col>
          </Row>
        </Container>

        <ProgressDots step={onBoardingState.step} />
      </div>
    </>
  );
}
