import OjamuLogo from "../logo/OjamuLogo";
import { faChartBar, faSignOutAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useNavigate } from "react-router-dom";

import { JWT_TOKEN } from "../../store/LoginStore";
import { useStores } from "../../store/RootStore";

const TopNavigation = () => {
  let navigate = useNavigate();
  const { loginStore } = useStores();

  const logOut = () => {
    sessionStorage.removeItem(JWT_TOKEN);
    loginStore.loginIsSuccessful = false;
    navigate("/login");
  };

  const navigationInsightSite = () => {
    let url = ""
   if (window.location.href.includes("https://intelligence.ojamu.com/")){
      url = "https://insights.ojamu.com/engagement"
   } else if(window.location.href.includes("https://intelligence-ojamu.vercel.app/")){
      url = "https://insights-ojamu.vercel.app/engagement"
   }else{
      url= "https://insights-frontend-pink.vercel.app/engagement"
   }
   return url
  }

  const navItems = [
    {
      label: "Results",
      page: "/dashboard",
    },
    {
      label: "Intelligence",
      page: "/dashboard/insights",
    },
    {
      label: "Engagement",
      page: "/dashboard/engagement",
    },
    {
      label: "Campaigns",
      page: "/dashboard/campaigns",
    },
  ];

  return (
    <nav className="TopDashboardTopNav navbar navbar-expand-lg fixed-top">
      <NavLink to="/">
        <OjamuLogo small />
      </NavLink>
      <div className="d-flex">
        <ul className="nav nav--left">
          {navItems.map(function (navItem, index) {
            return (
              <li key={index} className="nav-item">
                <NavLink className="nav-link" end to={navItem.page}>
                  {navItem.label}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <ul className="nav nav--right">
          <li className="nav-item nav-item--logout">
            <a
              className="nav-link"
              href={navigationInsightSite()}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon className="nav-link__Icon" icon={faChartBar} />
              Insights Tool
            </a>
          </li>
          <li className="nav-item nav-item--logout">
            <NavLink className="nav-link" to="/dashboard/profile">
              <FontAwesomeIcon className="nav-link__Icon" icon={faUserCircle} />
              Account
            </NavLink>
          </li>
          <li className="nav-item nav-item--logout">
            <NavLink className="nav-link" to="/login" onClick={logOut}>
              <FontAwesomeIcon className="nav-link__Icon" icon={faSignOutAlt} />
              Sign Out
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};
export default TopNavigation;
