import { useState } from "react";
import SendIcon from "../../images/Send_fill.svg";

interface Props {
  setConnectWallet: (value: boolean) => void;
  connectWallet: boolean;
}

export default function ChatBox(props: Props) {
  const [messenger, setMessenger] = useState("");
  const [messList, setMessList] = useState<any>([]);

  const handleSendMess = () => {
    const mess = { text: messenger, bot: false };
    messList.push(mess);
    setMessList(messList);
    setMessenger("");
  };

  return (
    <div className="Landing_content">
      <div className="Landing_text_heading">
        <div className="hero_title">
          <h1>TRY OUR BETA</h1>
          <p>Open to the first 100 users.</p>
        </div>
      </div>
      <div className="Access_chatBox">
        {props.connectWallet ? (
          <>
            <div className="Access_chatBox_container">
              {messList.length > 0 ? (
                <div className="mess_list">
                  {messList.map((item: any, index: number) => (
                    <div className="mess_item" key={index}>
                      {item.text}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="Rememer_text">
                  <p className="Rememer_text_title">Remember:</p>
                  <div className="Rememer_text_content">
                    <p>1. This chatbot is supposed to be used as a learning tool.</p>
                    <p>
                      2. Although we try our best to train our model on the most new/relevant news and price movement,
                      this tool is never meant to be used as investment advice.
                    </p>
                    <p>3. The model can lie or makeup facts from time to time. Be careful.</p>
                  </div>
                </div>
              )}
            </div>
            <div className="form_send">
              <div className="form_send_box">
                <input
                  type="text"
                  value={messenger}
                  onChange={(e) => setMessenger(e.target.value)}
                  placeholder="Ask me Anything about Zk-Rollups!"
                />
                <button onClick={handleSendMess}>
                  <img src={SendIcon} alt="" />
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="box_not_connect">
            <p>Connect your wallet to begin.</p>
            <button className="btn_connect" onClick={() => props.setConnectWallet(true)}>
              Connect Wallet
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
