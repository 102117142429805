import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import BrandIcon from "../../../components/icons/BrandIcon";
import { useStores } from "../../../store/RootStore";

const CompetitorsForm = (props) => {
  const [industryCompetitors, setIndustryCompetitors] = useState([]);
  const { companyStore, competitorStore } = useStores();

  const [showSelectorList, setShowSelectorList] = useState(false);

  useEffect(() => {
    const fetchIndustryCompetitors = async (industryId) => {
      const industryCompetitors = await competitorStore.getIndustryCompetitors(industryId);
      if (industryCompetitors !== undefined) {
        setIndustryCompetitors(industryCompetitors);
      }
    };

    const fetchIndustry = async () => {
      const industryId = companyStore.selectedIndustry;
      await fetchIndustryCompetitors(industryId);
    };

    fetchIndustry().catch(console.error);
  }, [competitorStore, companyStore]);

  const populateItems = (industryCompetitors) => {
    const industryCompetitorsNotErroneous = !competitorStore.getIndustryCompetitorsIsErroneous;
    return (
      industryCompetitorsNotErroneous &&
      industryCompetitors.map((competitor, index) => (
        <li key={index}>
          <div className="Selector__List__Item" onClick={() => toggleCompetitorSelection(index, competitor)}>
            <span>
              <BrandIcon id={competitor.id} name={competitor.name} />
              {competitor.name}
            </span>
            <i className={`Selector__List__Indicator ${competitor.selected ? 'Selector__List__Indicator--selected' : ''}`}></i>
          </div>
        </li>
      ))
    );
  };

  const selectorPlaceholder = () => {
    const names = industryCompetitors.filter( ic => ic.selected).map( o => o.name);
    return names.length > 0 ? names.join(', ') : 'Suggested Competitors';
  }

  const toggleCompetitorSelection = (index, id) => {
    setIndustryCompetitors(competitorStore.toggleCompetitorSelection(id.id));
  };

  const toggleSelectorList = () => {
    setShowSelectorList(!showSelectorList);
  };

  return (
    <>
      <div className="row">
        <div className="col-8 d-flex align-items-center">
          <div className="pe-5">
            <strong className="color--purple">Step 03</strong>
            <h1 className="pt-1 pb-3">Your Brand's Competitors</h1>
            <span>
              Well done, you’re almost there!
              <br/>
              <br/>
              For the last step let us know who your brand’s competitors are and the Ojamu AI will do the rest!
            </span>
          </div>
        </div>
        <div className="col-4">
          <h3 className="d-flex justify-content-between align-items-center pb-0">
            Competitors
            <small className="font-smaller">
              Minimum of 3 Required <span className="color--purple">*</span>
            </small>
          </h3>
          <hr />

          <form>
            <div className="form-group pb-2">
              {industryCompetitors.length > 0 && (
                <div className="Selector">
                  <span className="Selector__Label" onClick={toggleSelectorList}>
                    <i className={`${showSelectorList ? "show" : ""}`}></i>
                    {selectorPlaceholder()}
                  </span>
                  <ul className={`Selector__List ${showSelectorList ? "Selector__List--show" : ""}`}>
                    <li className="Selector__List__Title">Suggestions:</li>
                    {populateItems(industryCompetitors)}
                  </ul>
                </div>
              )}
            </div>

            <div className="row pt-4">
              <div className="col-6">
                <Button
                  className="Button Button--primary Button--primary--outlined w-100"
                  onClick={() => props.handleBackClick({ goal: "brand", step: 2 })}
                >
                  <span>Back</span>
                </Button>
              </div>

              <div className="col-6">
                <Button
                  className="Button Button--primary w-100"
                  onClick={() => props.handleSubmitClick()}
                  disabled={industryCompetitors.filter( ic => ic.selected).length < 3}
                >
                  <span>Submit</span>

                  {props.isLoading ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    <svg
                      className="Button__Icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="5.258"
                      height="8.514"
                      viewBox="0 0 5.258 8.514"
                    >
                      <path
                        id="Icon_material-keyboard-arrow-down"
                        data-name="Icon material-keyboard-arrow-down"
                        d="M10,11.76l3.257,3.25,3.257-3.25,1,1-4.257,4.257L9,12.76Z"
                        transform="translate(-11.76 17.514) rotate(-90)"
                        fill="#fff"
                      />
                    </svg>
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CompetitorsForm;
