import React from "react";
import "chartjs-adapter-moment";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, TimeScale, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, TimeScale);

const TimeOfTheDayGraph = (props) => {
  const graphData = props.data;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        type: "timeseries",
        display: true,
        ticks: {
          callback: function (value, index) {
            return index > 0 && index < 24 && index % 3 === 0 ? value : "";
          },
        },
        time: {
          unit: "hour",
          displayFormats: {
            day: "HH:mm",
          },
        },
      },
      yAxis: {
        ticks: {
          display: false,
        },
      },
    },
  };

  let timeArray = [];

  for (let i = 0; i < 25; i++) {
    timeArray.push(i + ":00");
  }

  const labels = timeArray.map((e) => moment(e, "HH:mm"));

  function argMax(array) {
    return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
  }

  let color = graphData.map((x) => "#fff");
  color[argMax(graphData)] = "#e10c7c";

  const data = {
    labels,
    datasets: [
      {
        data: graphData,
        backgroundColor: color,
        borderRadius: 3,
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

export default TimeOfTheDayGraph;
