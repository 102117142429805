export const formattedDate = (date: any, format = "dmy") => {
  let d = new Date(date);
  let ye = new Intl.DateTimeFormat("de", { year: "numeric" }).format(d);
  let mo = new Intl.DateTimeFormat("de", { month: "2-digit" }).format(d);
  let da = new Intl.DateTimeFormat("de", { day: "2-digit" }).format(d);

  switch (format) {
    case "ymd":
      return `${ye}-${mo}-${da}`;

    case "daynames":
      return d.toLocaleDateString("en", { weekday: "short" });

    case "mdy":
      return (d.toLocaleString('en', { month: 'short', year: 'numeric', day: 'numeric' }));

    default:
      return `${da}.${mo}.${ye}`;
  }
};
