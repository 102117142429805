// https://stackoverflow.com/a/11888430
// https://qr.ae/pGEeJD

export const dayLightSavingsPeriod = (): boolean => {
  const standardTimezoneOffset = (today: Date) => {
    let january = new Date(today.getFullYear(), 0, 1);
    let july = new Date(today.getFullYear(), 6, 1);
    return Math.max(january.getTimezoneOffset(), july.getTimezoneOffset());
  };

  const isDayLightSavingsObserved = (today: Date) => {
    return today.getTimezoneOffset() < standardTimezoneOffset(today);
  };

  let today = new Date();
  return isDayLightSavingsObserved(today);
};
