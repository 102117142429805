import { API, BASE_URL } from "../util/ApiUtil";
import JwtProvider from "../auth/JwtProvider";

export default class MarketingApi {
  public static logIn = (credentials: LoginCredentials): Promise<LoginResponse> => {
    return API.post(BASE_URL + "marketing/login", credentials);
  };

  public static logInWithMetaMask = (credentials: MetaMaskCredentials): Promise<LoginResponse> => {
    return API.post(BASE_URL + "marketing/login/metamask", credentials);
  };

  public static getCompanyDetails = (): Promise<CompanyDetailsResponse> => {
    return API.get(BASE_URL + "marketing/user/company/company-details", {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static postCompanyForm = (companyDataRequest: CompanyDataRequest): Promise<DefaultAPIResponse> => {
    return API.post(BASE_URL + "marketing/user/company", companyDataRequest, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static getIndustryCompetitors = (industryId: number): Promise<IndustryCompetitorResponse> => {
    return API.get(BASE_URL + "marketing/user/competitor/competitor-details?industry_id=" + industryId, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static setIndustryCompetitors = (competitorList: CompetitorListRequest): Promise<DefaultAPIResponse> => {
    return API.post(BASE_URL + "marketing/user/competitor", competitorList, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static getCompetitorTwitterMetrics = (
    request: CompetitorTwitterMetricsRequest
  ): Promise<CompetitorTwitterMetricsResponse> => {
    return API.get(
      BASE_URL +
        `marketing/priceXEngagementRate?account=${request.account}&startDate=${request.startDate}&endDate=${request.endDate}`,
      {
        headers: { "x-access-token": this.getJwt() },
      }
    );
  };

  public static getUserCompetitors = (): Promise<UserCompetitorsResponse> => {
    return API.get(BASE_URL + "marketing/user/competitor", {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static getCompetitorRecommendations = (
    competitors: CompetitorRecommendationRequest
  ): Promise<RecommendationResponse> => {
    return API.post(BASE_URL + "marketing/recommend/recommend", competitors, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static getUSD = (): Promise<BaseResponse> => {
    return API.get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD", {});
  };

  public static getTopWallet = (): Promise<TopWalletResponse> => {
    return API.post(BASE_URL + "marketing/getTopWallet", null, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static getTopWalletDetail = (wallet_address: any): Promise<TopWalletDetailResponse> => {
    return API.post(BASE_URL + "marketing/getTopWalletsByWalletsAddress", wallet_address, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static getWeb3Spaces = (): Promise<Web3SpacesResponse> => {
    return API.get(BASE_URL + "marketing/user/space/space-details", {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static postWeb3Spaces = (selectedWeb3Space: SelectedWeb3SpaceRequest): Promise<DefaultAPIResponse> => {
    return API.post(BASE_URL + "marketing/user/space", selectedWeb3Space, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static fetchTopNFTCollections = (competitors: CompetitorListRequest): Promise<TopNFTCollectionsResponse> => {
    return API.post(BASE_URL + "marketing/top-nft-collections", competitors, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static getNFTDetailAnalysis = (nftId: any): Promise<NFTDetailResponsive> => {
    return API.post(BASE_URL + "marketing/getNFTAnalysis", nftId, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static getAvgHoldTime = (nftId: any): Promise<TopNFTCollectionsResponse> => {
    return API.post(BASE_URL + "marketing/getAvgHoldTimeByContractAddress", nftId, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static getNFTTransactionCount = (param: any): Promise<NFTDetailResponsive> => {
    return API.post(BASE_URL + "marketing/getTransactionCount", param, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static getSavedCampaign = () => {
    return API.get(BASE_URL + "marketing/getCampaigns", {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static saveCampaign = (campaign: Campaign): Promise<CampaignResponse> => {
    return API.post(BASE_URL + "marketing/saveCampaigns", campaign, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static removeSavedCampaign = (campaign_id: any): Promise<CampaignResponse> => {
    return API.post(BASE_URL + "marketing/removerCampaign", campaign_id, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  public static getRelevantWallets = (wallet_address: any) => {
    return API.post(BASE_URL + "marketing/getRelevantWallets", wallet_address, {
      headers: { "x-access-token": this.getJwt() },
    });
  };

  private static getJwt() {
    return JwtProvider.getJwt();
  }
}
