import { useEffect, useState } from "react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InsightsPopup from "./InsightPopup";
import checkCircleIcon from "../../../images/check-cricle.svg";
import likeUpIcon from "../../../images/likeUp.svg";
import likeDownIcon from "../../../images/likeDown.svg";
import minusIcon from "../../../images/menus.svg";
import tiwterIcon from "../../../images/tiwter.svg";
import controlIcon from "../../../images/control.svg";
import blockChainIcon from "../../../images/blockchain-icon.svg";
import solIcon from "../../../images/solIcon.svg";
import PlusCircle from "../../../images/plusCircle.svg";
import InfoCircle from "../../../images/infoCircle.svg";
import telegramIcon from "../../../images/social-1.svg";
import gameIcon from "../../../images/social-2.svg";
import tiktokIcon from "../../../images/social-3.svg";
import { walletAddress } from "../../../assets/fakedata";
import TweeterModal from "../../../components/Modal/TweeterModal";
import WalletModal from "../../../components/Modal/WalletModal";
import transForm from "../../../assets/TransformImage";
import { transformToImage, changeContractWallet } from "../../../util/TransformWallet";

const CampaignItem = (props: any) => {
  const [showNFTInsight, setShowNFTInsight] = useState(false);
  const [showCampaignInsight, setShowCampaignInsight] = useState(false);
  const [showWalletInsight, setShowWalletInsight] = useState(false);
  const [showSocialInsight, setShowSocialInsight] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showWalletPopup, setShowWalletPopup] = useState(false);
  const [showSocialPopup, setShowSocialPopup] = useState(false);
  const [index, setIndex] = useState(0);
  const [relevantWallet, setRelevantWallet] = useState(walletAddress);

  useEffect(() => {
    const walletArray = props.campaign["Relevant Wallets"].map((wallet: any, index: number) => {
      return {
        address: wallet.Address,
        class: `image${wallet.Address} `,
      };
    });
    walletArray.pop();
    setRelevantWallet(walletArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    transForm();
    relevantWallet.forEach((item: any) => {
      const image1Arr = Array.from(document.querySelectorAll(`.image${item.address}`));
      if (image1Arr) {
        image1Arr.forEach((selector) => {
          transformToImage(selector, item.address);
        });
      }
    });
  }, [relevantWallet]);

  const showPopupComingSoon = (index: number) => {
    setIndex(index);
    setShowSocialPopup(true);
  };

  return (
    <>
      {showPopup && <TweeterModal setShowPopup={setShowPopup} />}
      {showWalletPopup && (
        <WalletModal
          setShowWalletPopup={setShowWalletPopup}
          setWalletData={props.setWalletData}
          setShowWalletModal={props.setShowWalletModal}
          wallet={props.campaign["Relevant Wallets"]}
        />
      )}
      <div className="d-flex card-campaign">
        <div className="content d-flex">
          <div className="content-nft">
            <div>
              <div className="d-flex">
                <div className="content-nft-image">
                  <img src={props.campaign.Image} alt="" />
                </div>
                <div className="d-flex flex-column justify-content-between">
                  <div className="d-flex align-items-center">
                    <h3 className="SubTitle__Titles__Title mb-0">{props.campaign["NFT Type"]}</h3>
                    <div className="position-relative">
                      <img
                        className="ps-1"
                        onMouseEnter={() => setShowNFTInsight(true)}
                        onMouseLeave={() => setShowNFTInsight(false)}
                        src={InfoCircle}
                        alt=""
                      />
                      {showNFTInsight && (
                        <InsightsPopup
                          closePopup={setShowNFTInsight}
                          insights={props.campaign["Relevant NFTs"][props.index].Insights}
                          page={"nft"}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="rate content-nft-detail d-flex align-items-center"
                    onMouseEnter={() => setShowNFTInsight(true)}
                    onMouseLeave={() => setShowNFTInsight(false)}
                  >
                    <img src={checkCircleIcon} alt="" />
                    <p className="ps-2">
                      {(props.campaign["Success Rate"] * 100).toFixed(1)}% - Estimated success rate
                    </p>
                  </div>
                  <div
                    className="blockchain content-nft-detail d-flex align-items-center"
                    onMouseEnter={() => setShowNFTInsight(true)}
                    onMouseLeave={() => setShowNFTInsight(false)}
                  >
                    <img src={controlIcon} alt="" />
                    <p className="ps-2">{props.campaign["Content Tag"]} - Web3 focus area</p>
                  </div>
                </div>
              </div>
              <div className="content-nft-description">
                <p>{props.campaign.Description}</p>
              </div>
            </div>
          </div>
          <div className="content-campaign">
            <div className="d-flex align-items-center">
              <h3 className="SubTitle__Titles__Title title-color mb-3">Campaign details</h3>
              <div className="position-relative">
                <img
                  className="ps-1 mb-3"
                  onMouseEnter={() => setShowCampaignInsight(true)}
                  onMouseLeave={() => setShowCampaignInsight(false)}
                  src={InfoCircle}
                  alt=""
                />
                {showCampaignInsight && (
                  <InsightsPopup
                    closePopup={setShowCampaignInsight}
                    insights={props.campaign.Insights}
                    page={"campaign"}
                  />
                )}
              </div>
            </div>
            <div className="blockchain">
              <p className="text-header mb-2">Blockchain</p>
              <div className="d-flex align-items-center">
                <img src={props.campaign.Blockchain === "ETH" ? blockChainIcon : solIcon} alt="" />
                <p className="text-content ms-2">{props.campaign.Blockchain}</p>
              </div>
            </div>
            <div className="d-flex mt-4 justify-content-between">
              <div className="price">
                <p className="text-header mb-2">LaunchPrice</p>
                <p className="text-content">${props.campaign.Price <= 2000 ? props.campaign.Price : 2000}</p>
              </div>
              <div className="amount">
                <p className="text-header mb-2"># of Items</p>
                <p className="text-content">{props.campaign.Amount <= 1000 ? props.campaign.Amount : 1000}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content d-flex">
          <div className="content-wallet">
            <div className="d-flex align-items-center">
              <h3 className="SubTitle__Titles__Title title-color mb-3">Relevant wallets</h3>
              <div className="position-relative">
                <img
                  className="ps-1 mb-3"
                  onMouseEnter={() => setShowWalletInsight(true)}
                  onMouseLeave={() => setShowWalletInsight(false)}
                  src={InfoCircle}
                  alt=""
                />
                {showWalletInsight && (
                  <InsightsPopup
                    closePopup={setShowWalletInsight}
                    insights={props.campaign["Relevant Wallets"][props.index].Insights}
                    page={"wallet"}
                  />
                )}
              </div>
            </div>
            <div className="d-flex">
              {relevantWallet.map((wallet: any, index: number) => (
                <div key={index}>
                  <div className="content-wallet-img marign-right">
                    <div className="Card__Number">0{index + 1}</div>
                    <img className={wallet.class} alt="" />
                  </div>
                  <p className="my-3 address-wallet">{changeContractWallet(wallet.address)}</p>
                </div>
              ))}
            </div>
            <div className="d-flex align-items-center cursor-pointer" onClick={() => setShowWalletPopup(true)}>
              <h3 className="text-header me-2 mb-0 title-color font-400">View all Wallets</h3>
              <FontAwesomeIcon className="MockDetailsPopup-close__Icon" icon={faAngleRight} />
            </div>
          </div>
          <div className="content-social">
            <div className="d-flex align-items-center">
              <h3 className="SubTitle__Titles__Title title-color">Social media insights</h3>
              <div className="position-relative">
                <img
                  className="ps-1 mb-2"
                  onMouseEnter={() => setShowSocialInsight(true)}
                  onMouseLeave={() => setShowSocialInsight(false)}
                  src={InfoCircle}
                  alt=""
                />
                {showSocialInsight && <InsightsPopup closePopup={setShowSocialInsight} page={"social"} />}
              </div>
            </div>
            <div className="d-flex box-social">
              <div className="social-active">
                <div onClick={() => setShowPopup(true)} className="content-social-image">
                  <img src={tiwterIcon} alt="" />
                </div>
              </div>
              <div className="social-coming d-flex">
                <div
                  className="content-social-image position-relative"
                  onMouseLeave={() => setShowSocialPopup(false)}
                  onMouseEnter={() => showPopupComingSoon(1)}
                >
                  <img src={telegramIcon} alt="" />
                  {showSocialPopup && index === 1 && <InsightsPopup page={"social-coming"} />}
                </div>
                <div
                  className="content-social-image position-relative"
                  onMouseLeave={() => setShowSocialPopup(false)}
                  onMouseEnter={() => showPopupComingSoon(2)}
                >
                  <img src={gameIcon} alt="" />
                  {showSocialPopup && index === 2 && <InsightsPopup page={"social-coming"} />}
                </div>
                <div
                  className="content-social-image position-relative"
                  onMouseLeave={() => setShowSocialPopup(false)}
                  onMouseEnter={() => showPopupComingSoon(3)}
                >
                  <img src={tiktokIcon} alt="" />
                  {showSocialPopup && index === 3 && <InsightsPopup page={"social-coming"} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" action d-flex justify-content-between align-items-center">
        <div className="action__save">
          {props.favourite ? (
            <button className="btn-save" onClick={() => props.handleRemoveSavedCampaign(props.campaign.id)}>
              <img src={minusIcon} alt="" />
              <span>Remove</span>
            </button>
          ) : (
            <button className="btn-save" onClick={() => props.handleSaveCampaign(props.campaign)}>
              <img src={PlusCircle} alt="" />
              <span>Save</span>
            </button>
          )}
        </div>
        <div className="action__recommendation d-flex justify-content-between align-items-center">
          <p className="mb-0">Recommendation</p>
          <div className="btn btn-likeUp">
            <img src={likeUpIcon} alt="" />
          </div>
          <div className="btn btn-likeDown">
            <img src={likeDownIcon} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignItem;
