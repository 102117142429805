import { forwardRef, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  CategoryScale,
} from "chart.js";
import moment from "moment";
import BrandIcon from "src/components/icons/BrandIcon";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, TimeScale);

const CompetitorDetailGraph = forwardRef((props, ref) => {
  const [clickedTooltip, setClickedTooltip] = useState(null);
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.classList.add("Graph__Tooltip");

      const table = document.createElement("table");
      table.style.margin = "0px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);
    const content = document.createElement("div");

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);
      const headline = document.createElement("strong");

      titleLines.forEach((title) => {
        const date = moment(title).format("MMM DD, YYYY");
        const div = document.createElement("div");
        const text = document.createTextNode(date);
        div.appendChild(text);

        headline.appendChild(div);
      });

      const div = document.createElement("div");

      const price = Number(bodyLines[0][0].replace("ENGAGEMENT: ", "").replace(",", ".")).toFixed(2);
      // const cf = bodyLines[1][0].replace("Engagement: ", "");
      const cf = "";

      const link = document.createElement("div");
      link.classList.add("Graph__Tooltip__Footer");

      link.innerHTML = "<strong>Click <i class='Circle'></i> To Learn More</strong>";

      div.innerHTML =
        "<div>Engagement: <span class='color--lila'>" +
        price +
        "</span> <span class='px-2'>x</span> Followers: <span class='color--purple'>" +
        cf +
        "</span></div>";

      const tooltipRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tooltipRoot.firstChild) {
        tooltipRoot.firstChild.remove();
      }

      // Add new children
      tooltipRoot.appendChild(headline);
      tooltipRoot.appendChild(div);
      tooltipRoot.appendChild(content);
      tooltipRoot.appendChild(link);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";

    document.body.style.cursor = "pointer";
  };

  const createGradient = (ctx, area, label) => {
    const colorSchemes = {
      ENGAGEMENT: {
        start: "#FF71BB",
        mid: "#E10C7C",
        end: "#E10C7C",
      },
      FOLLOWERS: {
        start: "#C09FEF",
        mid: "#C09FEF",
        end: "#7950b6",
      },
    };

    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
    if(colorSchemes[label]) {
      gradient.addColorStop(0, colorSchemes[label].start);
      gradient.addColorStop(0.5, colorSchemes[label].mid);
      gradient.addColorStop(1, colorSchemes[label].end);
    }

    return gradient;
  };

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      ...props.data,
      datasets: props.data.datasets.map((dataset) => ({
        ...dataset,
        borderColor: createGradient(chart.ctx, chart.chartArea, dataset.label),
      })),
    };

    setChartData(chartData);
  }, [props.data]);

  const handleLangChange = (index) => {
    props.onSelectBubble(index);
  };

  const options = {
    color: "#fff",
    maintainAspectRatio: false,
    onClick: function (evt, element) {
      if (element.length > 0) {
        handleLangChange(element[0].index);
        setClickedTooltip(element[0].index);
      }
    },
    onHover: function (evt, element) {
      if(element[0] && element[0].index !== clickedTooltip) {
        setClickedTooltip(null);
      }
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: externalTooltipHandler,
      },
    },

    scales: {
      y: {
        id: "A",
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          color: "#fff",
          callback: function (value, index, values) {
            return value;
          },
        },
        font: {
          color: "#fff",
        },
        grid: {
          color: "#414141",
        },
      },
      y1: {
        id: "B",
        type: "linear",
        display: true,
        position: "right",
        ticks: {
          color: "#fff",

          callback: function (value, index, values) {
            return value;
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      xAxis: {
        type: "time",
        display: true,
        ticks: {
          callback: function (value, index, values) {
            switch (props.data.range) {
              case 30:
                return index % 7 === 0 ? value : "";
              default:
                return value;
            }
          },
          color: "white",
          maxRotation: 0,
          minRotation: 0,
        },
        time: {
          isoWeekday: true,
          unit: "day",
          displayFormats: {
            day: "MM/DD/Y",
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const chartRef = useRef(null);
  return (
    <>
      <span className="Graph__Label d-none d-sm-inline-block">
        <i>Followers</i>
      </span>
      <div className="col">
        <div className="Graph__Legends">
          <div className="Graph__Legends__Title">
            <BrandIcon id={props.data.id} name={props.data.name} height={16} />
            <strong>
              {props.data.name}
            </strong>
            <span>
              Followers x Engagement
            </span>
          </div>
          <div className="ml-auto">
            <span className="px-2 d-none d-sm-inline-block">Legend:</span>
            <span className="Graph__Legends__Legend"> Followers </span>
            <span className="Graph__Legends__Legend"> Engagement </span>
          </div>
        </div>
        <div className="h-100">
          {props.data.datasets && <Line className="Graph__Canva" data={chartData} options={options} ref={chartRef} />}
        </div>
      </div>
      <span className="Graph__Label d-none d-sm-inline-block">
        <i>Engagement</i>
      </span>
    </>
  );
});

export default CompetitorDetailGraph;
