import { ethers } from "ethers";

export const calculateMetaMaskCredentials = async () => {
  // @ts-ignore
  await window.ethereum.request({ method: "eth_requestAccounts" });

  const getSigner = () => {
    // @ts-ignore
    const we3provider = new ethers.providers.Web3Provider(window.ethereum!, "any");
    return we3provider.getSigner();
  };

  const getMessage = () => {
    const messageHash = ethers.utils.id("Logging in to system");
    return ethers.utils.arrayify(messageHash);
  };

  const signer = getSigner();
  const messageBytes = getMessage();
  const signedMessage = await signer.signMessage(messageBytes);
  const messageBase64 = Buffer.from(messageBytes).toString("base64");

  const metaMaskCredentials = {
    message: messageBase64,
    signature: signedMessage,
  };

  return metaMaskCredentials;
};
