import { makeAutoObservable } from "mobx";
import MarketingApi from "../api/MarketingApi";
import { RootStore } from "./RootStore";

export interface Web3StoreInterface {
  loadingWeb3Spaces: boolean;
  loadingWeb3SpacesIsErroneous: boolean;
  web3Spaces: Web3Space[];
  postWeb3SpaceIsErroneous: boolean;
  loadingTopNFTCollections: boolean;
  topNFTCollectionsIsErroneous: boolean;
  topNFTCollections: NFTCollection[];
  nftAnalysisDetail: NftDetailAnalysis;
  nftTransaction: nftActivity;
  avgHoldTime: avgHoldTime[];
  topWallet: TopWallet[];
  topWalletDetail: topWalletDetail[];
}

class Web3Store implements Web3StoreInterface {
  public loadingWeb3Spaces: boolean = false;
  public loadingWeb3SpacesIsErroneous: boolean = false;
  public loadingGetTopWallet: boolean = false;
  public loadingGetTopWalletIsErroneous: boolean = false;
  public uSD: number = 0;

  public web3Spaces: Web3Space[] = [];
  public topWallet: TopWallet[] = [];
  public topWalletDetail: topWalletDetail[] = [];
  public postWeb3SpaceIsErroneous: boolean = false;
  public loadingTopNFTCollections: boolean = false;
  public topNFTCollectionsIsErroneous: boolean = false;
  public topNFTCollections: NFTCollection[] = [];
  public nftAnalysisDetail: NftDetailAnalysis = {} as NftDetailAnalysis;
  public nftTransaction: nftActivity = {} as nftActivity;
  public avgHoldTime: avgHoldTime[] = [];

  public rootStore: RootStore | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async getTopWallet() {
    this.loadingGetTopWallet = true;
    this.loadingGetTopWalletIsErroneous = false;

    try {
      const response = await MarketingApi.getTopWallet();
      if (response.data.success === "true") {
        this.topWallet = response.data.encrypted;
        return this.topWallet;
      }
      return;
    } catch {
      this.loadingGetTopWalletIsErroneous = true;
      return;
    } finally {
      this.loadingGetTopWallet = false;
    }
  }

  public async getTopWalletDetail(wallet_address: string) {
    this.loadingGetTopWallet = true;
    this.loadingGetTopWalletIsErroneous = false;
    const payload = { wallet_address: wallet_address };
    try {
      const response = await MarketingApi.getTopWalletDetail(payload);
      if (response.data.success === "true") {
        this.topWalletDetail = response.data.encrypted;
        return this.topWalletDetail;
      }
      return;
    } catch {
      this.loadingGetTopWalletIsErroneous = true;
      return;
    } finally {
      this.loadingGetTopWallet = false;
    }
  }

  public async getUSD() {
    this.loadingGetTopWallet = true;
    this.loadingGetTopWalletIsErroneous = false;

    try {
      const response = await MarketingApi.getUSD();
      if (response.status === 200) {
        this.uSD = response.data["USD"];
        return this.uSD;
      }
      return;
    } catch {
      this.loadingGetTopWalletIsErroneous = true;
      return;
    } finally {
      this.loadingGetTopWallet = false;
    }
  }

  public async getWeb3Spaces() {
    if (this.web3Spaces.length > 0) {
      return this.web3Spaces;
    }

    this.loadingWeb3Spaces = true;
    this.loadingWeb3SpacesIsErroneous = false;

    try {
      const response = await MarketingApi.getWeb3Spaces();
      if (response.data.success === "true") {
        this.web3Spaces = response.data.spaces;
        return this.web3Spaces;
      }
      return;
    } catch {
      this.loadingWeb3SpacesIsErroneous = true;
      return;
    } finally {
      this.loadingWeb3Spaces = false;
    }
  }

  public async postWeb3Space(selectedWeb3Space: SelectedWeb3SpaceRequest) {
    this.postWeb3SpaceIsErroneous = false;

    try {
      await MarketingApi.postWeb3Spaces(selectedWeb3Space);
    } catch {
      this.postWeb3SpaceIsErroneous = true;
    }
  }

  public async fetchTopNFTCollections(competitors: CompetitorListRequest) {
    this.topNFTCollectionsIsErroneous = false;
    this.loadingTopNFTCollections = true;

    try {
      const response = await MarketingApi.fetchTopNFTCollections(competitors);
      if (response.data.success === "true") {
        this.topNFTCollections = response.data.topNftCollections;
      }
      return this.topNFTCollections;
    } catch {
      this.topNFTCollectionsIsErroneous = true;
      return this.topNFTCollections;
    } finally {
      this.loadingTopNFTCollections = false;
    }
  }

  public async getNFTDetailAnalysis(nftId: string) {
    const payload = { id: nftId };
    this.nftAnalysisDetail = {} as NftDetailAnalysis;
    try {
      const response = await MarketingApi.getNFTDetailAnalysis(payload);
      if (response.data.success === "true" && response.data.nftAnalysis[0]) {
        this.nftAnalysisDetail = response.data.nftAnalysis[0];
      }
      return this.nftAnalysisDetail;
    } catch {
      return this.nftAnalysisDetail;
    }
  }

  public async getAvgHoldTime(nftId: string) {
    const payload = { id: nftId };
    try {
      const response = await MarketingApi.getAvgHoldTime(payload);
      if (response.data.success === "true") {
        this.avgHoldTime = response.data.topNftCollections.transactions;
      }
      return this.avgHoldTime;
    } catch {
      return this.avgHoldTime;
    }
  }

  public async getNFTTransactionCount(param: any) {
    try {
      const response = await MarketingApi.getNFTTransactionCount(param);
      if (response.data.success === "true") {
        this.nftTransaction = response.data.transactionCount;
      }
      return this.nftTransaction;
    } catch {
      return this.nftTransaction;
    }
  }

  public toggleWeb3SpaceSelection(web3SpaceId: number) {
    const newArr = this.web3Spaces.map((el) => {
      if (el.id !== web3SpaceId) {
        return {
          ...el,
          selected: false,
        };
      } else {
        return {
          ...el,
          selected: !el.selected,
        };
      }
    });

    this.web3Spaces = newArr;

    return this.web3Spaces;
  }
}

export default Web3Store;
