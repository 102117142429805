import Title from "../../components/headlines/Title";
import SubTitle from "../../components/headlines/SubTitle";
import { Button } from "react-bootstrap";
import { useStores } from "../../store/RootStore";

const Profile = () => {
  const { companyStore } = useStores();

  const postCompanyForm = async () => {
    companyStore.companyName = "";
    companyStore.companyWebsite = "";
    companyStore.companyTwitter = "";
    companyStore.selectedIndustry = 0;
    companyStore.selectedIndustryValue = "";
    companyStore.selectedEmployees = 0;
    companyStore.selectedEmployeesValue = "";
    companyStore.selectedRevenue = 0;
    companyStore.selectedRevenueValue = "";

    await companyStore.postCompanyForm();
  };

  return (
    <div>
      <Title title="Profile" />
      <div className="Container">
        <SubTitle title="Reset profile (for development only)"></SubTitle>
        <div className="row">
          <div className="col-6">
            <Button className="Button Button--primary w-100" onClick={() => postCompanyForm()}>
              Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
