import { makeAutoObservable } from "mobx";
import MarketingApi from "../api/MarketingApi";
import { RootStore } from "./RootStore";

export interface RecommendationStoreInterface {
  savedCampaign: Campaign[];
  relevantWallet: relevantWallet[];
}

class RecommendCampaignStore implements RecommendationStoreInterface {
  public savedCampaign: Campaign[] = [];
  public relevantWallet: relevantWallet[] = [];
  public rootStore: RootStore | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async getSavedCampaign() {
    try {
      const response = await MarketingApi.getSavedCampaign();
      if (response.data.success === "true") {
        this.savedCampaign = response.data.encrypted;
      }
      return this.savedCampaign;
    } catch {
      console.log("err");
      return;
    }
  }

  public async saveCampaign(campaign: Campaign) {
    try {
      const response = await MarketingApi.saveCampaign(campaign);
      if (response.data.success === "true") {
        const index = this.savedCampaign.findIndex((item) => item.id === campaign.id);
        if (index === -1) {
          this.savedCampaign.push(campaign);
        }
      }
      return this.savedCampaign;
    } catch {
      console.log("err");
      return;
    }
  }

  public async removeSavedCampaign(campaign_id: string) {
    const payload = { campaign_id: campaign_id };
    try {
      const response = await MarketingApi.removeSavedCampaign(payload);
      if (response.data.success === "true") {
        this.savedCampaign = this.savedCampaign.filter((campaign) => campaign.id !== campaign_id);
      }
      return this.savedCampaign;
    } catch {
      console.log("err");
      return;
    }
  }

  public async getRelevantWallets(wallet_address: string) {
    this.relevantWallet = [];
    const payload = { address: wallet_address };
    try {
      const response = await MarketingApi.getRelevantWallets(payload);
      if (response.data.success === "true" && response.data.encrypted) {
        const wallet = {
          address: wallet_address,
          ...response.data.encrypted[0],
        };
        this.relevantWallet.push(wallet);
      }
      return this.relevantWallet;
    } catch {
      console.log("err");
      return;
    }
  }
}

export default RecommendCampaignStore;
