export const implementEasternStandardTime = (hours: number[]) => {
  for (let i = 0; i < 5; i++) {
    let first = hours.shift()!;
    hours.push(first);
  }
};

export const implementEasternDaylightTime = (hours: number[]) => {
  for (let i = 0; i < 4; i++) {
    let first = hours.shift()!;
    hours.push(first);
  }
};
