import React from "react";
import "chartjs-adapter-moment";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, TimeScale, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, TimeScale);

const DayOfTheMonthGraph = (props) => {
  const graphData = props.data;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        display: true,
      },
      yAxis: {
        ticks: {
          display: false,
        },
      },
    },
  };

  function argMax(array) {
    if(array !== undefined && array.length > 0) {
      return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
    }
  }

  let color = graphData?.map((x) => "#fff");
  if(graphData !== undefined) {
    color[argMax(graphData)] = "#e10c7c";
  }
  const data = {
  labels: props.labels,
    datasets: [
      {
        data: graphData,
        backgroundColor: color,
        borderRadius: 2,
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

export default DayOfTheMonthGraph;
