import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentFormatGraph from "src/pages/insights/components/ContentFormatGraph";
import DayOfTheWeekGraph from "src/pages/insights/components/DayOfTheWeekGraph";
import TimeOfTheDayGraph from "src/pages/insights/components/TimeOfTheDayGraph";
import SubTitle from "src/components/headlines/SubTitle";

import Title from "../../components/headlines/Title";
import { useStores } from "../../store/RootStore";
import { useEffect, useState } from "react";
import { hours } from "../../util/Hours";
import { weekDays } from "../../util/Weekdays";
import Loading from "../../components/progress/Loading";
import { dayLightSavingsPeriod } from "../../util/DayLightSavingsPeriod";
import { implementEasternDaylightTime, implementEasternStandardTime } from "../../util/TimeZoneCalculator";
import PlatformSelection from "../../components/PlatformSelection";

const Insights = () => {
  const { recommendationStore, competitorStore } = useStores();
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [isLoadingTime, setIsLoadingTime] = useState(true);
  const [tweetHours, setTweetHours] = useState([0] as number[]);
  const [tweetWeekDays, setTweetWeekDays] = useState([0] as number[]);
  const [photoPercentage, setPhotoPercentage] = useState(0);
  const [videoPercentage, setVideoPercentage] = useState(0);
  const [gifPercentage, setGifPercentage] = useState(0);

  useEffect(() => {
    const getCompetitorInsights = async (competitors: number[] | undefined) => {
      const competitorInsights = await recommendationStore.getCompetitorRecommendations(competitors);
      if (competitorInsights !== undefined) {
        setCompetitorInsights(competitorInsights);
      }
    };

    const fetchData = async () => {
      const competitors = await competitorStore.getUserCompetitors();
      await getCompetitorInsights(competitors);
    };

    fetchData().then();
  }, [competitorStore, recommendationStore]);

  const setCompetitorInsights = (competitorRecommendations: CompetitorRecommendations) => {
    setTimeMetrics(competitorRecommendations);
    setMediaMetrics(competitorRecommendations);
  };

  const setMediaMetrics = (competitorRecommendations: CompetitorRecommendations) => {
    const photos = competitorRecommendations.mediaTypes.filter((mediaType) => mediaType.type === "photo");
    const videos = competitorRecommendations.mediaTypes.filter((mediaType) => mediaType.type === "video");
    const gifs = competitorRecommendations.mediaTypes.filter((mediaType) => mediaType.type === "animated_gif");

    setPhotoPercentage(Math.round(photos[0].percentage));
    setVideoPercentage(Math.round(videos[0].percentage));
    setGifPercentage(Math.round(gifs[0].percentage));
    setIsLoadingContent(false);
  };

  const setTimeMetrics = (competitorRecommendations: CompetitorRecommendations) => {
    let tweetHours: number[] = [];
    const tweetWeekDays: number[] = [];

    competitorRecommendations.tweetHours.map((tweetHour) => tweetHours.push(Number(tweetHour.weight)));
    competitorRecommendations.tweetWeekDays.map((tweetWeekDay) => tweetWeekDays.push(Number(tweetWeekDay.weight)));

    const normalizedTweetWeekDays = normalizeTweetWeekDays(tweetWeekDays);
    tweetHours = convertToESTTimeZone(tweetHours);

    setTweetHours(tweetHours);
    setTweetWeekDays(normalizedTweetWeekDays);
    setIsLoadingTime(false);
  };

  const convertToESTTimeZone = (tweetHours: number[]) => {
    dayLightSavingsPeriod() ? implementEasternDaylightTime(tweetHours) : implementEasternStandardTime(tweetHours);
    return tweetHours;
  };

  const normalizeTweetWeekDays = (tweetWeekDays: number[]) => {
    const [sunday, ...restOfTheWeek] = tweetWeekDays;
    return [...restOfTheWeek, sunday];
  };

  const graphData = tweetHours.flatMap((instance) => instance.valueOf());
  const graphDataDayOfTheWeek = tweetWeekDays.flatMap((instance) => instance.valueOf());

  const calculateBestHour = (weight: number) => {
    const bestHourIndex = tweetHours.indexOf(weight);
    return hours[bestHourIndex];
  };

  const calculateBestDays = (tweetWeekDays: number[]) => {
    const weekDaysToBeSorted = [...tweetWeekDays];
    const sortedWeekDays = weekDaysToBeSorted.sort((a, b) => a - b).reverse();
    return `${weekDays[tweetWeekDays.indexOf(sortedWeekDays[0])]}, ${
      weekDays[tweetWeekDays.indexOf(sortedWeekDays[1])]
    } & ${weekDays[tweetWeekDays.indexOf(sortedWeekDays[2])]}`;
  };

  const maxWeight = graphData.reduce((bestHour, value) => (value > bestHour ? value : bestHour), 0);
  // @ts-ignore
  const hoursToBeSorted = [...graphData];
  const sortedHours = hoursToBeSorted.sort((a, b) => a - b).reverse();
  const bestHour = calculateBestHour(maxWeight);
  const secondBestHour = calculateBestHour(sortedHours[1]);
  const thirdBestHour = calculateBestHour(sortedHours[2]);
  const bestDays = calculateBestDays(tweetWeekDays);

  function getTimeToPost() {
    return (
      <>
        {!isLoadingTime ? (
          <>
            <div className="Card">
              <div className="Card__Content Card__Content--pa">
                <div className="Card__Title d-flex justify-content-between">
                  <span>Highest engagement time frames</span>
                  <div className="Card__Title__Actions">
                    <label className="Card__Title__Actions__Label" htmlFor="123">
                      Sort by Time Zone:
                    </label>
                    <select className="Input Input--clean">
                      <option>Eastern Standard Time (EST)</option>
                    </select>
                  </div>
                </div>

                <div className="Card__Title">
                  <span className="color--purple">
                    <FontAwesomeIcon icon={faBolt} /> {bestHour} (EST)&nbsp;
                  </span>
                  <small>
                    This looks like the best time to maximize your reach. You can also try <b>{secondBestHour}</b> or{" "}
                    <b>{thirdBestHour}</b>.
                  </small>
                </div>

                <div className="InsightsGraphWrapper">
                  <TimeOfTheDayGraph data={graphData} />
                </div>
              </div>
            </div>

            <div className="Card mt-5">
              <div className="Card__Content Card__Content--pa">
                <div className="Card__Title d-flex justify-content-between">
                  <span>Highest engagement days</span>
                </div>

                <div className="Card__Title">
                  <span className="color--purple">
                    <FontAwesomeIcon icon={faBolt} /> {bestDays}&nbsp;
                  </span>
                  <small>These look like the best days for your brand to maximize its social performance.</small>
                </div>

                <div className="InsightsGraphWrapper">
                  <DayOfTheWeekGraph data={graphDataDayOfTheWeek} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }

  function getContentStrategy() {
    return (
      <>
        {!isLoadingContent ? (
          <div className="Card">
            <div className="Card__Content Card__Content--pa">
              <div className="Card__Title d-flex justify-content-between">
                <span>Format engagement percentage</span>
              </div>

              <div className="Card__Title">
                <small className="font-smaller">
                  This breakdown has shown the highest percentage of engagement between formats
                </small>
              </div>

              <div className="row">
                <div className="col-12 col-xl-4">
                  <ContentFormatGraph percentage={photoPercentage} label="Images" />
                </div>

                <div className="col-12 col-xl-4">
                  <ContentFormatGraph percentage={videoPercentage} label="Videos" />
                </div>

                <div className="col-12 col-xl-4">
                  <ContentFormatGraph percentage={gifPercentage} label="Animated gifs" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </>
    );
  }

  return (
    <>
      <Title title="Intelligence" >
        <PlatformSelection/>
      </Title>
      <div className="Container">
        <div className="row">
          <div className="col-12 col-xl-7">
            <SubTitle title="Engagement time"></SubTitle>
            {getTimeToPost()}
          </div>

          <div className="col-12 col-xl-5">
            <SubTitle title="Content Strategy"></SubTitle>
            {getContentStrategy()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Insights;
