import axios from "axios";
import { stringify } from "querystring";
import { AxiosRequestConfig } from "axios";

export const BASE_URL = "https://marketing-api-prod.onrender.com/";

export const BASE_URL_AI = "http://ojamu-dev.evvolabs.com/ai/";

export const API_CONF: AxiosRequestConfig = {
  baseURL: BASE_URL,
  timeout: 15000,
  headers: {},
  paramsSerializer: (params) => stringify(params),
};

export const API_CONF_AI: AxiosRequestConfig = {
  baseURL: BASE_URL_AI,
  timeout: 15000,
  headers: {},
  paramsSerializer: (params) => stringify(params),
};

export const API_AI = axios.create(API_CONF_AI);
export const API = axios.create(API_CONF);
