import { changeContractWallet, transformToImage, formatUSD } from "../../util/TransformWallet";
import transForm from "../../assets/TransformImage";
import { useStores } from "../../store/RootStore";
import { useEffect, useState } from "react";
import { toJS } from "mobx";

const WalletCard = (props: any) => {
  const { web3Store } = useStores();
  const [walletDetail, setWalletDetail] = useState([] as topWalletDetail[]);

  useEffect(() => {
    const getTopWalletDetail = async (wallet_address: string) => {
      const response: any = await web3Store.getTopWalletDetail(wallet_address);
      setWalletDetail(toJS(response));
    };

    const fetchData = async () => {
      await getTopWalletDetail(props.wallet.address);
    };

    fetchData().then();
  }, []);

  useEffect(() => {
    setWalletDetail(web3Store.topWalletDetail);
  }, [walletDetail]);

  const populateTopAssets = (wallet: any) => {
    return wallet?.map((topAsset: any, index: number) => {
      return (
        <div key={index} className="Card__Wallet__Asset">
          <div className="Card__ImageContainer Card__ImageContainer__Wallet Card__ImageContainer__Wallet__Asset">
            <img className="Card__ImageContainer__Image" src={topAsset?.image_url || topAsset.assetsImage} alt={""} />
          </div>
          <div className="Card__Wallet__Asset__Details">
            <div className="Card__Wallet__Asset__Details__Title">{topAsset?.name || topAsset.assetsName}</div>
            <div className="Card__Wallet__Asset__Details__Value">{topAsset?.price || topAsset.assetsPrice} ETH</div>
          </div>
        </div>
      );
    });
  };
  const handleShowViewWallet = () => {
    if (props.setShowWalletPopup) {
      props.setShowWalletPopup(false);
    }
    props.setShowWalletModal(true);
    const data = { ...props.wallet, usd: props.usd, topAssets: walletDetail };
    props.setWalletData(data);
  };

  useEffect(() => {
    transForm();
    const image2Arr = Array.from(document.querySelectorAll(`.image${props.wallet.address}`));
    if (image2Arr) {
      image2Arr.forEach((selector) => {
        transformToImage(selector, props.wallet.address);
      });
    }
  }, []);

  return (
    <>
      {props.address ? (
        <div className={props.class} key={props.index}>
          <div className="Card Card__Wallet">
            <div className="Card__Number">0{props.index + 1}</div>
            <div className="d-flex">
              <div className="Card__ImageContainer Card__ImageContainer__Wallet">
                <img className={`Card__ImageContainer__Image image${props.wallet?.address}`} alt={""} />
              </div>

              <div className="Card__Wallet__Header">
                <div className="Card__Wallet__Header__Title">
                  <h3>{changeContractWallet(props.wallet?.address)}</h3>
                  <div onClick={handleShowViewWallet} className="cursor-pointer">
                    <a className="Card__Link" href="/dashboard" style={{ pointerEvents: "none" }}>
                      View Wallet Analysis
                    </a>
                  </div>
                </div>

                <div className="Card__Wallet__Header__Details">
                  <div>
                    <span>Assets Held:</span>
                    <strong>{props.wallet?.assetsHeld}</strong>
                  </div>

                  <div>
                    <span>Wallet Value:</span>
                    <span>
                      <span style={{ color: "white", fontSize: 15 }}>
                        <strong>${Number(props.wallet?.walletValues).toLocaleString("en")} USD</strong>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="Card__Wallet__Assets">
              <div className="Card__Wallet__Assets__Header">
                <span>Top Assets Held</span>
                <div className="Card__Wallet__Assets__Header__Border" />
              </div>

              <div className="Card__Wallet__Assets__Content">{populateTopAssets(props.wallet.topAssetsHeld)}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className={props.class} key={props.index}>
          <div className="Card Card__Wallet">
            <div className="Card__Number">0{props.index + 1}</div>
            <div className="d-flex">
              <div className="Card__ImageContainer Card__ImageContainer__Wallet">
                <img className={`Card__ImageContainer__Image image${props.wallet.address}`} alt={""} />
              </div>

              <div className="Card__Wallet__Header">
                <div className="Card__Wallet__Header__Title">
                  <h3>{changeContractWallet(props.wallet.address)}</h3>
                  <div onClick={handleShowViewWallet} className="cursor-pointer">
                    <a className="Card__Link" href="/dashboard" style={{ pointerEvents: "none" }}>
                      View Wallet Analysis
                    </a>
                  </div>
                </div>

                <div className="Card__Wallet__Header__Details">
                  <div>
                    <span>Assets Held:</span>
                    <strong>{props.wallet.asset_held}</strong>
                  </div>

                  <div>
                    <span>Wallet Value:</span>
                    <span>
                      <span style={{ color: "white", fontSize: 15 }}>
                        <strong>{formatUSD((props.wallet.wallet_value / 10e18) * props.usd)}</strong>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="Card__Wallet__Assets">
              <div className="Card__Wallet__Assets__Header">
                <span>Top Assets Held</span>
                <div className="Card__Wallet__Assets__Header__Border" />
              </div>

              <div className="Card__Wallet__Assets__Content">{populateTopAssets(props.topAssets)}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WalletCard;
