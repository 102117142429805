import { useEffect } from "react";

import checkCircleIcon from "../../../images/checkCircleBlack.svg";

const InsightsPopup = (props: any) => {
  useEffect(() => {
    let popup;
    if (document.querySelector(".insights-popup")) {
      popup = document.querySelector(".insights-popup");
    } else {
      popup = document.querySelector(".comingsoon-popup");
    }
    const heightPopup = (popup as HTMLElement).offsetHeight;
    (popup as HTMLElement).style.top = -(heightPopup - 3) + "px";
  }, []);

  // useEffect(() => {
  //   const handleClickOutside = (event: any) => {
  //     if (insightRef.current && !(insightRef.current as any).contains(event.target)) {
  //       props.closePopup(false);
  //     }
  //   };
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [insightRef]);

  return (
    <>
      {props.page === "social-coming" ? (
        <div className="position-absolute comingsoon-popup">
          <div className="comingsoon d-flex align-items-center justify-content-center">
            <h3 className="m-0">Coming soon...</h3>
          </div>
          <div className="d-flex justify-content-center">
            <div className="arrow-down"></div>
          </div>
        </div>
      ) : (
        <div
          onMouseLeave={() => props.closePopup(false)}
          onMouseEnter={() => props.closePopup(true)}
          className="position-absolute insights-popup"
        >
          <div className="insights">
            <h3 className="insights__title">AI insights:</h3>

            <div className="insights__content">
              {props.insights ? (
                props.insights.map((insight: any, index: number) => (
                  <div
                    key={index}
                    className={`d-flex align-items-center ${index !== props.insights.length - 1 ? "mb-4" : ""}`}
                  >
                    <img src={checkCircleIcon} alt="" />
                    {insight.content ? (
                      <a href={insight.link} target="_blank" rel="noreferrer" className="ms-3">
                        {insight.content}
                      </a>
                    ) : (
                      <p className="ms-3">{insight}</p>
                    )}
                  </div>
                ))
              ) : (
                <>
                  <div className="d-flex align-items-center mb-4">
                    <img src={checkCircleIcon} alt="" />
                    <p className="ms-3 ">[Content line 1]</p>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <img src={checkCircleIcon} alt="" />
                    <p className="ms-3">[Content line 1]</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <img src={checkCircleIcon} alt="" />
                    <p className="ms-3">[Content line 1]</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="arrow-down"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default InsightsPopup;
