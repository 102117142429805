import { makeAutoObservable } from "mobx";
import MarketingApi from "../api/MarketingApi";
import { RootStore } from "./RootStore";

export interface CompetitorStoreInterface {
  getIndustryCompetitorsIsErroneous: boolean;
  industryCompetitors: IndustryCompetitor[];
  selectedCompetitors: number[];
  selectedCompetitorsByRow: CompetitorRow[];
  setIndustryCompetitorsIsErroneous: boolean;
  updatedExistingCompetitorsSet: boolean;
  addedNewCompetitor: boolean;
  userCompetitors: number[];
  userCompetitorsIsErroneous: boolean;
}

class CompetitorStore implements CompetitorStoreInterface {
  public getIndustryCompetitorsIsErroneous: boolean = false;
  public industryCompetitors: IndustryCompetitor[] = [];
  public selectedCompetitors: number[] = [];
  public setIndustryCompetitorsIsErroneous: boolean = false;
  public selectedCompetitorsByRow: CompetitorRow[] = [];
  public updatedExistingCompetitorsSet: boolean = false;
  public addedNewCompetitor: boolean = false;
  public userCompetitors: number[] = [];
  public userCompetitorsIsErroneous: boolean = false;
  public rootStore: RootStore | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async getIndustryCompetitors(industryId: number) {
    this.getIndustryCompetitorsIsErroneous = false;

    try {
      const response = await MarketingApi.getIndustryCompetitors(industryId);
      this.industryCompetitors = response.data.competitors;
      return this.industryCompetitors;
    } catch {
      this.getIndustryCompetitorsIsErroneous = true;
      return;
    }
  }

  public async setIndustryCompetitors() {
    this.setIndustryCompetitorsIsErroneous = false;
    const competitorsList: CompetitorListRequest = { competitors: this.industryCompetitors.filter((ic) => ic.selected).map(sc => sc.id) };

    try {
      await MarketingApi.setIndustryCompetitors(competitorsList);
    } catch {
      this.setIndustryCompetitorsIsErroneous = true;
    }
  }

  public async getUserCompetitors() {
    this.userCompetitorsIsErroneous = false;
    this.userCompetitors = [];

    try {
      const response = await MarketingApi.getUserCompetitors();
      this.composeUserCompetitorsList(response.data.competitors);
      return this.userCompetitors;
    } catch {
      this.userCompetitorsIsErroneous = true;
      return;
    }
  }

  public setCompetitor(index: number, selection: IndustryCompetitor) {
    this.updatedExistingCompetitorsSet = false;
    this.addedNewCompetitor = false;
    const competitorId = selection.id;
    const existingRows = this.selectedCompetitorsByRow;
    if (existingRows.length > 0) {
      this.addCompetitorToAnInitializedCompetitorsList(existingRows, index, competitorId);
    } else {
      this.addNewCompetitor(index, competitorId);
    }
    this.aggregateSelectedCompetitors();
  }

  public toggleCompetitorSelection(competitorId: number) {
    const newArr = this.industryCompetitors.map(el => {
      if (el.id !== competitorId) return el;

      return {
        ...el,
        selected: !el.selected
      }
    });

    this.industryCompetitors = newArr;

    return this.industryCompetitors;
  }

  private addNewCompetitor(index: number, competitorId: number) {
    const competitorRow: CompetitorRow = {
      rowIndex: index,
      competitorId: competitorId,
    };
    this.selectedCompetitorsByRow.push(competitorRow);
  }

  private addCompetitorToAnInitializedCompetitorsList(
    existingRows: CompetitorRow[],
    index: number,
    competitorId: number
  ) {
    this.addedNewCompetitor = false;
    this.modifyExistingCompetitorRow(existingRows, index, competitorId);
    this.addNewCompetitorRow(index, competitorId);
  }

  private addNewCompetitorRow(index: number, competitorId: number) {
    if (!this.updatedExistingCompetitorsSet) {
      const competitorRow: CompetitorRow = {
        rowIndex: index,
        competitorId: competitorId,
      };
      this.selectedCompetitorsByRow.push(competitorRow);
      this.addedNewCompetitor = true;
    }
  }

  private modifyExistingCompetitorRow(existingRows: CompetitorRow[], index: number, competitorId: number) {
    for (let row = 0; row < existingRows.length; row++) {
      const collectedCompetitor = existingRows[row];
      const collectedCompetitorRowIndex = collectedCompetitor.rowIndex;
      if (collectedCompetitorRowIndex === index) {
        this.selectedCompetitorsByRow.splice(row, 1);
        const competitorRow: CompetitorRow = {
          rowIndex: index,
          competitorId: competitorId,
        };
        this.selectedCompetitorsByRow.push(competitorRow);
        this.updatedExistingCompetitorsSet = true;
      }
    }
  }

  public aggregateSelectedCompetitors() {
    const existingRows = this.selectedCompetitorsByRow;
    for (let row = 0; row < existingRows.length; row++) {
      const collectedCompetitor = existingRows[row];
      const collectedCompetitorId = collectedCompetitor.competitorId;
      const competitorNotListed = !this.selectedCompetitors.includes(collectedCompetitorId);
      if (competitorNotListed) {
        this.selectedCompetitors.push(collectedCompetitorId);
      }
    }
  }

  public removeCompetitor(competitor: IndustryCompetitor) {
    const competitorToBeRemoved = this.selectedCompetitors.indexOf(competitor.id);
    this.selectedCompetitors.splice(competitorToBeRemoved, 1);
  }

  private composeUserCompetitorsList(response: UserCompetitorsResponseUnit[]) {
    response.map((userCompetitor: UserCompetitorsResponseUnit) => this.userCompetitors.push(userCompetitor.id));
  }
}

export default CompetitorStore;
