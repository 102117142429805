export const transformToImage = (element: any, address: string) => {
  var blockiesData = (window as any).blockies
    .create({
      seed: address.toLowerCase(),
      size: 8,
      scale: 16,
    })
    .toDataURL();
  element.setAttribute("src", blockiesData);
};

export const formatUSD = (amount: number) => {
  return '$' + parseFloat(amount.toString()).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' USD';
};

export const changeContractWallet = (address: string) => {
  const start = address?.slice(0, 6);
  const end = address?.slice(-4);
  return start + "..." + end;
};
