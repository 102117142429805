import { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import SubTitle from "../../../components/headlines/SubTitle";
import { useStores } from "../../../store/RootStore";
import Loading from "../../../components/progress/Loading";

const CompetitorAdditionalMetrics = (props: any) => {
  const { recommendationStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [hashtags, setHashtags] = useState([] as TopHashtag[]);
  const [retweets, setRetweets] = useState([] as TopRetweetedTweet[]);

  const populateHashtags = () => {
    return (
      <>
        {!isLoading ? (
          <>
            {hashtags.map((tag: TopHashtag, index) => (
              <span className="Tag" key={index}>
                  #{tag.tag}
              </span>
            ))}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  };

  const populateRetweets = () => {
    return (
      <>
        {!isLoading ? (
          <>
            {retweets.map((topRetweet: TopRetweetedTweet, index) => (
              <li key={index}>
                <p>
                  <a href={topRetweet.twitter_link} target="_blank" rel="noopener noreferrer">
                    {topRetweet.text.substring(0, 30) + "..."}
                  </a>{" "}
                  {topRetweet.retweet_count}
                </p>
              </li>
            ))}
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const competitorMetrics = await recommendationStore.getCompetitorRecommendations(props.competitorId);
      const hashtags = competitorMetrics!.topHashtags;
      const retweets = competitorMetrics!.topRetweetedTweets;
      setHashtags(hashtags);
      setRetweets(retweets);
      setIsLoading(false);
    };

    fetchData().then();
  }, [props.competitorId, recommendationStore]);

  return (
    <div>
        <div className="row pb-5">
          <div className="col-5">
            <div className="Content__Container mb-4">
              <SubTitle title="Popular Hashtags"></SubTitle>
              <div className="Container p-4 pb-3">
                <div className="d-flex">
                  {populateHashtags()}
                </div>
              </div>
            </div>

          </div>
          <Col md={6} className="mb-5">
            <SubTitle title="Top Retweeted" />
            <ol>{populateRetweets()}</ol>
          </Col>
        </div>
    </div>
  );
};

export default CompetitorAdditionalMetrics;
