import { makeAutoObservable } from "mobx";
import MarketingApi from "../api/MarketingApi";
import { RootStore } from "./RootStore";

export interface RecommendationStoreInterface {
  topTweets: TopTweet[];
  topCompetitors: TopCompetitor[];
  topRetweetedTweets: TopRetweetedTweet[];
  topHashtags: TopHashtag[];
  tweetHours: TweetHour[];
  tweetWeekDays: TweetWeekDay[];
  mediaTypes: MediaType[];
  competitorRecommendations: CompetitorRecommendations;
  recommendationIsErroneous: boolean;
  tweetsWithHashTagsPercentage: number;
  tweetsWithUrlsPercentage: number;
  tweetsWithPollsPercentage: number;
}

class RecommendationStore implements RecommendationStoreInterface {
  public topTweets: TopTweet[] = [];
  public topCompetitors: TopCompetitor[] = [];
  public topRetweetedTweets: TopRetweetedTweet[] = [];
  public topHashtags: TopHashtag[] = [];
  public tweetHours: TweetHour[] = [];
  public tweetWeekDays: TweetWeekDay[] = [];
  public mediaTypes: MediaType[] = [];
  public competitorRecommendations: CompetitorRecommendations = {
    topCompetitors: [],
    topTweets: [],
    topRetweetedTweets: [],
    topHashtags: [],
    tweetHours: [],
    tweetWeekDays: [],
    mediaTypes: [],
  };
  public tweetsWithHashTagsPercentage: number = 0;
  public tweetsWithUrlsPercentage: number = 0;
  public tweetsWithPollsPercentage: number = 0;
  public recommendationIsErroneous: boolean = false;
  public rootStore: RootStore | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public async getCompetitorRecommendations(competitorsList: number[] | undefined) {
    this.recommendationIsErroneous = false;

    const competitors: CompetitorRecommendationRequest = {
      competitors: competitorsList,
    };

    try {
      const response = await MarketingApi.getCompetitorRecommendations(competitors);
      if (response.data.success === "true") {
        this.topCompetitors = response.data.recommendation.topCompetitors;
        this.topTweets = response.data.recommendation.topTweets;
        this.topRetweetedTweets = response.data.recommendation.topRetweetedTweets;
        this.topHashtags = response.data.recommendation.topHashtags;
        this.tweetHours = response.data.recommendation.tweetHours;
        this.tweetWeekDays = response.data.recommendation.tweetWeekDays;
        this.mediaTypes = response.data.recommendation.mediaTypes;
        this.tweetsWithHashTagsPercentage = response.data.recommendation.tweetsWithHashTagsPercentage;
        this.tweetsWithUrlsPercentage = response.data.recommendation.tweetsWithUrlsPercentage;
        this.tweetsWithPollsPercentage = response.data.recommendation.tweetsWithPollsPercentage;
        this.competitorRecommendations = {
          topCompetitors: this.topCompetitors,
          topTweets: this.topTweets,
          topRetweetedTweets: this.topRetweetedTweets,
          topHashtags: this.topHashtags,
          tweetHours: this.tweetHours,
          tweetWeekDays: this.tweetWeekDays,
          mediaTypes: this.mediaTypes,
        };
        return this.competitorRecommendations;
      }
      return;
    } catch {
      this.recommendationIsErroneous = true;
      return;
    }
  }
}

export default RecommendationStore;
