import discord from "./discord-icon.svg";
import twitter from "./twitter-icon.svg";
import telegram from "./telegram-icon.svg";
import tiktok from "./tiktok-icon.svg";
import youtube from "./youtube-icon.svg";
import instagram from "./instagram-icon.svg";

const CryptoIcon = (props: any) => {
  const iconMap = {
    twitter,
    telegram,
    discord,
    tiktok,
    youtube,
    instagram
  };
  return <img className="SocialmediaIcon" src={iconMap[props.id]} alt={props.name} />;
};

export default CryptoIcon;
