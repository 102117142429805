import React from "react";
import LoginStore from "./LoginStore";
import CompanyStore from "./CompanyStore";
import CompetitorStore from "./CompetitorStore";
import GraphStore from "./GraphStore";
import RecommendationStore from "./RecommendationStore";
import RecommendCampaignStore from "./RecommendCampaignStore";
import Web3Store from "./Web3Store";

export class RootStore {
  public loginStore: LoginStore;
  public companyStore: CompanyStore;
  public competitorStore: CompetitorStore;
  public graphStore: GraphStore;
  public recommendationStore: RecommendationStore;
  public recommendCampaignStore: RecommendCampaignStore;
  public web3Store: Web3Store;

  constructor() {
    this.loginStore = new LoginStore(this);
    this.companyStore = new CompanyStore(this);
    this.competitorStore = new CompetitorStore(this);
    this.graphStore = new GraphStore(this);
    this.recommendationStore = new RecommendationStore(this);
    this.recommendCampaignStore = new RecommendCampaignStore(this);
    this.web3Store = new Web3Store(this);
  }
}

const StoresContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoresContext);

export default RootStore;
