import Title from "../../components/headlines/Title";
import SubTitle from "../../components/headlines/SubTitle";
import { useStores } from "../../store/RootStore";
import { useEffect, useState } from "react";
import Loading from "../../components/progress/Loading";
import ResultsCard from "./ResultsCard";
import NftCard from "./NftCard";
import WalletCard from "./WalletCard";
import PlatformSelection from "../../components/PlatformSelection";
import NFTModal from "../../components/Modal/NFTModal";
import WalletModal from "../../components/Modal/WalletResultModal";

const ResultsContent = () => {
  const { web3Store, competitorStore, recommendationStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [showNFTModal, setShowNFTModal] = useState(false);
  const [NFTData, setNFTData] = useState({} as NFTCollection);
  const [walletData, setWalletData] = useState({});
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [topNftCollections, setTopNftCollections] = useState<NFTCollection[]>([]);
  const [topTweets, setTopTweets] = useState([] as TopTweet[]);
  const [topWallets, setTopWallets] = useState([] as TopWallet[]);
  const topNFTCollectionsIsErroneous = !web3Store.topNFTCollectionsIsErroneous;
  const competitorRecommendationsNotErroneous = !recommendationStore.recommendationIsErroneous;

  useEffect(() => {
    const getTopNftCollections = async (competitors: number[] | undefined) => {
      const payload: CompetitorListRequest = {
        competitors: competitors,
      };
      const response = await web3Store.fetchTopNFTCollections(payload);

      const limitedTopNftCollections: NFTCollection[] = [];

      for (let i = 0; i < 3; i++) {
        limitedTopNftCollections.push(response[i]);
      }

      setTopNftCollections(limitedTopNftCollections);
    };

    const getCompetitorRecommendations = async (competitors: number[] | undefined) => {
      const competitorRecommendations = await recommendationStore.getCompetitorRecommendations(competitors);
      if (competitorRecommendations !== undefined) {
        setCompetitorRecommendations(competitorRecommendations);
      }
    };

    const getTopWallet = async () => {
      const data = await web3Store.getTopWallet();
      if (data !== undefined) {
        setTopWallets(data);
      }
    };

    const getUSD = async () => {
      if (web3Store.uSD === 0) {
        await web3Store.getUSD();
      }
    };

    const fetchData = async () => {
      getUSD();
      getTopWallet();
      const competitors = await competitorStore.getUserCompetitors();
      await getTopNftCollections(competitors);
      await getCompetitorRecommendations(competitors);
    };

    fetchData().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3Store]);

  useEffect(() => {
    const container = document.querySelector(".Container__Dashboard");
    const walletModalHeight = document.querySelector(".WalletResult_modal")?.clientHeight;

    if (showWalletModal) {
      (container as HTMLElement).style.height = (walletModalHeight as number) + 100 + "px";
    } else {
      (container as HTMLElement).style.height = "auto";
    }
  }, [showWalletModal]);

  const setCompetitorRecommendations = (competitorRecommendations: CompetitorRecommendations) => {
    const limitedTopTweets = [];

    for (let i = 0; i < 3; i++) {
      limitedTopTweets.push(competitorRecommendations!.topTweets[i]);
    }

    setTopTweets(limitedTopTweets);
    setIsLoading(false);
  };

  const populateTopNFTProjects = () => {
    return (
      <>
        {!isLoading ? (
          topNFTCollectionsIsErroneous &&
          topNftCollections.map((nft, index) => (
            <NftCard
              index={index}
              key={index}
              nft={nft}
              class="col-sm-12 col-lg-6 col-xl-4 mb-4"
              setShowNFTModal={setShowNFTModal}
              setNFTData={setNFTData}
            />
          ))
        ) : (
          <Loading />
        )}
      </>
    );
  };

  const populateTopMarketplaces = () => {
    return topWallets.map((wallet, index) => {
      return (
        <WalletCard
          setShowWalletModal={setShowWalletModal}
          index={index}
          key={index}
          wallet={wallet}
          usd={web3Store.uSD}
          class="col-12 col-md-12 mb-4"
          setWalletData={setWalletData}
          topAssets={wallet.topAssetsHeld}
        />
      );
    });
  };

  const populateTopEngagedPosts = () => {
    return (
      <>
        {!isLoading ? (
          competitorRecommendationsNotErroneous &&
          topTweets.map((engagedPost, index) => (
            <ResultsCard
              index={index}
              key={index}
              twitterItem={engagedPost}
              type="tweet"
              class="col-sm-12 col-lg-6 col-xl-4 mb-4"
            />
          ))
        ) : (
          <Loading />
        )}
      </>
    );
  };

  return (
    <>
      <Title title="Results" subTitle="[Web3] | Competitive Landscape" />

      <div className="row">
        <div className="col-7">
          <div className="Content__Container mb-4">
            <SubTitle title="Top NFT Competitors" />

            <div className="Container p-4 pb-0">
              <div className="row">{populateTopNFTProjects()}</div>
            </div>
          </div>

          <div className="Content__Container mb-4">
            <SubTitle title="Top Related Post" subTitle="[Web2]">
              <PlatformSelection />
            </SubTitle>

            <div className="Container p-4 pb-0">
              <div className="row">{populateTopEngagedPosts()}</div>
            </div>
          </div>
        </div>

        <div className="col-5">
          <div className="Content__Container mb-4">
            <SubTitle title="Top Wallets">
              <div role="group" aria-label="Basic radio toggle button group">
                <input
                  type="radio"
                  className="btn-check px-0"
                  name="marketplace_timing"
                  id="marketplace_timing_1h"
                  value="1h"
                  onChange={() => true}
                />
                <label className="btn btn-radio mx-2" htmlFor="marketplace_timing_1h">
                  <span className="d-sm-inline mx-0">1H</span>
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="marketplace_timing"
                  id="marketplace_timing_24h"
                  defaultChecked
                  value="24h"
                />
                <label className="btn btn-radio mx-2" htmlFor="marketplace_timing_24h">
                  <span className="d-sm-inline mx-0">24H</span>
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="marketplace_timing"
                  id="marketplace_timing_30d"
                  value="30d"
                />
                <label className="btn btn-radio mx-2" htmlFor="marketplace_timing_30d">
                  <span className="d-sm-inline mx-0">30D</span>
                </label>
              </div>
            </SubTitle>

            <div className="Container p-4 pb-0">
              <div className="row">{populateTopMarketplaces()}</div>
            </div>
          </div>
        </div>
      </div>

      {showNFTModal && (
        <div className="popupResult">
          <NFTModal NFTData={NFTData} setShowNFTModal={setShowNFTModal} />
        </div>
      )}

      {showWalletModal && (
        <div className="popupResult">
          <WalletModal walletData={walletData} setShowWalletModal={setShowWalletModal} />
        </div>
      )}
    </>
  );
};

export default ResultsContent;
