import Logo from "./ojamu-logo.svg";
import LogoLeft from "./ojamu-logo-left.svg";
import LogoSmall from "./ojamu_small.png";

const OjamuLogo = (props: any) => {
  const LogoSource = () => {
    switch (props.small) {
      case true:
        return LogoLeft;
      case 'tiny':
        return LogoSmall;
      default:
        return Logo;
    }
  }

  const LogoClass = () => {
    switch (props.small) {
      case true:
        return "Logo Logo--small";
      case 'tiny':
        return "Logo Logo--tiny";
      default:
        return "Logo";
    }
  }

  return (
    <img
      className={`banner ${LogoClass()}`}
      src={LogoSource()}
      alt="OJAMU Logo"
    />
  );
};

export default OjamuLogo;
